import { WalletProviders } from "@nftstudios/web3-provider";

declare global {
  interface Window {
    ethereum: any;
  }
}

const PROVIDER =
  typeof window !== "undefined" && window.ethereum ? WalletProviders.MetaMask : WalletProviders.WalletConnectV2;

export default PROVIDER;
