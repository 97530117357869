import { useState, useEffect } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { HACK_THE_ROYALS, HOME, TERMS_OF_USE } from "@constants/routes";
import { TAvatarTraitItem, TAvatar } from "@customTypes/avatar";
import { REGENERATOR_API } from "@constants/env";
import { ORIGINAL_IMAGE } from "@constants/apiEndpoints";
import Trait from "@components/Trait";
import useGetAvatarsFromApi from "@hooks/request/useGetAvatarFromAPI";
import AvatarDetail, { EAvatarDetailsStage } from "./components/AvatarDetail";
import DefaultLoader from "@components/DefaultLoader";
import styles from "./AvatarProfile.module.scss";

const AvatarProfile = (): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const [traits, setTraits] = useState<TAvatarTraitItem[]>([]);
    const [haveTraits, setHaveTraits] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [avatar, setAvatar] = useState<TAvatar | null>(null);
    const [isFetching, setIsFetching] = useState(true);
    const { walletAddress } = useWeb3Context();
    const { isLoadingAvatarByOriginalTokenId, getAvatarByOriginalTokenId } = useGetAvatarsFromApi(walletAddress);

    useEffect(() => {
        getAvatar();
    }, [walletAddress]);

    const getAvatar = async () => {
        const avatar = await getAvatarByOriginalTokenId(parseInt(id));

        if (avatar && avatar.traits) {
            avatar?.hasMintedTraits && setHaveTraits(true);
            setAvatar(avatar);
            setTraits([
                avatar.traits.extra,
                avatar.traits.crown,
                avatar.traits.hair,
                avatar.traits.eyes,
                avatar.traits.mouth,
                avatar.traits.beard,
                avatar.traits.face,
                avatar.traits.dress,
                avatar.traits.body,
                avatar.traits.background,
            ]);
        }
        setIsFetching(false);
    };

    const handleIsLoading = () => {
        setIsLoading(false);
    };

    if (isFetching) {
        return (
            <section className={styles.container}>
                <DefaultLoader classname={styles.loader} spinner />
            </section>
        );
    }

    if (!avatar) {
        return <Redirect to={HOME} />;
    }

    return (
        <section className={styles.container}>
            {isLoadingAvatarByOriginalTokenId ? (
                <DefaultLoader classname={styles.loader} spinner />
            ) : (
                <AvatarDetail
                    className={styles.details}
                    isLoading={isLoading}
                    handlePreviewLoaded={handleIsLoading}
                    avatar={avatar}
                    stage={EAvatarDetailsStage.View}
                />
            )}

            {avatar.frozen ? (
                <>
                    {avatar.duplicated ? (
                        <p>
                            <span>FROZEN COPY Q+K</span>
                            <br />
                            THIS AVATAR IS FROZEN. IT CAN NOT BE HACKED ANYMORE. THE SPECIFIC TRAITS ON IT CAN NO LONGER
                            BE SOLD OR TRADED INDIVIDUALLY ON THE MARKET.
                            <br />
                            <Link to={TERMS_OF_USE} className={styles.termsLink}>
                                READ MORE
                            </Link>{" "}
                            ABOUT THE COMMERCIAL USE LICENSE IN OUR TERMS OF USE.
                        </p>
                    ) : (
                        <p>
                            <span>FROZEN Q+K</span>
                            <br />
                            THIS AVATAR IS FROZEN. IT CAN NOT BE HACKED ANYMORE. THE SPECIFIC TRAITS ON IT CAN NO LONGER
                            BE SOLD OR TRADED INDIVIDUALLY ON THE MARKET.
                            <br />
                            <Link to={TERMS_OF_USE} className={styles.termsLink}>
                                READ MORE
                            </Link>{" "}
                            ABOUT THE COMMERCIAL USE LICENSE THAT YOU HAVE OBTAINED IN OUR TERMS OF USE.
                        </p>
                    )}
                    <a
                        href={`${REGENERATOR_API}${ORIGINAL_IMAGE.replace(":id", avatar.id.toString())}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        download
                        className={styles.downloadLink}
                    >
                        DOWNLOAD HI-RES Q+K
                    </a>
                </>
            ) : (
                <>
                    {haveTraits ? (
                        <>
                            <p className={styles.title}>TRAITS LIST</p>
                            <div className={styles.partList}>
                                {traits.map(({ tokenId, rarity, image, type }) =>
                                    tokenId === 0 ? null : (
                                        <Trait
                                            key={tokenId}
                                            id={tokenId}
                                            type={type}
                                            rarity={rarity}
                                            image={image}
                                            showStats
                                        />
                                    )
                                )}

                                {avatar.owner === walletAddress && (
                                    <div>
                                        <Link className={styles.modifyTraitsButton} to={HACK_THE_ROYALS}>
                                            HACKT IT
                                        </Link>
                                    </div>
                                )}
                            </div>

                            <p>
                                CLICK ON <span>HACK IT</span> TO CHANGE THE APPEARANCE OF YOUR AVATAR.
                            </p>
                        </>
                    ) : (
                        <>
                            <p className={styles.title}>TRAITS LIST</p>
                            <p>
                                YOU HAVE NOT CREATED ANY TRAITS YET. EACH AVATAR IS MADE OF TEN DIFFERENT
                                INTERCHANGEABLE TRAITS. EACH TRAIT IS AN NFT WITH VARYING LEVELS OF RARITY. TO MINT YOUR
                                TRAITS, CLICK THE BUTTON BELOW, AND APPROVE THE TRANSACTION IN YOUR WALLET. SOME TRAITS
                                MAY TAKE A FEW MINUTES TO SHOW UP.
                            </p>

                            <Link className={styles.createTraitsButton} to={HACK_THE_ROYALS}>
                                MINT TRAITS
                            </Link>
                        </>
                    )}
                </>
            )}
        </section>
    );
};

export default AvatarProfile;
