import { ReactElement, useEffect, useState } from "react";
import { useSimulatorContext } from "@context/simulatorContext";
import { checkTrait } from "@pages/Simulator/simulatorUtils";
import { EActiveTab } from "../SimulatorCollection";
import { TTrait } from "@customTypes/trait";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import Loader from "@components/Loader";
import useSimulator from "@pages/Simulator/useSimulator";
import styles from "./SimulatorTraitGrid.module.scss";

interface ISimulatorTraitGrid {
    traits: TTrait[];
    isLoadingTraits: boolean;
    isLoadingMintedTraits: boolean;
    activeTab: EActiveTab;
    hasMoreTraits: boolean;
    handleNextFetch: () => void;
}

export enum ETraitVariant {
    DISABLED,
    SELECTED,
    ENABLED,
}

const traitClassMap = {
    [ETraitVariant.DISABLED]: styles.traitDisabled,
    [ETraitVariant.SELECTED]: styles.traitSelected,
    [ETraitVariant.ENABLED]: styles.traitEnabled,
};

const orderedTraits = ["extra", "crown", "hair", "eyes", "mouth", "beard", "face", "dress", "body", "background"];

const SimulatorTraitGrid = ({
    traits,
    activeTab,
    isLoadingTraits,
    isLoadingMintedTraits,
    hasMoreTraits,
    handleNextFetch,
}: ISimulatorTraitGrid): ReactElement => {
    const [tap, setTap] = useState(0);
    const [checkOnTap, setCheckOnTap] = useState(ETraitVariant.DISABLED);
    const [doubleTappedTrait, setDoubleTappedTrait] = useState<TTrait | null>(null);
    const [traitsInOrder, setTraitsInOrder] = useState<TTrait[]>([]);

    const { addTraitToPreview } = useSimulator();
    const { selectedAvatar, updateSelectedTrait, selectedTrait, updateTraitAvatarPreview } = useSimulatorContext();

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        if (activeTab === EActiveTab.MY_COLLECTION) {
            const tr = orderedTraits.map((traitType) => traits.filter((t) => t.type === traitType)).flat();
            setTraitsInOrder(tr);
            return;
        }
        setTraitsInOrder(traits);
    }, [traits]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTap(0);
        }, 300);

        if (tap === 2 && checkOnTap !== ETraitVariant.DISABLED && doubleTappedTrait) {
            addTraitToPreview(doubleTappedTrait, activeTab);
            setCheckOnTap(ETraitVariant.DISABLED);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [tap]);

    const handleTap = (trait: TTrait) => {
        const traitChecked = checkTrait(trait, selectedAvatar);
        setCheckOnTap(traitChecked.state);
        setDoubleTappedTrait(trait);
        updateSelectedTrait(trait);
        setTap(tap + 1);

        if (activeTab === EActiveTab.MY_COLLECTION) {
            updateTraitAvatarPreview(trait);
        }
    };

    const handleClick = (trait: TTrait) => {
        updateSelectedTrait(trait);

        if (activeTab === EActiveTab.MY_COLLECTION) {
            updateTraitAvatarPreview(trait);
        }
    };

    const shouldShowPlaceholders = () =>
        (activeTab === EActiveTab.OPENSEA && isLoadingMintedTraits && traits.length === 0) ||
        (activeTab === EActiveTab.MY_COLLECTION && isLoadingTraits);

    const getToolTipText = (trait: TTrait, check: { state: ETraitVariant; error?: string }) => {
        if (!selectedAvatar || check.state === ETraitVariant.DISABLED) {
            return check.error;
        }

        return `${trait.name} 
                <br /><br /> 
                ${trait.avatarId ? "Q+K #" + trait.avatarId + "<br /><br />" : ""}  
                Trait # ${trait.id} 
                <br /><br /> 
                ${trait.rarity}% have this trait`;
    };

    return (
        <div className={styles.container}>
            {shouldShowPlaceholders() ? (
                <div className={styles.infiniteScroll}>
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                </div>
            ) : (
                <InfiniteScroll
                    className={styles.infiniteScroll}
                    dataLength={traitsInOrder.length}
                    next={handleNextFetch}
                    hasMore={activeTab === EActiveTab.OPENSEA ? hasMoreTraits : false}
                    loader={
                        <div className={styles.loader}>
                            <Loader />
                        </div>
                    }
                    scrollableTarget="scrollableDiv"
                >
                    {traitsInOrder.length > 0 ? (
                        <>
                            {traitsInOrder.map((trait) => {
                                const traitChecked = checkTrait(trait, selectedAvatar);
                                const dataTip = getToolTipText(trait, traitChecked);

                                return (
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        className={`${styles.traitBox} ${traitClassMap[traitChecked.state]} ${
                                            trait.id === selectedTrait?.id && trait.type === selectedTrait?.type
                                                ? styles.selectedForOpensea
                                                : undefined
                                        }`}
                                        key={`trait-${trait.type}-${trait.id}`}
                                        onDoubleClick={() =>
                                            traitChecked.state !== ETraitVariant.DISABLED
                                                ? addTraitToPreview(trait, activeTab)
                                                : null
                                        }
                                        onClick={() => handleClick(trait)}
                                        onKeyDown={() => handleClick(trait)}
                                        onTouchStart={() => handleTap(trait)}
                                    >
                                        <div className={styles.traitItem} data-tip={dataTip}>
                                            {trait.available &&
                                                traitChecked.state !== ETraitVariant.DISABLED &&
                                                activeTab === EActiveTab.MY_COLLECTION && (
                                                    <div className={styles.traitTaken} />
                                                )}
                                            <img
                                                src={trait.image.replace(
                                                    "/traits-images/",
                                                    "/traits-images-thumbnails/"
                                                )}
                                                alt={`${trait.type}${
                                                    trait.style ? " style " + trait.style : ""
                                                } trait owned by the user`}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <p className={styles.noTraits}>There are no traits</p>
                    )}
                </InfiniteScroll>
            )}
            <ReactTooltip data-multiline={true} html={true} />
        </div>
    );
};

export default SimulatorTraitGrid;
