import landingMobile1 from "@images/landing/landing_mobile_1.png";
import landingMobile2 from "@images/landing/landing_mobile_2.png";
import landingMobile3 from "@images/landing/landing_mobile_3.png";
import landingMobile4 from "@images/landing/landing_mobile_4.png";
import landingMobile5 from "@images/landing/landing_mobile_5.png";
import landingMobile6 from "@images/landing/landing_mobile_6.png";
import landingMobile7 from "@images/landing/landing_mobile_7.png";
import landingMobile8 from "@images/landing/landing_mobile_8.png";
import landingMobile9 from "@images/landing/landing_mobile_9.png";
import landingMobile10 from "@images/landing/landing_mobile_10.png";
import landingMobile11 from "@images/landing/landing_mobile_11.png";
import landingMobile12 from "@images/landing/landing_mobile_12.png";

import landingLDesktop1 from "@images/landing/landing_desktop_l_1.png";
import landingLDesktop2 from "@images/landing/landing_desktop_l_2.png";
import landingLDesktop3 from "@images/landing/landing_desktop_l_3.png";
import landingLDesktop4 from "@images/landing/landing_desktop_l_4.png";
import landingLDesktop5 from "@images/landing/landing_desktop_l_5.png";
import landingLDesktop6 from "@images/landing/landing_desktop_l_6.png";
import landingLDesktop7 from "@images/landing/landing_desktop_l_7.png";
import landingLDesktop8 from "@images/landing/landing_desktop_l_8.png";
import landingLDesktop9 from "@images/landing/landing_desktop_l_9.png";
import landingLDesktop10 from "@images/landing/landing_desktop_l_10.png";
import landingLDesktop11 from "@images/landing/landing_desktop_l_11.png";
import landingLDesktop12 from "@images/landing/landing_desktop_l_12.png";

export const largeImages = [
    landingLDesktop1,
    landingLDesktop10,
    landingLDesktop2,
    landingLDesktop11,
    landingLDesktop3,
    landingLDesktop12,
    landingLDesktop4,
    landingLDesktop5,
    landingLDesktop6,
    landingLDesktop7,
    landingLDesktop8,
    landingLDesktop9,
];
export const mobileImages = [
    landingMobile1,
    landingMobile10,
    landingMobile2,
    landingMobile11,
    landingMobile3,
    landingMobile12,
    landingMobile4,
    landingMobile5,
    landingMobile6,
    landingMobile7,
    landingMobile8,
    landingMobile9,
];
