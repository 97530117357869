import {
    OPENSEA_COLLECTION_URL,
    OPENSEA_LAB_COLLECTION_URL,
    OPENSEA_FROZEN_COLLECTION_URL,
    OPENSEA_TRAITS_COLLECTION_URL,
    OPENSEA_GENESIS_COLLECTION_URL,
} from "../../constants/env";
import { DISCORD, EMAIL, INSTAGRAM, TWITTER } from "../../constants/links";
import button_style from "@elements/Button/Button.module.scss";
import styles from "./Faq.module.scss";

const Faq = (): JSX.Element => (
    <div className={styles.container}>
        <h1>FAQs</h1>
        <section>
            <h3>WHO ARE HACKATAO?</h3>
            <p>
                Hackatao was born in 2007 in Italy. Hackatao are OG artists among the pioneers of the international
                Crypto Art movement, having minted their first artwork on the blockchain back in 2018. In these early
                years Hackatao actively participated in the construction of the cryptoverse and the NFT space.
            </p>
        </section>

        <section>
            <h3>WHAT IS QUEENS+KINGS?</h3>
            <p>
                Queens+Kings is an innovative, artistic PFP (profile picture) avatar project created by Hackatao, in
                collaboration with NFT Studios and supported by Sotheby's. All traits and characteristics of the
                randomized avatars are also NFTs, hence collectors can change the appearance of their avatars,
                interchange the traits and buy and sell them on the secondary market on{" "}
                <a
                    href={OPENSEA_LAB_COLLECTION_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-testid="faq-openSea-link"
                >
                    OpenSea
                </a>
                . This project is unique in its own way because the collectors are deeply involved in the creative
                process and as such, the Royals become a community creation!
            </p>
        </section>

        <section>
            <h3>HOW CAN I BUY A Q+K AVATAR?</h3>
            <p>
                A total of 6.900 avatars were minted in December 2021 + February 2022. Collectors can now buy and sell
                individual traits and customised avatars on OpenSea. Since the introduction of the Burning tool, the
                number of avatars now varies as collectors are able to burn traits and bases, therefore increasing the
                value of the project while reducing the scale.
            </p>
        </section>

        <section>
            <h3>WILL HACKATAO RELEASE MORE Q+K AVATARS IN THE FUTURE?</h3>
            <p>No.</p>
        </section>

        <section>
            <h3>HOW RARE ARE AVATAR TRAITS AND HOW CAN I TELL THE RARITY OF EACH?</h3>
            <p>
                Some features are rarer than others, all features are unisex. Rarity metadata will be constantly updated
                on{" "}
                <a
                    href={OPENSEA_COLLECTION_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-testid="faq-openSea-link"
                >
                    OpenSea
                </a>
                .
            </p>
        </section>

        <section>
            <h3>WHY IS Q+K AN ERC721?</h3>
            <p className={styles.ulParagraph}>
                Together with their community, Hackatao have decided to use ERC721, to grant better user experience.
            </p>
            <ul>
                <li>Users can sell their traits even if they are at that time part of a Q+K.</li>
                <li>
                    Users can, in a single transaction, get traits from other Q+Ks that they own and use them on other
                    avatars in their collection.
                </li>
                <li>
                    Updated rarity metadata on{" "}
                    <a
                        href={OPENSEA_COLLECTION_URL}
                        rel="noopener noreferrer"
                        target="_blank"
                        data-testid="faq-openSea-link"
                    >
                        Opensea
                    </a>
                    .
                </li>
            </ul>
        </section>

        <section>
            <h3>IF I BUY A Q+K AVATAR, WHAT CAN I DO WITH IT?</h3>
            <p>
                The avatar NFT then becomes part of your art collection. Cryptoart avatar icons are often used as
                profile pictures on various social media, as collectors identify the artworks. In the case of
                Queens+Kings, you may also intervene and alter the artwork itself, by customizing it, thanks to the
                interchangeable traits.
            </p>
        </section>

        <section>
            <h3>WILL HACKATAO RELEASE NEW TRAITS IN THE FUTURE?</h3>
            <p>
                The Mechanism at the core of the project allows Hackatao to release new traits in time, keeping
                Queens+Kings alive, ever-evolving.. Throughout 2022 a plethora of traits inspired by quintessential
                Hackatao pieces were gifted to Q+K hackers by Hackatao at the end of every season. As for 2023 a new
                strategy has been envisioned to reduce the scale of the traits in the project and consequently provide
                more value to the project.
            </p>
        </section>

        <section>
            <h3>HOW CAN THE TRAITS BECOME SINGLE NFTs?</h3>
            <p>
                Once the avatar has been minted you can decide whether you would like to mint its traits as well. You
                can do this one trait at a time, or altogether. This will cost you gas. We would advise you to do so
                when gas fees are cheaper.
            </p>
        </section>

        <section>
            <h3>BUT HOW CAN THE Q+K AVATAR BE 100% MY PROPERTY?</h3>
            <p>
                Even if the traits are interchangeable, the base avatar maintains its unique, traceable identity. Each
                avatar is unique. The NFT can't be destroyed, replicated, or taken away. The collector is the only one
                that has access to its private key.
            </p>
        </section>

        <section>
            <h3>WHAT IS HACKING AND HOW TO DO IT?</h3>
            <p>
                Hacking is the process of changing the appearance of a Q+K avatar by using interchangeable traits. This
                is done in the ‘Hack The Royal’ section of the Queens+Kings website.
            </p>
        </section>

        <section>
            <h3>WHAT IS FREEZING AND HOW TO DO IT?</h3>
            <p>
                Freezing is the irreversible process of solidifying your Q+K in time, making it unhackable but
                consequently gaining the commercial rights of the Avatar. There are also always collaborative
                opportunities for Frozen Royals within the community, for example, the Sandbox, Faceless, B&O e.t.c.
                This is done in the ‘Hack The Royal’ section of the Queens+Kings website by pressing the ‘Freeze Q+K’
                button.
            </p>
        </section>

        <section>
            <h3>WHAT IS BURNING AND HOW TO DO IT?</h3>
            <p>
                It is a new upcoming function that will reduce the scale of avatars and traits, increasing value and
                rarity accordingly.
            </p>
        </section>

        <h2>FAQs Community</h2>
        <section>
            <h3>HOW CAN I JOIN THE HACKATAO QUEENS+KINGS COMMUNITY?</h3>
            <p>
                You can join the Hackatao Queens+Kings community on the dedicated{" "}
                <a href={DISCORD} rel="noopener noreferrer" target="_blank" data-testid="faq-discord-link">
                    Discord server
                </a>
                .
            </p>
        </section>

        <section>
            <h3>HOW CAN I CONTACT THE HACKATAO TEAM?</h3>
            <p>
                Should you require further information on the project, do not hesitate to contact us via email on{" "}
                <a href={EMAIL} data-testid="faq-email-link">
                    queenskings@hackatao.com
                </a>{" "}
                for enquiries.
            </p>
        </section>

        <section>
            <h3>WHERE CAN I FIND THE LATEST UPDATES?</h3>
            <p>
                Follow Hackatao on{" "}
                <a href={TWITTER} rel="noopener noreferrer" target="_blank" data-testid="faq-twitter-link">
                    Twitter
                </a>
                ,{" "}
                <a href={INSTAGRAM} rel="noopener noreferrer" target="_blank" data-testid="faq-instagram-link">
                    Instagram
                </a>{" "}
                and{" "}
                <a href={DISCORD} rel="noopener noreferrer" target="_blank" data-testid="faq-discord-link">
                    Discord
                </a>{" "}
                for the latest updates on upcoming projects.
            </p>
        </section>

        <h2>FAQs Getting started</h2>
        <section>
            <h3>CAN I ACCESS MY COLLECTION ON DIFFERENT DEVICES?</h3>
            <p>You may access your collection as well as the avatar builder on desktop or on mobile.</p>
        </section>

        <section>
            <h3>DO I NEED METAMASK TO MINT?</h3>
            <p>
                queenskings.hackatao.com is web3, so you can use metamask or a similar wallet to mint and build your
                avatar.
            </p>
        </section>

        <section>
            <h3>WHAT ELSE DO I NEED TO BUY A Q+K AVATAR?</h3>
            <p>You should have Ethereum in your wallet.</p>
        </section>

        <section>
            <h3>WHERE CAN I SEE MY Q+K AVATARS?</h3>
            <p>
                You may visualize your avatars under the 'My Collection' section of this dedicated website or{" "}
                <a
                    href={OPENSEA_COLLECTION_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-testid="faq-openSea-link"
                >
                    OpenSea
                </a>
                .
            </p>
        </section>

        <section>
            <h3>HOW DO I SELL A Q+K AVATAR?</h3>
            <p>
                You can sell or buy Q+K whole avatars or single traits on{" "}
                <a
                    href={OPENSEA_COLLECTION_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-testid="faq-openSea-link"
                >
                    OpenSea
                </a>
                .
            </p>
        </section>

        <section>
            <h3>I LOST ACCESS TO MY WALLET. CAN I RECOVER MY Q+K AVATARS?</h3>
            <p>Friend, you lost the keys to the kingdom, no horse will save you.</p>
        </section>

        <h2>FAQs Transactions and Technical Questions</h2>
        <section>
            <h3>MY TRANSACTION FAILED, WHY?</h3>
            <p>
                It seems that you might have submitted a transaction with a very low gas fee, you might be stuck with a
                pending transaction. You might need to increase the limit, to have sufficient gas to perform the
                transaction.
            </p>
        </section>

        <section>
            <h3>WHAT IS 'GAS'?</h3>
            <p>Something very annoying but necessary.</p>
        </section>

        <a href={EMAIL} className={button_style.secondaryHeader} data-testid="faq-email-link">
            CONTACT US
        </a>
    </div>
);

export default Faq;
