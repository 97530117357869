import axios from "axios";
import { useEffect, useState } from "react";
import { IMAGES_API_BASE_URL } from "@constants/env";
import { BURNABLE_TRAITS } from "@constants/apiEndpoints";
import { IAsset } from "@customTypes/burn/asset";
import { ITraitApiResponse } from "@customTypes/trait";
import { traitApiResponseToAsset } from "@utils/mappers/burn/traitApiResponseToAsset";
import { ETabType } from "@enums/burn";
import { useWeb3Context } from "@nftstudios/web3-provider";

interface IUseGetTraits {
    data: IAsset[];
    currentPage: number;
    total: number;
    isLoading: boolean;
}

const useGetTraits = (
    limit: number,
    page: number,
    name: string | undefined = undefined,
    type: string,
    tab: ETabType
): IUseGetTraits => {
    const { walletAddress } = useWeb3Context();
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState<IAsset[]>([]);

    useEffect(() => {
        if (tab !== ETabType.TRAITS || !walletAddress) {
            return;
        }

        const controller = new AbortController();
        const signal = controller.signal;

        setIsLoading(true);
        axios
            .get(`${BURNABLE_TRAITS}`.replace(":address", walletAddress).replace(":type", type), {
                baseURL: IMAGES_API_BASE_URL,
                params: {
                    page,
                    limit,
                    name,
                },
                signal,
            })
            .then((result) => {
                if (result) {
                    setCurrentPage(0);
                    setTotal(result.data.length);
                    const parsedTraits = result.data.map((apiTrait: ITraitApiResponse) =>
                        traitApiResponseToAsset(apiTrait)
                    );
                    setData(parsedTraits);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setIsLoading(false));

        return () => {
            controller.abort();
        };
    }, [walletAddress, limit, page, name, tab, type]);

    return {
        data,
        isLoading,
        total,
        currentPage,
    };
};

export default useGetTraits;
