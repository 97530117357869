import { useState } from "react";
import { generatePath } from "react-router-dom";
import { IMAGES_API_BASE_URL } from "@constants/env";
import { AVATARS, AVATAR, TRAITS, TRAIT, UNIQUE_TRAITS, AVATAR_ORIGINAL, RANDOM_TRAITS } from "@constants/apiEndpoints";
import { ITraitApiResponse, TTrait } from "@customTypes/trait";
import { TAvatar, TAvatarTraitItem, TAvatarTraits } from "@customTypes/avatar";
import { apiResponseToAvatar, IAvatarApiResponse } from "@utils/scripts/AvatarUtils";
import { apiResponseToTrait } from "@utils/scripts/TraitUtils";
import axios from "axios";
import {
    T_BACKGROUND_CONTRACT_ADDR,
    T_BEARD_CONTRACT_ADDR,
    T_BODY_CONTRACT_ADDR,
    T_CROWN_CONTRACT_ADDR,
    T_DRESS_CONTRACT_ADDR,
    T_EXTRA_CONTRACT_ADDR,
    T_EYES_CONTRACT_ADDR,
    T_FACE_CONTRACT_ADDR,
    T_HAIR_CONTRACT_ADDR,
    T_MOUTH_CONTRACT_ADDR,
} from "@constants/env";

interface IUseAvatarsFromApi {
    mintedNextCursor: number;
    traits: TTrait[];
    avatars: TAvatar[];
    trait: TTrait | null;
    rarities: {
        background: number;
        crown: number;
        hair: number;
        face: number;
        eyes: number;
        mouth: number;
        beard: number;
        body: number;
        dress: number;
        extra: number;
    } | null;
    assembledAvatars: TAvatar[];
    isLoadingTraits: boolean;
    isLoadingAvatars: boolean;
    isLoadingAvatarByTokenId: boolean;
    isLoadingTraitByTokenId: boolean;
    isLoadingAssembledAvatars: boolean;
    isLoadingMintedTraits: boolean;
    isLoadingClaimedTraits: boolean;
    isLoadingAvatarByOriginalTokenId: boolean;
    moreAvatars: boolean;
    getTraits: () => void;
    getAvatars: (offset: number) => void;
    getMintedTraits: (type: string, rarity: number, nextCursor?: number, limit?: number) => Promise<TTrait[]>;
    getRarityByTraits: (trait: TAvatarTraits) => void;
    getAvatarsByTokenList: (tokenIdList: string[]) => void;
    getTraitByTokenId: (type: string, tokenid: number) => void;
    getAvatarByTokenId: (tokenid: number) => Promise<TAvatar | null>;
    getTraitsByTypesAndIds: (types: string[], tokenIds: string[]) => Promise<TTrait[]>;
    getAvatarByOriginalTokenId: (originalTokenId: number) => Promise<TAvatar | null>;
    getRandomTraits: () => Promise<TTrait[]>;
}

const traitToContract = {
    [T_BACKGROUND_CONTRACT_ADDR as string]: "background",
    [T_CROWN_CONTRACT_ADDR as string]: "crown",
    [T_HAIR_CONTRACT_ADDR as string]: "hair",
    [T_FACE_CONTRACT_ADDR as string]: "face",
    [T_EYES_CONTRACT_ADDR as string]: "eyes",
    [T_MOUTH_CONTRACT_ADDR as string]: "mouth",
    [T_BEARD_CONTRACT_ADDR as string]: "beard",
    [T_BODY_CONTRACT_ADDR as string]: "body",
    [T_DRESS_CONTRACT_ADDR as string]: "dress",
    [T_EXTRA_CONTRACT_ADDR as string]: "extra",
};

const useGetAvatarsFromApi = (walletAddress: string): IUseAvatarsFromApi => {
    const [mintedNextCursor, setMintedNextCursor] = useState(0);
    const [avatars, setAvatars] = useState<TAvatar[]>([]);
    const [moreAvatars, setMoreAvatars] = useState<boolean>(false);
    const [traits, setTraits] = useState<TTrait[]>([]);
    const [trait, setTrait] = useState<TTrait | null>(null);
    const [assembledAvatars, setAssembledAvatars] = useState<TAvatar[]>([]);
    const [isLoadingAssembledAvatars, setIsLoadingAssembledAvatars] = useState(true);
    const [isLoadingAvatars, setIsLoadingAvatars] = useState(true);
    const [isLoadingTraits, setIsLoadingTraits] = useState(true);
    const [isLoadingAvatarByTokenId, setIsLoadingAvatarByTokenId] = useState(false);
    const [isLoadingTraitByTokenId, setIsLoadingTraitByTokenId] = useState(true);
    const [isLoadingMintedTraits, setIsLoadingMintedTraits] = useState(false);
    const [isLoadingClaimedTraits, setIsLoadingClaimedTraits] = useState(false);
    const [isLoadingAvatarByOriginalTokenId, setIsLoadingAvatarByOriginalTokenId] = useState(false);

    const [rarities, setRarities] =
        useState<{
            background: number;
            crown: number;
            hair: number;
            face: number;
            eyes: number;
            mouth: number;
            beard: number;
            body: number;
            dress: number;
            extra: number;
        } | null>(null);

    const getAvatars = (offset: number) => {
        setIsLoadingAvatars(true);

        axios
            .get(`${IMAGES_API_BASE_URL}${AVATARS}`.replace(":address", walletAddress), {
                params: {
                    offset,
                    limit: 4,
                },
            })
            .then(({ data }) => {
                const parsedAvatars = data.map((apiAvatar: IAvatarApiResponse) => apiResponseToAvatar(apiAvatar));

                if (parsedAvatars.length === 0) {
                    setMoreAvatars(false);
                } else {
                    setMoreAvatars(true);
                    setAvatars(avatars.concat(parsedAvatars));
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setIsLoadingAvatars(false));
    };

    const getAvatarByTokenId = async (tokenid: number): Promise<TAvatar | null> => {
        setIsLoadingAvatarByTokenId(true);
        let result: TAvatar | null = null;

        try {
            const { data } = await axios.get(`${IMAGES_API_BASE_URL}${AVATAR}`.replace(":tokenid", tokenid.toString()));
            if (data) {
                const parsedAvatar = apiResponseToAvatar(data);
                result = parsedAvatar;
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingAvatarByTokenId(false);
        }

        return result;
    };

    const getAvatarByOriginalTokenId = async (originalTokenId: number): Promise<TAvatar | null> => {
        setIsLoadingAvatarByOriginalTokenId(true);
        let result: TAvatar | null = null;

        try {
            const { data } = await axios.get(
                `${IMAGES_API_BASE_URL}${AVATAR_ORIGINAL}`.replace(":tokenid", originalTokenId.toString())
            );
            const parsedAvatar = apiResponseToAvatar(data);
            result = parsedAvatar;
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingAvatarByOriginalTokenId(false);

            if (!walletAddress) {
                setIsLoadingAvatars(false);
            }
        }

        return result;
    };

    const getAvatarsByTokenList = async (tokenList: string[]) => {
        Promise.all(
            tokenList.map(async (tokenid) => {
                let retries = 10;
                const interval = async () => {
                    try {
                        const call = await axios.get(generatePath(`${IMAGES_API_BASE_URL}${AVATAR}`, { tokenid }));

                        if (call.status === 200) {
                            const parsedNewAvatar = apiResponseToAvatar(call.data);
                            setAssembledAvatars((prevAssembledAvatars) => [...prevAssembledAvatars, parsedNewAvatar]);
                            setIsLoadingAssembledAvatars(false);
                            return;
                        }
                    } catch (error) {
                        console.error(error);
                        retries--;
                    }
                    if (retries > 0) {
                        setTimeout(interval, 3000);
                    }
                };
                interval();
            })
        );
    };

    const getTraitsByTypesAndIds = async (traitAddresses: string[], tokenIds: string[]): Promise<TTrait[]> => {
        setIsLoadingClaimedTraits(true);
        let result: TTrait[] = [];
        try {
            result = await Promise.all(
                traitAddresses.map(async (address, index) => {
                    const trait = (
                        await axios.get(
                            generatePath(`${IMAGES_API_BASE_URL}${TRAIT}`, {
                                type: traitToContract[address],
                                tokenid: tokenIds[index],
                            })
                        )
                    ).data;
                    const parsedTrait: TTrait = apiResponseToTrait(trait);
                    return parsedTrait;
                })
            );
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingClaimedTraits(false);
        }
        return result;
    };

    const getTraits = () => {
        axios
            .get(`${IMAGES_API_BASE_URL}${TRAITS}`.replace(":address", walletAddress))
            .then(({ data }) => {
                const parsedTraits = data.map((apiTrait: ITraitApiResponse) => apiResponseToTrait(apiTrait));
                setTraits(parsedTraits);
            })
            .catch((error) => console.error(error))
            .finally(() => setIsLoadingTraits(false));
    };

    const getTraitByTokenId = (type: string, tokenid: number) => {
        axios
            .get(generatePath(`${IMAGES_API_BASE_URL}${TRAIT}`, { type, tokenid }))
            .then(({ data }) => {
                const parsedTrait = apiResponseToTrait(data);
                setTrait(parsedTrait);
            })
            .catch((error) => console.error(error))
            .finally(() => setIsLoadingTraitByTokenId(false));
    };

    const getMintedTraits = async (
        type: string,
        rarity: number,
        nextCursor: number = 0,
        limit: number = 30
    ): Promise<TTrait[]> => {
        setIsLoadingMintedTraits(true);
        let mintedTraits: TTrait[] = [];

        try {
            const result = await axios.get(UNIQUE_TRAITS.replace(":type", type), {
                baseURL: IMAGES_API_BASE_URL,
                params: {
                    orderByRarity: rarity,
                    nextCursor,
                    limit,
                },
            });

            if (result) {
                mintedTraits = result.data.traits.map((apiTrait: ITraitApiResponse) => apiResponseToTrait(apiTrait));
                setMintedNextCursor(result.data.nextCursor);
            }
        } catch (error) {
            console.error(error);
        }

        setIsLoadingMintedTraits(false);
        return mintedTraits;
    };

    const getRarityByTraits = (traits: {
        background: TAvatarTraitItem;
        crown: TAvatarTraitItem;
        hair: TAvatarTraitItem;
        face: TAvatarTraitItem;
        eyes: TAvatarTraitItem;
        mouth: TAvatarTraitItem;
        beard: TAvatarTraitItem;
        body: TAvatarTraitItem;
        dress: TAvatarTraitItem;
        extra: TAvatarTraitItem;
    }) => {
        setRarities({
            background: traits.background.rarity,
            crown: traits.crown.rarity,
            hair: traits.hair.rarity,
            face: traits.face.rarity,
            eyes: traits.eyes.rarity,
            mouth: traits.mouth.rarity,
            beard: traits.beard.rarity,
            body: traits.body.rarity,
            dress: traits.dress.rarity,
            extra: traits.extra.rarity,
        });
    };

    const getRandomTraits = async () => {
        setIsLoadingAvatars(true);
        let randomTraits: TTrait[] = [];
        try {
            const result = await axios.get(`${IMAGES_API_BASE_URL}${RANDOM_TRAITS}`);
            if (result) {
                randomTraits = result.data.map((apiTrait: ITraitApiResponse) => apiResponseToTrait(apiTrait));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingAvatars(false);
        }
        return randomTraits;
    };

    return {
        trait,
        traits,
        avatars,
        rarities,
        mintedNextCursor,
        assembledAvatars,
        isLoadingTraits,
        isLoadingAvatars,
        isLoadingTraitByTokenId,
        isLoadingAvatarByTokenId,
        isLoadingAssembledAvatars,
        isLoadingMintedTraits,
        isLoadingClaimedTraits,
        isLoadingAvatarByOriginalTokenId,
        moreAvatars,
        getTraits,
        getMintedTraits,
        getAvatars,
        getTraitByTokenId,
        getRarityByTraits,
        getAvatarByTokenId,
        getAvatarsByTokenList,
        getTraitsByTypesAndIds,
        getAvatarByOriginalTokenId,
        getRandomTraits,
    };
};

export default useGetAvatarsFromApi;
