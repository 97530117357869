import { NavLink } from "react-router-dom";
import { HOME, TERMS_OF_USE, PRIVACY_POLICY, FAQ, HOW_IT_WORKS } from "@constants/routes";
import styles from "./FooterNavbar.module.scss";

const FooterNavbar = (): JSX.Element => (
    <nav className={styles.container}>
        <ul>
            <li>
                <NavLink exact to={HOME} activeClassName={styles.activeLink} data-testid="footer-home-link">
                    HOME
                </NavLink>
            </li>
            <li className={styles.separator}>|</li>
            <li>
                <NavLink
                    exact
                    to={HOW_IT_WORKS}
                    activeClassName={styles.activeLink}
                    data-testid="footer-howItWorks-link"
                >
                    HOW IT WORKS
                </NavLink>
            </li>
            <li className={styles.separator}>|</li>
            <li>
                <NavLink to={TERMS_OF_USE} activeClassName={styles.activeLink} data-testid="terms-of-use-link">
                    TERMS OF USE
                </NavLink>
            </li>
            <li className={styles.separator}>|</li>
            <li>
                <NavLink
                    exact
                    to={PRIVACY_POLICY}
                    activeClassName={styles.activeLink}
                    data-testid="privacy-policy-link"
                >
                    PRIVACY POLICY
                </NavLink>
            </li>
            <li className={styles.separator}>|</li>
            <li>
                <NavLink exact to={FAQ} activeClassName={styles.activeLink} data-testid="faq-link">
                    FAQ
                </NavLink>
            </li>
        </ul>
    </nav>
);

export default FooterNavbar;
