import { ChangeEvent, ReactElement, MouseEvent, KeyboardEvent, FormEvent } from "react";
import magifier from "@images/magnifier.png";
import cross from "@images/cross.png";
import styles from "./RankingTabs.module.scss";

export enum EActiveTab {
    AVATARS = "AVATARS",
    TRAITS = "TRAITS",
}

interface IRankingTabs {
    activeTab: EActiveTab;
    avatarSearch: string;
    traitSearch: string;
    handleTabChange: (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>, tab: EActiveTab) => void;
    handleAvatarSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleTraitSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSubmitAvatarSearch: (event: FormEvent) => void;
    handleSubmitTraitSearch: (event: FormEvent) => void;
    handleResetAvatarSearch: () => void;
    handleResetTraitSearch: () => void;
}

const RankingTabs = ({
    activeTab,
    avatarSearch,
    traitSearch,
    handleTabChange,
    handleAvatarSearchChange,
    handleTraitSearchChange,
    handleSubmitAvatarSearch,
    handleSubmitTraitSearch,
    handleResetAvatarSearch,
    handleResetTraitSearch,
}: IRankingTabs): ReactElement => (
    <div className={styles.container}>
        <div className={styles.tabs} role="tablist">
            <div
                role="tab"
                tabIndex={0}
                className={`${activeTab === EActiveTab.AVATARS ? styles.active : ""} ${styles.tab}`}
                onClick={(e) => handleTabChange(e, EActiveTab.AVATARS)}
                onKeyDown={(e) => handleTabChange(e, EActiveTab.AVATARS)}
            >
                <p>Q+K AVATAR</p>
                <form
                    className={`${styles.searchForm} ${
                        activeTab === EActiveTab.AVATARS ? styles.visible : styles.hidden
                    }`}
                    onSubmit={handleSubmitAvatarSearch}
                >
                    <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        className={styles.searchInput}
                        value={avatarSearch}
                        placeholder="ID"
                        onChange={handleAvatarSearchChange}
                    />
                    <button type="submit">
                        <img src={magifier} alt="Search by avatar id" />
                    </button>
                    {avatarSearch && (
                        <button className={styles.cross} type="button" onClick={handleResetAvatarSearch}>
                            <img src={cross} alt="Reset search filter" />
                        </button>
                    )}
                </form>
            </div>
            <div
                role="tab"
                tabIndex={0}
                className={`${activeTab === EActiveTab.TRAITS ? styles.active : ""} ${styles.tab}`}
                onClick={(e) => handleTabChange(e, EActiveTab.TRAITS)}
                onKeyDown={(e) => handleTabChange(e, EActiveTab.TRAITS)}
            >
                <p>Q+K TRAITS</p>
                <form
                    className={`${styles.searchForm} ${
                        activeTab === EActiveTab.TRAITS ? styles.visible : styles.hidden
                    }`}
                    onSubmit={handleSubmitTraitSearch}
                >
                    <input
                        type="text"
                        className={styles.searchInput}
                        value={traitSearch}
                        placeholder="Name"
                        onChange={handleTraitSearchChange}
                    />
                    <button type="submit">
                        <img src={magifier} alt="" />
                    </button>
                    {traitSearch && (
                        <button className={styles.cross} type="submit" onClick={handleResetTraitSearch}>
                            <img src={cross} alt="Reset search filter" />
                        </button>
                    )}
                </form>
            </div>
        </div>
    </div>
);

export default RankingTabs;
