import { ImgHTMLAttributes, ReactElement } from "react";
import styles from "./Image.module.scss";

type IImage = ImgHTMLAttributes<HTMLImageElement>;

const Image = ({ src, alt, ...rest }: IImage): ReactElement => {
    if (!src) {
        return <div className={styles.image} />;
    }

    return <img className={styles.image} src={src} alt={alt} {...rest} />;
};

export default Image;
