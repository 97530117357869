import qklogo from "@images/home-qk-logo.png";
import BottomBanner from "@components/BottomBanner";
import AvatarMain from "./components/AvatarMain";
import AvatarGrid from "./components/AvatarGrid";
import styles from "./Home.module.scss";

const Home = (): JSX.Element => (
    <div className={styles.container}>
        <section>
            <img src={qklogo} className={styles.floatingLogo} alt="floating QK" role="presentation" />

            <AvatarMain />

            <p className={styles.onlyDesktop}>
                WE ARE ALL <span>QUEENS AND KINGS</span>, SOVEREIGNS OF OUR CREATIVE REALM. <span>+</span> WE CROWN
                OURSELVES. <span>+</span> OUR KINGDOM IS THE METAVERSE THAT WE CREATE FOR OURSELVES. <span>+</span> WE
                HAVE NO SUBJECTS, NO ARMIES, NOTHING TO CONQUER BUT KNOWLEDGE AND VIRTUE. <span>+</span> WE SHARE.{" "}
                <span>+</span> WE PRACTICE DEFI AND THINK OUTSIDE THE BOUNDARIES OF COMMON THOUGHT.{" "}
                <span>WE ARE QUEENS AND KINGS.</span>
            </p>

            <p className={styles.onlyMobile}>
                WE ARE ALL <span>QUEENS AND KINGS</span>, SOVEREIGNS OF OUR CREATIVE REALM. <br />
                WE CROWN OURSELVES. <br />
                OUR KINGDOM IS THE METAVERSE THAT WE CREATE FOR OURSELVES. <br />
                WE HAVE NO SUBJECTS, NO ARMIES, NOTHING TO CONQUER BUT KNOWLEDGE AND VIRTUE. <br />
                WE SHARE. WE PRACTICE DEFI AND THINK OUTSIDE THE BOUNDARIES OF COMMON THOUGHT.
                <br />
                <br />
                <span>WE ARE QUEENS AND KINGS.</span>
            </p>

            <AvatarGrid />
            <BottomBanner />
        </section>
    </div>
);

export default Home;
