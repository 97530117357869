import { useState, useEffect } from "react";
import { TAvatar } from "@customTypes/avatar";
import ItemsCarousel from "react-items-carousel";
import DefaultLoader from "@components/DefaultLoader";
import useWindowSize from "@hooks/useWindowSize";
import frozenWatermark from "@images/froze_watermark.png";
import styles from "./AvatarDetailCarousel.module.scss";

interface IAvatarsCarousel {
    classname?: string;
    resetCarousel?: boolean;
    btnLeftClassName?: string;
    btnRightClassName?: string;
    avatarsList: TAvatar[];
    handleActiveSlide?: (id: number) => void;
    handleActiveAvatarIndex?: (id: number) => void;
    showLeft?: boolean;
    showRight?: boolean;
    numberOfCards?: number;
    gutter: number;
    moreAvatars?: boolean;
    getAvatars?: (offset: number) => void;
    isLoadingAvatars?: boolean;
    isPreviewLoading?: boolean;
    handlePreviewLoaded?: () => void;
}

const AvatarDetailCarousel = ({
    classname,
    resetCarousel,
    btnLeftClassName,
    btnRightClassName,
    avatarsList,
    showLeft,
    showRight,
    handleActiveSlide,
    handleActiveAvatarIndex,
    numberOfCards = 1,
    gutter = 5,
    moreAvatars,
    getAvatars,
    isLoadingAvatars,
    isPreviewLoading,
    handlePreviewLoaded,
}: IAvatarsCarousel): JSX.Element => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const { width } = useWindowSize();

    const chevronWidth = width && width > 375 ? 40 : 20;

    useEffect(() => {
        handleActiveSlide && handleActiveSlide(avatarsList[activeIndex].id);
        handleActiveAvatarIndex && handleActiveAvatarIndex(activeIndex);
    }, [activeIndex, activeItemIndex]);

    useEffect(() => {
        setActiveIndex(0);
        setActiveItemIndex(0);
    }, [resetCarousel]);

    const handleActiveItemIndex = (itemIndex: number) => {
        if (itemIndex > activeItemIndex) {
            setActiveIndex(activeIndex === avatarsList.length - 1 ? 0 : activeIndex + 1);
        } else {
            setActiveIndex(activeIndex === 0 ? avatarsList.length - 1 : activeIndex - 1);
        }
        setActiveItemIndex(itemIndex);
    };

    const handleOnLoad = () => {
        handlePreviewLoaded && handlePreviewLoaded();
    };

    return (
        <div className={`${styles.container} ${classname}`} style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={handleActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCards}
                rightChevron={
                    showRight ? (
                        <button
                            className={`${styles.nextBtn} ${btnRightClassName}`}
                            aria-label="Next avatar"
                            data-testid="minted-avatar-next-button"
                        />
                    ) : null
                }
                chevronWidth={chevronWidth}
                leftChevron={
                    showLeft ? (
                        <button
                            className={`${styles.prevBtn} ${btnLeftClassName}`}
                            aria-label="Prev avatar"
                            data-testid="minted-avatar-prev-button"
                        />
                    ) : null
                }
                gutter={gutter}
                outsideChevron
                onActiveStateChange={({ isLastScroll }: { isLastScroll: boolean; isFirstScroll: boolean }) => {
                    if (isLastScroll && moreAvatars && !isLoadingAvatars && getAvatars) {
                        getAvatars(offset + 4);
                        setOffset(offset + 4);
                    }
                }}
            >
                {avatarsList.map(({ originalTokenId, image, name, frozen }) => (
                    <div key={originalTokenId} className={styles.imageContainer}>
                        <p>
                            Q<span>+</span>K #{originalTokenId}
                        </p>
                        {isPreviewLoading && (
                            <div className={styles.previewLoading}>
                                <DefaultLoader classname={styles.loader} spinner />
                            </div>
                        )}
                        <img
                            className={isPreviewLoading ? styles.previewLoadingImg : undefined}
                            onLoad={handleOnLoad}
                            src={image}
                            alt={name}
                        />
                        {frozen && (
                            <img className={styles.frozenmark} src={frozenWatermark} alt={"Frozen avatar watermark"} />
                        )}
                    </div>
                ))}
            </ItemsCarousel>
        </div>
    );
};

export default AvatarDetailCarousel;
