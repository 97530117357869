import { ReactElement } from "react";
import BottomBanner from "@components/BottomBanner";
import DefaultLoader from "@components/DefaultLoader";
import styles from "./Loading.module.scss";
import { ETHERSCAN_URL } from "@constants/env";

interface ILoading {
    txId?: string | null;
}

const Loading = ({ txId }: ILoading): ReactElement => {
    return (
        <div className={styles.container}>
            <h3 className={styles.title}>BURNING</h3>
            <p>THE FLAME RISES AS WE ARE BURNING YOUR OFFERING! BE STEADFAST AS THE FIRE GROWS.</p>
            <p className={styles.last}>MY THE ASHES BECOME THE BUILDING BLOCKS OF FUTURE KINGDOMS!</p>
            <DefaultLoader spinner invert />
            <p className={styles.transaction}>
                CHECK YOUR{" "}
                <a href={`${ETHERSCAN_URL}tx/${txId}`} rel="noopener noreferrer" target="_blank">
                    TRANSACTION
                </a>
            </p>

            <BottomBanner />
        </div>
    );
};

export default Loading;
