import axios from "axios";
import { useState } from "react";
import { IMAGES_API_BASE_URL, RANKING_AVATAR_CLIENT_API } from "../../constants/env";
import { AVATARS_RANKING, TRAITS_RANKING } from "../../constants/apiEndpoints";
import { apiResponseToRankingTrait } from "../../utils/scripts/TraitUtils";
import { apiResponseToAvatarRanking, IAvatarRanking, IAvatarRankingApiResponse } from "../../utils/scripts/AvatarUtils";
import { ITraitRanking, ITraitRankingApiResponse } from "@customTypes/trait";

interface IUseGetRankingDataFromApi {
    avatarPage: number;
    traitPage: number;
    totalTraits: number;
    isFetchingAvatars: boolean;
    isFetchingTraits: boolean;
    getAvatars: (page?: number, limit?: number, tokenId?: number | undefined) => Promise<IAvatarRanking[]>;
    getTraits: (type: string, page?: number, limit?: number, name?: string | undefined) => Promise<ITraitRanking[]>;
}

const useGetRankingDataFromApi = (): IUseGetRankingDataFromApi => {
    const [isFetchingAvatars, setIsFetchingAvatars] = useState(false);
    const [isFetchingTraits, setIsFetchingTraits] = useState(false);
    const [traitPage, setTraitPage] = useState(0);
    const [avatarPage, setAvatarPage] = useState(0);
    const [totalTraits, setTotalTraits] = useState(0);

    const getAvatars = async (
        page: number = 0,
        limit: number = 20,
        tokenId: number | undefined = undefined
    ): Promise<IAvatarRanking[]> => {
        setIsFetchingAvatars(true);
        let avatarList: IAvatarRanking[] = [];

        try {
            const result = await axios.get(AVATARS_RANKING, {
                baseURL: RANKING_AVATAR_CLIENT_API,
                params: {
                    page,
                    limit,
                    tokenId,
                },
            });

            setAvatarPage(result.data.page);

            const parsedAvatars = result.data.avatars.map((avatar: IAvatarRankingApiResponse) =>
                apiResponseToAvatarRanking(avatar)
            );
            avatarList = parsedAvatars;
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsFetchingAvatars(false);
        }

        return avatarList;
    };

    const getTraits = async (
        type: string,
        page: number = 0,
        limit: number = 20,
        name: string | undefined
    ): Promise<ITraitRanking[]> => {
        setIsFetchingTraits(true);
        let traitList: ITraitRanking[] = [];

        try {
            const result = await axios.get(TRAITS_RANKING, {
                baseURL: IMAGES_API_BASE_URL,
                params: {
                    type,
                    page,
                    limit,
                    name,
                },
            });

            setTraitPage(result.data.page);
            setTotalTraits(result.data.total);

            const parsedTraits = result.data.traits.map((trait: ITraitRankingApiResponse) =>
                apiResponseToRankingTrait(type, trait)
            );
            traitList = parsedTraits;
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsFetchingTraits(false);
        }

        return traitList;
    };

    return {
        avatarPage,
        traitPage,
        totalTraits,
        isFetchingAvatars,
        isFetchingTraits,
        getAvatars,
        getTraits,
    };
};

export default useGetRankingDataFromApi;
