import { ReactElement } from "react";
import { TTrait } from "@customTypes/trait";
import styles from "./SimulatorBuyAvatarOpenseaButton.module.scss";

interface ISimulatorBuyAvatarOpenseaButton {
    className?: string;
    selectedTrait?: TTrait | null;
}

const SimulatorBuyAvatarOpenseaButton = ({
    selectedTrait,
    className,
}: ISimulatorBuyAvatarOpenseaButton): ReactElement => {
    const link = `https://opensea.io/collection/queenskingslab?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=${
        selectedTrait?.type === "extra" ? "power" : selectedTrait?.type
    }&search[stringTraits][0][values][0]=${selectedTrait?.name} - ${selectedTrait?.rarityString}`;

    return (
        <a href={link} className={`${styles.buyOpensea} ${className}`} rel="noopener noreferrer" target="_blank">
            SEARCH IN Q+K COLLECTION
        </a>
    );
};

export default SimulatorBuyAvatarOpenseaButton;
