export const HOME = "/";
export const FAQ = "/faq";
export const MINT = "/mint";
export const TERMS_OF_USE = "/terms-of-use";
export const HOW_IT_WORKS = "/how-it-works";
export const MY_COLLECTION = "/my-collection";
export const PRIVACY_POLICY = "/privacy-policy";
export const AVATAR_PROFILE = "/avatars/:id";
export const TRAIT_PROFILE = "/trait/:type/:id";
export const LANDING = "/landing";
export const LEARN_MORE = "/learn-more";
export const SUBSCRIBE = "/subscribe";
export const SUBSCRIBE_SUCCESS = "/subscribe-success";
export const SUBSCRIBE_FAIL = "/subscribe-failed";
export const ROADMAP = "/roadmap";
export const HACK_THE_ROYALS = "/hack-the-royals";
export const RANKING = "/ranking";
export const CLAIM_TRAIT = "/claim-trait";
export const BURNING = "/burning";
export const ROYAL_LIST = "/royal-list";
