import { ReactElement } from "react";
import AssembledAvatarDetails from "@components/AssembledAvatarDetails";
import BottomBanner from "@components/BottomBanner";
import parentStyles from "../../RemintTransactionMessages.module.scss";
import styles from "./Success.module.scss";
import { TAvatar } from "@customTypes/avatar";

interface ISuccess {
    avatar: TAvatar | null;
    txId: string | null;
}

const Success = ({ avatar, txId }: ISuccess): ReactElement => (
    <div className={parentStyles.remintContainer}>
        <div className={styles.assambledCongratz}>
            <p>
                <span className={parentStyles.gold}>CONGRATZ!</span> YOU ARE CROWNED! <br />
                SOME CHANGES MAY TAKE A FEW MINUTES TO SHOW UP
            </p>
        </div>

        <div className={styles.assembledAvatar}>{avatar && <AssembledAvatarDetails avatar={avatar} txId={txId} />}</div>

        <BottomBanner />
    </div>
);

export default Success;
