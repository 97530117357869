import styles from "./QuantitySelector.module.scss";
import button_styles from "@elements/Button/Button.module.scss";

interface IQuantitySelector {
    quantity: number;
    disabled: boolean;
    addQuantity: () => void;
    subtractQuantity: () => void;
}

const QuantitySelector = ({ quantity, disabled, addQuantity, subtractQuantity }: IQuantitySelector): JSX.Element => (
    <div className={styles.container}>
        <button
            onClick={() => !disabled && subtractQuantity()}
            className={disabled ? button_styles.quantity_selector_disabled : button_styles.quantity_selector}
            data-testid="subtract-button"
        >
            -
        </button>
        <div>{quantity}</div>
        <button
            onClick={() => !disabled && addQuantity()}
            className={disabled ? button_styles.quantity_selector_disabled : button_styles.quantity_selector}
            data-testid="add-button"
        >
            +
        </button>
    </div>
);

export default QuantitySelector;
