import { ReactElement } from "react";
import Button, { Variant } from "@elements/Button";
import { TTrait } from "@customTypes/trait";
import { TAvatar } from "@customTypes/avatar";
import { REGENERATOR_API } from "@constants/env";
import { ORIGINAL_IMAGE } from "@constants/apiEndpoints";
import SimulatorBuyTraitOpenseaButton from "../SimulatorBuyTraitOpenseaButton";
import SimulatorBuyAvatarOpenseaButton from "../SimulatorBuyAvatarOpenseaButton";
import styles from "./SimulatorButtons.module.scss";

interface ISimuatorButtons {
    selectedTrait: TTrait | null;
    selectedAvatar: TAvatar | null;
    originalAvatar: TAvatar | null;
    allTraitsAreMine: boolean;
    isOwner: boolean;
    originalIsEqualToSelected: boolean;
    selectedAvatarHasMintedTraits: boolean;
    createSelectedAvatarTraits: () => void;
    assembleSelectedAvatar: () => void;
    randomTraits: () => void;
    resetAvatar: () => void;
    freeze: () => void;
}

const SimulatorButtons = ({
    selectedTrait,
    selectedAvatar,
    originalAvatar,
    allTraitsAreMine,
    isOwner,
    originalIsEqualToSelected,
    selectedAvatarHasMintedTraits,
    assembleSelectedAvatar,
    createSelectedAvatarTraits,
    resetAvatar,
    freeze,
    randomTraits,
}: ISimuatorButtons): ReactElement => {
    const canFreezeAvatar = (): boolean =>
        isOwner && allTraitsAreMine && mandatoryTraits() && !selectedAvatar?.frozen && originalIsEqualToSelected;
    const cantBuyTraitInOpensea = (): boolean => !selectedTrait || !selectedTrait.minted || !selectedTrait.rarityString;
    const cantBuyAvatarInOpensea = (): boolean => !selectedTrait || !selectedTrait.rarityString;
    const canRemint = (): boolean => isOwner && allTraitsAreMine && !originalIsEqualToSelected;
    const cantMintTraits = (): boolean => isOwner && allTraitsAreMine && originalIsEqualToSelected;
    const canDownload = (): boolean => isOwner && originalIsEqualToSelected;

    const mandatoryTraits = (): boolean => {
        const types = ["crown", "eyes", "mouth", "face", "body", "background"];
        let hasNeededTraits = true;

        types.forEach((type) => {
            if (originalAvatar!.traits[type].tokenId === 0) {
                hasNeededTraits = false;
            }
        });

        return hasNeededTraits;
    };

    return (
        <div className={styles.container}>
            <SimulatorBuyTraitOpenseaButton
                selectedTrait={selectedTrait}
                className={cantBuyTraitInOpensea() ? styles.disabled : undefined}
            />

            <SimulatorBuyAvatarOpenseaButton
                selectedTrait={selectedTrait}
                className={cantBuyAvatarInOpensea() ? styles.disabled : undefined}
            />

            <div className={styles.randomReset}>
                <Button
                    className={styles.random}
                    variant={Variant.TertiaryHeader}
                    onClick={randomTraits}
                    aria-label="Put random traits on avatar"
                >
                    Random
                </Button>

                <Button
                    className={styles.reset}
                    variant={Variant.TertiaryHeader}
                    onClick={resetAvatar}
                    aria-label="Reset avatar to the original"
                >
                    Reset
                </Button>
            </div>

            {selectedAvatar?.frozen ? (
                <a
                    className={canDownload() ? styles.downloadLink : styles.downloadLinkDisabled}
                    href={
                        canDownload()
                            ? `${REGENERATOR_API}${ORIGINAL_IMAGE.replace(":id", selectedAvatar.id.toString())}`
                            : "#"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                    download
                >
                    DOWNLOAD HI-RES Q+K
                </a>
            ) : (
                <Button
                    className={selectedAvatarHasMintedTraits ? styles.remint : styles.mintTraits}
                    variant={Variant.SecondaryHeader}
                    onClick={selectedAvatarHasMintedTraits ? assembleSelectedAvatar : createSelectedAvatarTraits}
                    disabled={selectedAvatarHasMintedTraits ? !canRemint() : !cantMintTraits()}
                >
                    {selectedAvatarHasMintedTraits ? "Re-mint" : "Mint Traits"}
                </Button>
            )}

            <Button
                className={canFreezeAvatar() ? styles.freeze : styles.disabledFreeze}
                variant={Variant.SecondaryHeader}
                onClick={freeze}
                disabled={!canFreezeAvatar()}
            >
                Freeze Q+K
            </Button>
        </div>
    );
};

export default SimulatorButtons;
