import Web3 from "web3";
import axios from "axios";
import { useEffect } from "react";
import { getBurnReducer, TBurnPayload, TError } from "@reducers/burnReducer";
import { EActionTypes, EContractTransactionStatus, ETransactionErrorName, ETransactionStatus } from "@constants/enums";
import { TBurnInput } from "@customTypes/burn/contractInput";
import { BURNABLE_SIGNATURE } from "@constants/apiEndpoints";
import { BURN_CONTRACT_ADDR, IMAGES_API_BASE_URL } from "@constants/env";
import usePrevious from "@hooks/usePrevious";
import useContractsInstancer from "@hooks/useContractsInstancer";
import contracts from "@constants/burnContracts";

interface IUseBurnContractReturn {
    state: TBurnPayload;
    status: ETransactionStatus | undefined;
    txId: string | null;
    error: TError | null | undefined;
    burn: (inputs: TBurnInput) => Promise<void>;
}

const useBurnContract = (web3: Web3 | undefined, walletAddress: string): IUseBurnContractReturn => {
    const [
        burnContract,
        qkContract,
        bodyContract,
        eyesContract,
        faceContract,
        hairContract,
        crownContract,
        dressContract,
        mouthContract,
        backgroundContract,
        heroinesContract,
    ] = useContractsInstancer(web3, contracts);
    const [state, dispatch] = getBurnReducer();
    const { error, txId, status } = state;
    const prevStatus = usePrevious(status);

    useEffect(() => {
        if (prevStatus === ETransactionStatus.IN_PROGRESS && status === ETransactionStatus.ERROR) {
            dispatchError({
                name: ETransactionErrorName.CANCELED_BY_USER,
                message: ETransactionErrorName.CANCELED_BY_USER,
                code: 4001,
            } as TError);
        }
    }, [prevStatus, status]);

    const burn = async ({ types: tokenTypes, tokenIds }: TBurnInput) => {
        prepareToCall();

        const allContractsApproved = await approveAllContracts(tokenTypes);

        if (!allContractsApproved) {
            return;
        }

        registerBurnEvent();

        try {
            const response = await getSignature(tokenTypes, tokenIds);
            const { types, tokenIds: ids, codes, signature } = response;

            await burnContract.methods
                .burn(types, ids, codes, signature)
                .send({
                    from: walletAddress,
                })
                .on(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) => {
                    dispatch({
                        type: EActionTypes.TRANSACTION_HASH,
                        payload: {
                            txId: txId,
                        },
                    });
                });
        } catch ({ code, message }: unknown) {
            console.log("Catch Error", code, message);
            dispatchError({ name: "Burn error", message, code } as TError);
        }
    };

    const registerBurnEvent = () => {
        burnContract.events
            .BurnedAssets({}, (error: unknown, _) => {
                if (error) {
                    console.error(error);
                    const message = error instanceof Error ? error.message : ETransactionErrorName.UNKNOWN_ERROR;
                    dispatchError({
                        name: "Register Burn Event error",
                        message,
                        code: -1,
                    });
                    return;
                }
            })
            .once(EContractTransactionStatus.DATA, ({ returnValues: { _owner, _types, _tokenIds } }) => {
                if (_owner.toLowerCase() !== walletAddress.toLowerCase()) {
                    return;
                }

                dispatch({
                    type: EActionTypes.DATA,
                    payload: {
                        error: null,
                        txId,
                        status: ETransactionStatus.SUCCESS,
                    },
                });
            })
            .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                dispatchError({ name: "Register Burn Event error", message, code } as TError)
            );
    };

    const getSignature = async (types: string[], tokenIds: number[]): Promise<any> => {
        const signedMessage = await axios.post(
            BURNABLE_SIGNATURE.replace(":address", walletAddress),
            {
                types: types,
                tokenIds: tokenIds,
            },
            { baseURL: IMAGES_API_BASE_URL }
        );

        return signedMessage.data;
    };

    const approveAllContracts = async (types: string[]): Promise<boolean> => {
        const burnContracts = {
            avatar: qkContract,
            background: backgroundContract,
            crown: crownContract,
            hair: hairContract,
            face: faceContract,
            eyes: eyesContract,
            mouth: mouthContract,
            // beard: beardContract,
            body: bodyContract,
            dress: dressContract,
            // extra: extraContract,
            heroinesD: heroinesContract,
            heroinesH: heroinesContract,
        };

        const typesUnique = Array.from(new Set(types));

        try {
            for (const type of typesUnique) {
                const contract = burnContracts[type];
                const isApproved = await contract.methods.isApprovedForAll(walletAddress, BURN_CONTRACT_ADDR).call();

                if (!isApproved) {
                    await contract.methods.setApprovalForAll(BURN_CONTRACT_ADDR, true).send({
                        from: walletAddress,
                    });
                }
            }

            return true;
        } catch ({ code, message }: unknown) {
            console.error("Approval Error", code, message);
            dispatchError({ name: "Approval error", message, code } as TError);
            return false;
        }
    };

    const prepareToCall = () =>
        dispatch({
            type: EActionTypes.CALLING_CONTRACT,
            payload: {
                error: null,
                txId: null,
                status: ETransactionStatus.WAITING_CONFIRMATION,
            },
        });

    const dispatchError = ({ name, message, code }: TError) =>
        dispatch({
            type: code === 4001 ? EActionTypes.RESET : EActionTypes.ERROR,
            payload: {
                error: { name, message, code },
                txId: null,
                status: code === 4001 ? ETransactionStatus.NO_ACTION : ETransactionStatus.ERROR,
            },
        });

    return {
        state,
        status,
        txId,
        error,
        burn,
    };
};

export default useBurnContract;
