export const MESSAGE_TO_SIGN = "/messageToSign";
export const WHITELIST = "/whitelist/:address";
export const SIGNATURE = "/signature/:address";
export const CLAIM_PRICE = "/claim-price/:address";
export const MINT_TRAITS = "/mint-traits/:tokenid";
export const AVATARS = "/addresses/:address/avatars";
export const AVATAR = "/avatars/:tokenid";
export const TRAITS = "/addresses/:address/traits";
export const TRAIT = "/traits/original/:type/:tokenid";
export const GENERATE_IMAGES = "/generate-images?";
export const UNIQUE_TRAITS = "/traits/:type";
export const AVATARS_RANKING = "/avatars/ranking";
export const TRAITS_RANKING = "/traits/ranking";
export const AVATAR_ORIGINAL = "/avatars/original/:tokenid";
export const CHECK_DUPLICATE = "/avatar/check_duplicate";
export const PREVIEW_AVATAR = "/avatar/preview";
export const ORIGINAL_IMAGE = "/avatar/:id/download";
export const RANDOM_TRAITS = "/avatars/randomize";
export const BURNABLE_TRAITS = "/addresses/:address/burnable_traits/:type";
export const BURNABLE_AVATARS = "/addresses/:address/burnable_avatars";
export const BURNABLE_HEROINES = "/heroines/:address";
export const BURNABLE_SIGNATURE = "/burn/:address/signature";
export const BURNABLE_HISTORY = "/burned_tokens";
