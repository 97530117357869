import { ReactElement } from "react";
import arrow from "@images/opensea-arrow.png";
import styles from "./RankingTraitItem.module.scss";

interface IRankingTraitItem {
    name: string;
    preview: string;
    rank: number;
    score: number;
    type: string;
    minted: boolean;
    rarityString: string;
    selectedRow: string;
    handleSelectedRow: (rarityString: string) => void;
}

const RankingTraitItem = ({
    name,
    preview,
    rank,
    score,
    type,
    minted,
    rarityString,
    selectedRow,
    handleSelectedRow,
}: IRankingTraitItem): ReactElement => {
    const getLink = (): string => {
        let link =
            "https://opensea.io/collection/queenskings?search[sortAscending]=true&search[sortBy]=PRICE" +
            `&search[stringTraits][0][name]=${type === "extra" ? "power" : type}` +
            `&search[stringTraits][0][values][0]=${name} - ${rarityString}`;

        if (minted) {
            link =
                "https://opensea.io/collection/queenskings-traits?search[sortAscending]=true&search[sortBy]=PRICE" +
                `&search[stringTraits][0][name]=Rarity&search[stringTraits][0][values][0]=${rarityString}`;
        }

        return link;
    };

    return (
        <tr
            className={`${styles.container} ${selectedRow === rarityString ? styles.selected : ""}`}
            role="button"
            tabIndex={0}
            onClick={() => handleSelectedRow(rarityString)}
        >
            <td className={styles.avid}>{name}</td>
            <td className={styles.preview}>
                <img src={preview} alt={name} />
            </td>
            <td className={styles.rank}>{rank}</td>
            <td className={styles.score}>{score}</td>
            <td className={styles.viewop}>
                {/*Necesitamos el type en cada trait y por tipo llevarlo al contrato que corersponde*/}
                <a href={getLink()} target="_blank" rel="noopener noreferrer" aria-label="View this trait in Opensea!">
                    <img src={arrow} alt="" />
                </a>
            </td>
        </tr>
    );
};

export default RankingTraitItem;
