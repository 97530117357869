import { ChangeEvent, MouseEvent, KeyboardEvent, FormEvent, useEffect, useState } from "react";
import { IAvatarRanking } from "@utils/scripts/AvatarUtils";
import { EActiveTab } from "./components/RankingTabs";
import { ITraitRanking } from "@customTypes/trait";
import useGetRankingDataFromApi from "@hooks/request/useGetRankingDataFromApi";

type TParams = {
    limit: number;
    traitType: string;
    traitName: string;
    avatarId: string;
    actualTraitPage: number;
    actualAvatarPage: number;
};

interface IUseRanking {
    activeTab: EActiveTab;
    traitSearch: string;
    avatarSearch: string;
    traits: ITraitRanking[];
    avatars: IAvatarRanking[];
    selectedTrait: ITraitRanking | undefined;
    selectedAvatar: IAvatarRanking | undefined;
    isPageLoading: boolean;
    params: TParams;
    traitPage: number;
    avatarPage: number;
    totalTraits: number;
    isFetchingTraits: boolean;
    isFetchingAvatars: boolean;
    handleTabChange: (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>, tab: EActiveTab) => void;
    handleAvatarSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleAvatarSubmit: (e: FormEvent) => void;
    handleResetAvatarSearch: () => void;
    handleSelectedAvatarRow: (id: number) => void;
    handleTraitSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleTraitTypeChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    handleResetTraitSearch: () => void;
    handleTraitSubmit: (e: FormEvent) => void;
    handleSelectedTraitRow: (rarityString: string) => void;
    handleItemsLimit: (e: ChangeEvent<HTMLSelectElement>) => void;
    handleNextPage: () => void;
    handlePrevPage: () => void;
}

const useRanking = (): IUseRanking => {
    const [activeTab, setActiveTab] = useState(EActiveTab.AVATARS);
    const [traitSearch, setTraitSearch] = useState("");
    const [avatarSearch, setAvatarSearch] = useState("");
    const [traits, setTraits] = useState<ITraitRanking[]>([]);
    const [avatars, setAvatars] = useState<IAvatarRanking[]>([]);
    const [selectedTrait, setSelectedTrait] = useState<ITraitRanking | undefined>(undefined);
    const [selectedAvatar, setSelectedAvatar] = useState<IAvatarRanking | undefined>(undefined);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [params, setParams] = useState<TParams>({
        limit: 20,
        traitType: "extra",
        traitName: "",
        avatarId: "",
        actualTraitPage: 0,
        actualAvatarPage: 0,
    });

    const { isFetchingAvatars, isFetchingTraits, traitPage, avatarPage, totalTraits, getAvatars, getTraits } =
        useGetRankingDataFromApi();

    useEffect(() => {
        if (activeTab === EActiveTab.AVATARS && avatars.length === 0) {
            fetchAvatars();
            setIsPageLoading(false);
            return;
        }

        traits.length === 0 && fetchTraits();
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === EActiveTab.AVATARS) {
            fetchAvatars();
            return;
        }

        fetchTraits();
    }, [params]);

    const fetchAvatars = async () => {
        const { actualAvatarPage, limit, avatarId } = params;
        const avts = await getAvatars(actualAvatarPage, limit, Number(avatarId));
        setAvatars(avts);
        setSelectedAvatar(avts[0]);
    };

    const fetchTraits = async () => {
        const { traitType, actualTraitPage, limit, traitName } = params;
        const trts = await getTraits(traitType === "powers" ? "extra" : traitType, actualTraitPage, limit, traitName);
        setTraits(trts);
        setSelectedTrait(trts[0]);
    };

    const handleTabChange = (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>, tab: EActiveTab): void => {
        if ((e.target as Element).tagName.toLowerCase() === "input") {
            return;
        }
        setActiveTab(tab);
    };

    const handleAvatarSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setAvatarSearch(e.target.value);
    };

    const handleResetAvatarSearch = () => {
        setParams({ ...params, avatarId: "", actualAvatarPage: 0 });
        setAvatarSearch("");
    };

    const handleAvatarSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (avatarSearch) {
            setParams({ ...params, avatarId: avatarSearch, actualAvatarPage: 0 });
            return;
        }

        setParams({ ...params, avatarId: "", actualAvatarPage: 0 });
    };

    const handleSelectedAvatarRow = (id: number): void => {
        setSelectedAvatar(avatars.find((avatar) => avatar.id === id));
    };

    const handleTraitSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setTraitSearch(e.target.value);
    };

    const handleResetTraitSearch = () => {
        setParams({ ...params, traitName: "", actualTraitPage: 0 });
        setTraitSearch("");
    };

    const handleTraitTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setParams({ ...params, traitType: e.target.value, actualTraitPage: 0 });
    };

    const handleTraitSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (traitSearch) {
            setParams({ ...params, traitName: traitSearch, actualTraitPage: 0 });
            return;
        }
        setParams({ ...params, traitName: "", actualTraitPage: 0 });
    };

    const handleSelectedTraitRow = (rarityString: string): void => {
        setSelectedTrait(traits.find((trait) => trait.rarityString === rarityString));
    };

    const handleItemsLimit = (e: ChangeEvent<HTMLSelectElement>) => {
        setParams({ ...params, limit: Number(e.target.value) });
    };

    const handleNextPage = () => {
        if (activeTab === EActiveTab.AVATARS) {
            setParams({ ...params, actualAvatarPage: params.actualAvatarPage + 1 });
            return;
        }

        setParams({ ...params, actualTraitPage: params.actualTraitPage + 1 });
    };

    const handlePrevPage = () => {
        if (activeTab === EActiveTab.AVATARS) {
            setParams({ ...params, actualAvatarPage: params.actualAvatarPage - 1 });
            return;
        }

        setParams({ ...params, actualTraitPage: params.actualTraitPage - 1 });
    };

    return {
        activeTab,
        traitSearch,
        avatarSearch,
        traits,
        avatars,
        selectedTrait,
        selectedAvatar,
        isPageLoading,
        params,
        traitPage,
        avatarPage,
        totalTraits,
        isFetchingTraits,
        isFetchingAvatars,
        handleTabChange,
        handleAvatarSearchChange,
        handleAvatarSubmit,
        handleResetAvatarSearch,
        handleSelectedAvatarRow,
        handleTraitSearchChange,
        handleTraitTypeChange,
        handleResetTraitSearch,
        handleTraitSubmit,
        handleSelectedTraitRow,
        handleItemsLimit,
        handleNextPage,
        handlePrevPage,
    };
};

export default useRanking;
