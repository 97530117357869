import axios from "axios";
import { useEffect, useState } from "react";
import { IMAGES_API_BASE_URL } from "@constants/env";
import { BURNABLE_HISTORY } from "@constants/apiEndpoints";
import { IBurnedAssetApiResponse } from "@customTypes/royalList/historyBurnedAsset";
import assetApiResponseToAsset from "@utils/mappers/royalList/assetApiResponseToAsset";

interface IUseGetHistoryBurnedAssetsReturn {
    data: any[];
    total: number;
    currentPage: number;
    isLoading: boolean;
    error: string;
}

const useGetHistoryBurnedAssets = (page?: number, limit?: number): IUseGetHistoryBurnedAssetsReturn => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [error, setError] = useState("");

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setIsLoading(true);
        axios
            .get(BURNABLE_HISTORY, {
                baseURL: IMAGES_API_BASE_URL,
                params: {
                    page,
                    limit,
                },
                signal,
            })
            .then((response) => {
                if (response) {
                    const tokens = response.data.tokens.map((token: IBurnedAssetApiResponse) =>
                        assetApiResponseToAsset(token)
                    );
                    setData(tokens);
                    setTotal(response.data.total);
                    setCurrentPage(response.data.page);
                }
            })
            .catch((error) => setError(error))
            .finally(() => setIsLoading(false));

        return () => {
            controller.abort();
        };
    }, [page, limit]);

    return {
        data,
        total,
        currentPage,
        isLoading,
        error,
    };
};

export default useGetHistoryBurnedAssets;
