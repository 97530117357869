import { ReactElement } from "react";
import styles from "./Row.module.scss";

interface IRow {
    id: string;
    name: string;
    source: string;
    selectedRowId: string;
    checked: boolean;
    handleChecked: (id: string) => void;
    handleSelectedRow: (id: string) => void;
}

const Row = ({ id, name, source, selectedRowId, checked, handleChecked, handleSelectedRow }: IRow): ReactElement => {
    return (
        <tr
            className={`${styles.container} ${selectedRowId === id ? styles.selected : ""}`}
            role="button"
            tabIndex={0}
            onClick={() => handleSelectedRow(id)}
        >
            <td className={styles.name}>{name}</td>
            <td className={styles.preview}>
                <img src={`${source}?img-width=118`} alt={name} />
            </td>
            <td className={styles.select}>
                <input type="checkbox" id={`${name}_${id}`} checked={checked} onChange={() => handleChecked(id)} />
                <label htmlFor={`${name}_${id}`}>
                    <span />
                </label>
            </td>
        </tr>
    );
};

export default Row;
