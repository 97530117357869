import { ReactNode } from "react";
import styles from "./Button.module.scss";

export enum Variant {
    PrimaryHeader,
    SecondaryHeader,
    TertiaryHeader,
    QuaternaryHeader,
    Link,
}

const stylesMap = {
    [Variant.PrimaryHeader]: styles.primaryHeader,
    [Variant.SecondaryHeader]: styles.secondaryHeader,
    [Variant.TertiaryHeader]: styles.tertiaryHeader,
    [Variant.QuaternaryHeader]: styles.quaternaryHeader,
    [Variant.Link]: styles.link,
};

interface ButtonProps {
    children: ReactNode;
    className?: string;
    variant?: Variant;
    [x: string]: unknown;
}

const Button = ({ children, className, variant = Variant.PrimaryHeader, ...attributes }: ButtonProps): JSX.Element => (
    <button className={`${className} ${stylesMap[variant]}`} {...attributes}>
        {children}
    </button>
);

export default Button;
