import { Dispatch, useReducer } from "react";
import { EActionTypes, ETransactionStatus } from "../constants/enums";

export type TError = {
    name: string;
    message: string;
    code: number;
};

type TAvatarPayload = {
    tokenIdList: string[] | null;
    traitAddresses: string[] | null;
    txId: string | null;
    error: TError | null;
    status: ETransactionStatus;
};

type TAction = {
    type: EActionTypes;
    payload: TAvatarPayload;
};

const initialState: TAvatarPayload = {
    tokenIdList: null,
    traitAddresses: null,
    txId: null,
    error: null,
    status: ETransactionStatus.NO_ACTION,
};

const avatarReducer = (state: TAvatarPayload, action: TAction) => {
    const {
        type,
        payload: { tokenIdList, traitAddresses, error, txId, status },
    } = action;

    switch (type) {
        case EActionTypes.CALLING_CONTRACT:
            return {
                ...state,
                txId,
                tokenIdList,
                traitAddresses,
                error,
                status,
            };
        case EActionTypes.TRANSACTION_HASH:
            return {
                ...state,
                txId,
                status,
            };
        case EActionTypes.DATA:
            return {
                ...state,
                tokenIdList,
                traitAddresses,
                status,
            };
        case EActionTypes.ERROR:
            return {
                ...state,
                error,
                tokenIdList: null,
                traitAddresses: null,
                txId: null,
                status,
            };
        case EActionTypes.RESET:
            return initialState;
        default:
            throw new Error("Unkwnown action type");
    }
};

export const getAvatarReducer = (): [state: TAvatarPayload, dispatch: Dispatch<TAction>] =>
    useReducer(avatarReducer, initialState);
