import { IAsset } from "@customTypes/burn/asset";
import { IHeroineApiResponse } from "@customTypes/heroine";

export const heroinesApiResponseToAsset = ({ name, tokenId, image, type }: IHeroineApiResponse): IAsset => {
    return {
        id: String(tokenId),
        name,
        image,
        type: type,
    };
};
