import { ReactElement, ReactNode } from "react";
import Caption from "./components/Caption";
import Image from "./components/Image";
import Actions from "./components/Actions";
import styles from "./Preview.module.scss";

interface IPreview {
    image: ReactNode;
    caption: ReactNode;
    actions: ReactNode;
}

const Preview = ({ image, caption, actions }: IPreview): ReactElement => {
    return (
        <div className={styles.container}>
            {image}
            {caption}
            {actions}
        </div>
    );
};

Preview.Image = Image;
Preview.Caption = Caption;
Preview.Actions = Actions;

export default Preview;
