import { ReactElement } from "react";
import skull from "@images/technical-error.png";
import styles from "./TechnicalError.module.scss";

interface ITechicalError {
    children?: ReactElement;
}

const TechnicalError = ({ children }: ITechicalError): ReactElement => (
    <div className={styles.container}>
        {children}
        <img src={skull} alt="" />
    </div>
);

export default TechnicalError;
