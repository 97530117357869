import { ReactElement } from "react";
import { TAvatar } from "@customTypes/avatar";
import { ETransactionStatus } from "@constants/enums";
import TechnicalError from "@components/TechnicalError";
import InProgress from "./components/InProgress";
import Success from "./components/Success";
import WaitingConfirmation from "./components/WaitingConfirmation";
import styles from "./FreezeTransactionMessages.module.scss";

interface IFreezeTransactionMessages {
    txId: string | null;
    isCopy: boolean;
    avatar: TAvatar | null;
    status: ETransactionStatus;
}

enum Steps {
    WAITING_CONFIRMATION = ETransactionStatus.WAITING_CONFIRMATION,
    IN_PROGRESS = ETransactionStatus.IN_PROGRESS,
    SUCCESS = ETransactionStatus.SUCCESS,
    ERROR = ETransactionStatus.ERROR,
}

type StepConfig = ReactElement;

const FreezeTransactionMessages = ({ status, txId, isCopy, avatar }: IFreezeTransactionMessages): ReactElement => {
    const messageByStatus: Record<Steps, StepConfig> = {
        [Steps.WAITING_CONFIRMATION]: <WaitingConfirmation />,
        [Steps.IN_PROGRESS]: <InProgress isCopy={isCopy} txId={txId} />,
        [Steps.SUCCESS]: <Success isCopy={isCopy} avatar={avatar} />,
        [Steps.ERROR]: (
            <TechnicalError>
                <>
                    <p className={styles.technicalError}>TECHNICAL ERROR</p>
                    <p>SOMETHING WENT WRONG! THE TRANSACTION DID NOT GO THROUGH.</p>
                    <p>BE BRAVE, SEVERIGN, NEVER SURRENDER! TRY FREEZING AGAIN.</p>
                </>
            </TechnicalError>
        ),
    };

    return messageByStatus[status];
};

export default FreezeTransactionMessages;
