import { useEffect, useState } from "react";
import { sortTraits } from "@utils/scripts/AvatarUtils";
import { QK_CONTRACT_ADDR } from "@constants/env";
import { TAvatar } from "@customTypes/avatar";
import { useWeb3Context } from "@nftstudios/web3-provider";
import styles from "./AssembledAvatarDetails.module.scss";

interface IAvatarDetail {
    avatar: TAvatar;
    txId: string | null;
}

const AssembledAvatarDetails = ({ avatar, txId }: IAvatarDetail): JSX.Element => {
    const { web3 } = useWeb3Context();
    const { id, originalTokenId, image, traits } = avatar;
    const [newTokenId, setNewTokenId] = useState(0);

    useEffect(() => {
        if (!web3 || !txId) {
            return;
        }

        web3.eth.getTransactionReceipt(txId).then((tx) => {
            let reMinted = false;
            for (const log of tx.logs) {
                if (parseInt(log.topics[3]) === id) {
                    reMinted = true;
                    break;
                }
            }

            if (tx.logs.length === 0 || !reMinted) {
                setNewTokenId(id);

                return;
            }

            setNewTokenId(parseInt(tx.logs[tx.logs.length - 1].topics[3]));
        });
    }, []);

    return (
        <div className={styles.container}>
            <p className={styles.avatarIdMobile}>
                Q<span>+</span>K #{originalTokenId}
            </p>
            <div className={styles.leftContainer} />
            <div className={styles.imageContainer}>
                <img src={image} alt="assembled avatar" />
            </div>
            <div className={styles.rightContainer}>
                <p className={styles.avatarIdDesktop}>
                    Q<span>+</span>K #{originalTokenId}
                </p>
                <div className={styles.traitContainer}>
                    {Object.keys(sortTraits(traits)).map((key) => (
                        <div className={styles.trait} key={key}>
                            <p>
                                <span data-testid="trait-type-text">
                                    {traits[key].type === "extra" ? "powers" : traits[key].type}
                                </span>
                                <span data-testid="trait-percent-text">
                                    {traits[key].rarity > 0 ? `${traits[key].rarity}%` : "-"}
                                </span>
                            </p>
                        </div>
                    ))}
                </div>
                <div className={styles.btnContainer}>
                    <a
                        href={`https://opensea.io/assets/${QK_CONTRACT_ADDR}/${newTokenId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        VIEW IN OPENSEA
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AssembledAvatarDetails;
