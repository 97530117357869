import { useEffect, useState } from "react";
import { ETabType } from "@enums/burn";
import { IAsset } from "@customTypes/burn/asset";
import { TCurrentAsset } from "@customTypes/burn/currentAsset";

interface IUseSelectedAssets {
    currentAssets: TCurrentAsset;
    updateSelectedAvatar: (avatar: IAsset) => void;
    updateSelectedTrait: (trait: IAsset) => void;
    updateSelectedHeroine: (heroine: IAsset) => void;
}

type TUseSelectedAssetsInputs = {
    tab: ETabType;
    traits: IAsset[];
    traitsToBurn: IAsset[];
    avatars: IAsset[];
    avatarsToBurn: IAsset[];
    heroines: IAsset[];
    heroinesToBurn: IAsset[];
    traitPage: number;
    avatarPage: number;
    heroinesPage: number;
    total: number;
    isFetchingTraits: boolean;
    isFetchingAvatars: boolean;
    isFetchingHeroines: boolean;
};

const useSelectedAssets = ({
    tab,
    traits,
    traitsToBurn,
    avatars,
    avatarsToBurn,
    heroines,
    heroinesToBurn,
    traitPage,
    avatarPage,
    heroinesPage,
    total,
    isFetchingTraits,
    isFetchingAvatars,
    isFetchingHeroines,
}: TUseSelectedAssetsInputs): IUseSelectedAssets => {
    const [currentAssets, setCurrentAssets] = useState<TCurrentAsset>({
        items: [],
        selectedItem: null,
        isLoading: false,
        notFoundMessage: "",
        checkedItems: [],
        page: 0,
        totalItems: 0,
    });

    const [selectedTrait, setSelectedTrait] = useState<IAsset>();
    const [selectedHeroine, setSelectedHeroine] = useState<IAsset>();
    const [selectedAvatar, setSelectedAvatar] = useState<IAsset>();

    useEffect(() => {
        if (traits) setSelectedTrait(traits[0]);
        if (avatars) setSelectedAvatar(avatars[0]);
        if (heroines) setSelectedHeroine(heroines[0]);
    }, [traits, avatars, heroines]);

    useEffect(() => {
        if (tab === ETabType.AVATARS) {
            setCurrentAssets({
                items: avatars,
                selectedItem: selectedAvatar ?? avatars[0],
                isLoading: isFetchingAvatars,
                checkedItems: avatarsToBurn,
                notFoundMessage: "No avatars found",
                page: avatarPage,
                totalItems: total,
            });
        } else if (tab === ETabType.TRAITS) {
            setCurrentAssets({
                items: traits,
                selectedItem: selectedTrait ?? traits[0],
                isLoading: isFetchingTraits,
                checkedItems: traitsToBurn,
                notFoundMessage: "No traits found",
                page: traitPage,
                totalItems: total,
            });
        } else {
            setCurrentAssets({
                items: heroines,
                selectedItem: selectedHeroine ?? heroines[0],
                isLoading: isFetchingHeroines,
                checkedItems: heroinesToBurn,
                notFoundMessage: "No heroines found",
                page: heroinesPage,
                totalItems: total,
            });
        }
    }, [
        tab,
        traits,
        avatars,
        heroines,
        isFetchingTraits,
        isFetchingAvatars,
        isFetchingHeroines,
        traitsToBurn,
        avatarsToBurn,
        heroinesToBurn,
        selectedTrait,
        selectedAvatar,
        selectedHeroine,
        avatarPage,
        traitPage,
        heroinesPage,
        total,
    ]);

    const updateSelectedAvatar = (avatar: IAsset) => {
        setSelectedAvatar(avatar);
    };

    const updateSelectedTrait = (trait: IAsset) => {
        setSelectedTrait(trait);
    };

    const updateSelectedHeroine = (heroine: IAsset) => {
        setSelectedHeroine(heroine);
    };

    return {
        currentAssets,
        updateSelectedAvatar,
        updateSelectedTrait,
        updateSelectedHeroine,
    };
};

export default useSelectedAssets;
