import Modal from "react-modal";
import { ReactElement, useEffect, useState } from "react";
import { useSimulatorContext } from "@context/simulatorContext";
import { EProjectTransactionTypes, ETransactionStatus } from "@constants/enums";
import { freezeModalStyles } from "./simulatorUtils";
import { OPENSEA_COLLECTION_URL } from "@constants/env";
import withMaxWidth from "@hocs/withMaxWidth";
import useSimulator from "./useSimulator";
import DefaultLoader from "@components/DefaultLoader";
import AvatarDetailCarousel from "@components/AvatarDetailCarousel";
import FreezeConfirmation from "./components/FreezeConfirmation";
import SimulatorCollection from "./components/SimulatorCollection";
import SimulatorButtons from "./components/SimulatorButtons";
import FreezeTransactionMessages from "./components/FreezeTransactionMessages";
import RemintTransactionMessages from "./components/RemintTransactionMessages";
import SimulatorSelectedTraits from "./components/SimulatorSelectedTraits";
import Rarities from "./components/Rarities";
import magnifier from "@images/magnifier.png";
import cross from "@images/cross.png";
import styles from "./Simulator.module.scss";

const Simulator = (): ReactElement => {
    const [isLoading, setIsLoading] = useState(true);
    const [avatarIsCopy, setAvatarIsCopy] = useState(false);

    const {
        txId,
        status,
        walletAddress,
        transactionType,
        isOpenFreezeModal,
        isLoadingAvatars,
        toggleCarouselReset,
        handleActiveAvatar,
        originalIsEqualToSelected,
        selectedAvatarHasMintedTraits,
        resetAvatar,
        moreAvatars,
        avatarSearch,
        originalAvatar,
        getMoreAvatars,
        assembleSelectedAvatar,
        createSelectedAvatarTraits,
        handleResetAvatarSearch,
        handleAvatarSearchChange,
        handleAvatarSubmit,
        requestOpenFreezeModal,
        requestCloseFreezeModal,
        freezeSelectedAvatar,
        randomTraits,
    } = useSimulator();

    const { selectedTrait, selectedAvatar, avatarsList, isPreviewLoading, updatePreviewLoading, allTraitsAreMine } =
        useSimulatorContext();

    useEffect(() => {
        if (!isLoadingAvatars && isLoading) {
            setIsLoading(false);
        }
    }, [isLoadingAvatars]);

    useEffect(() => {
        requestCloseFreezeModal();
    }, [selectedAvatar?.id]);

    const handleIsAvatarCopy = (duplicate: boolean) => {
        setAvatarIsCopy(duplicate);
    };

    if (isLoading) {
        return <DefaultLoader spinner classname={styles.loader} />;
    }

    if (status !== ETransactionStatus.NO_ACTION && status !== ETransactionStatus.TRAITS_SUCCESS) {
        if (transactionType === EProjectTransactionTypes.FREEZE) {
            return (
                <FreezeTransactionMessages status={status} avatar={selectedAvatar} txId={txId} isCopy={avatarIsCopy} />
            );
        }

        if (transactionType === EProjectTransactionTypes.RE_MINT) {
            return <RemintTransactionMessages status={status} avatar={selectedAvatar} txId={txId} />;
        }
    }

    if (!(avatarsList.length > 0)) {
        return (
            <div className={styles.container}>
                <div className={`${styles.simulatorTitle} ${styles.noAvatars}`}>
                    <p>
                        <span>HACK THE ROYALS</span> AND CREATE YOUR OWN AVATAR IN YOUR OWN IMAGE. <br />
                        CHOOSE THE Q+K YOU WOULD LIKE TO RE-MINT.
                    </p>
                </div>

                <a
                    className={styles.goToCollection}
                    href={OPENSEA_COLLECTION_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    GO TO OPENSEA
                </a>
            </div>
        );
    }

    return (
        <div className={styles.container} data-testid="hack-the-royals-container">
            <div className={styles.simulatorTitle}>
                <p>HACK THE ROYALS 2.0</p>
            </div>

            <div className={styles.grid}>
                <div className={styles.column}>
                    <div className={styles.carouselBox}>
                        {isLoadingAvatars && avatarsList.length === 0 ? (
                            <DefaultLoader classname={styles.loader} spinner />
                        ) : (
                            <>
                                <div className={styles.formContainer}>
                                    <form className={styles.searchForm} onSubmit={handleAvatarSubmit}>
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            className={styles.searchInput}
                                            value={avatarSearch}
                                            placeholder="ID"
                                            onChange={handleAvatarSearchChange}
                                        />
                                        <button type="submit">
                                            <img src={magnifier} alt="Search by avatar id" />
                                        </button>
                                        {avatarSearch && (
                                            <button
                                                className={styles.cross}
                                                type="button"
                                                onClick={handleResetAvatarSearch}
                                            >
                                                <img src={cross} alt="Reset search filter" />
                                            </button>
                                        )}
                                    </form>
                                </div>

                                <AvatarDetailCarousel
                                    classname={styles.carousel}
                                    resetCarousel={toggleCarouselReset}
                                    btnRightClassName={styles.arrows}
                                    btnLeftClassName={styles.arrows}
                                    handleActiveSlide={handleActiveAvatar}
                                    avatarsList={avatarsList}
                                    gutter={0}
                                    numberOfCards={1}
                                    showLeft={!isLoadingAvatars && !isPreviewLoading}
                                    showRight={!isLoadingAvatars && !isPreviewLoading}
                                    moreAvatars={moreAvatars}
                                    getAvatars={getMoreAvatars}
                                    isLoadingAvatars={isLoadingAvatars}
                                    isPreviewLoading={isPreviewLoading}
                                    handlePreviewLoaded={updatePreviewLoading}
                                />
                            </>
                        )}
                    </div>

                    <SimulatorSelectedTraits isLoading={!selectedAvatar || isLoadingAvatars} />

                    <div className={styles.raritiesAndButtonsBox}>
                        <Rarities traits={selectedAvatar?.traits} />
                        <SimulatorButtons
                            isOwner={walletAddress === selectedAvatar?.owner}
                            selectedTrait={selectedTrait}
                            selectedAvatar={selectedAvatar}
                            originalAvatar={originalAvatar}
                            allTraitsAreMine={allTraitsAreMine}
                            originalIsEqualToSelected={originalIsEqualToSelected}
                            selectedAvatarHasMintedTraits={selectedAvatarHasMintedTraits}
                            assembleSelectedAvatar={assembleSelectedAvatar}
                            createSelectedAvatarTraits={createSelectedAvatarTraits}
                            resetAvatar={resetAvatar}
                            freeze={requestOpenFreezeModal}
                            randomTraits={randomTraits}
                        />
                        <Modal
                            isOpen={isOpenFreezeModal}
                            onRequestClose={requestCloseFreezeModal}
                            style={freezeModalStyles}
                        >
                            <FreezeConfirmation
                                isCopy={avatarIsCopy}
                                handleIsCopy={handleIsAvatarCopy}
                                selectedAvatar={selectedAvatar}
                                onConfirmation={freezeSelectedAvatar}
                                onCancel={requestCloseFreezeModal}
                            />
                        </Modal>
                    </div>
                </div>
                <SimulatorCollection />
            </div>
        </div>
    );
};

export default withMaxWidth(Simulator);
