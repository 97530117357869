import avatar_1 from "../../assets/images/avatars/avatar_1.png";
import avatar_2 from "../../assets/images/avatars/avatar_2.png";
import avatar_3 from "../../assets/images/avatars/avatar_3.png";
import avatar_4 from "../../assets/images/avatars/avatar_4.png";
import avatar_5 from "../../assets/images/avatars/avatar_5.png";
import avatar_6 from "../../assets/images/avatars/avatar_6.png";
import avatar_7 from "../../assets/images/avatars/avatar_7.png";
import avatar_8 from "../../assets/images/avatars/avatar_8.png";
import avatar_9 from "../../assets/images/avatars/avatar_9.png";
import avatar_10 from "../../assets/images/avatars/avatar_10.png";
import avatar_11 from "../../assets/images/avatars/avatar_11.png";
import avatar_12 from "../../assets/images/avatars/avatar_12.png";
import avatar_13 from "../../assets/images/avatars/avatar_13.png";
import avatar_14 from "../../assets/images/avatars/avatar_14.png";
import avatar_15 from "../../assets/images/avatars/avatar_15.png";
import avatar_16 from "../../assets/images/avatars/avatar_16.png";
import avatar_17 from "../../assets/images/avatars/avatar_17.png";
import avatar_18 from "../../assets/images/avatars/avatar_18.png";
import avatar_19 from "../../assets/images/avatars/avatar_19.png";
import avatar_20 from "../../assets/images/avatars/avatar_20.png";
import avatar_21 from "../../assets/images/avatars/avatar_21.png";
import avatar_22 from "../../assets/images/avatars/avatar_22.png";
import avatar_23 from "../../assets/images/avatars/avatar_23.png";
import avatar_24 from "../../assets/images/avatars/avatar_24.png";
import avatar_25 from "../../assets/images/avatars/avatar_25.png";
import avatar_26 from "../../assets/images/avatars/avatar_26.png";
import avatar_27 from "../../assets/images/avatars/avatar_27.png";

const avatars = [
    avatar_1,
    avatar_2,
    avatar_3,
    avatar_4,
    avatar_5,
    avatar_6,
    avatar_7,
    avatar_8,
    avatar_9,
    avatar_10,
    avatar_11,
    avatar_12,
    avatar_13,
    avatar_14,
    avatar_15,
    avatar_16,
    avatar_17,
    avatar_18,
    avatar_19,
    avatar_20,
    avatar_21,
    avatar_22,
    avatar_23,
    avatar_24,
    avatar_25,
    avatar_26,
    avatar_27,
];

export const getRandomAvatars = (lengthOfArray: number): string[] => {
    const array: number[] = [];

    while (array.length < lengthOfArray) {
        const result = Math.floor(Math.random() * avatars.length);
        if (array.indexOf(result) === -1) {
            array.push(result);
        }
    }
    const randomAvatars = array.map((n) => avatars[n]);

    return randomAvatars;
};
