import { TAvatar, TAvatarTraits } from "@customTypes/avatar";

type TAvatarTraitItemApiResponse = {
    tokenId: number;
    rarity: number;
    type: string;
    name: string;
    image: string;
    style: string;
};

type TAvatarTraitsApiResponse = {
    background: TAvatarTraitItemApiResponse;
    crown: TAvatarTraitItemApiResponse;
    hair: TAvatarTraitItemApiResponse;
    face: TAvatarTraitItemApiResponse;
    eyes: TAvatarTraitItemApiResponse;
    mouth: TAvatarTraitItemApiResponse;
    beard: TAvatarTraitItemApiResponse;
    body: TAvatarTraitItemApiResponse;
    dress: TAvatarTraitItemApiResponse;
    extra: TAvatarTraitItemApiResponse;
};

export interface IAvatarApiResponse {
    hasMintedTraits: boolean;
    owner: string;
    tokenId: number;
    originalTokenId: number;
    image: string;
    name: string;
    description: string;
    frozen: boolean;
    duplicated: boolean;
    traits: TAvatarTraitsApiResponse;
}

export const apiResponseToAvatar = ({
    hasMintedTraits,
    owner,
    tokenId,
    originalTokenId,
    image,
    name,
    traits,
    frozen,
    duplicated,
}: IAvatarApiResponse): TAvatar => {
    return {
        hasMintedTraits,
        owner,
        id: tokenId,
        originalTokenId,
        frozen,
        image,
        name,
        traits,
        duplicated,
    };
};

export const sortTraits = (traits: TAvatarTraits): TAvatarTraits => {
    const { extra, crown, hair, eyes, mouth, beard, face, dress, body, background } = traits;
    return {
        extra,
        crown,
        hair,
        eyes,
        mouth,
        beard,
        face,
        dress,
        body,
        background,
    };
};

/*
 *  Ranking API
 */

export type TRankingTraitItemApiResponse = {
    name: string;
    tokenId: number;
    rarity: number;
    type: string;
};

export type TRankingAvatarTraitsApiResponse = {
    crown: TRankingTraitItemApiResponse;
    hair: TRankingTraitItemApiResponse;
    face: TRankingTraitItemApiResponse;
    eyes: TRankingTraitItemApiResponse;
    mouth: TRankingTraitItemApiResponse;
    beard: TRankingTraitItemApiResponse;
    body: TRankingTraitItemApiResponse;
    dress: TRankingTraitItemApiResponse;
    extra: TRankingTraitItemApiResponse;
    background: TRankingTraitItemApiResponse;
};

export interface IAvatarRankingApiResponse {
    id: number;
    royalId: number;
    name: string;
    score: number;
    owner: string;
    rank: number;
    preview: string;
    frozen: boolean;
    traits: TRankingAvatarTraitsApiResponse;
}

export type TRankingTraitItem = {
    name: string;
    tokenId: number;
    rarity: number;
    type: string;
};

export type TRankingAvatarTraits = {
    crown: TRankingTraitItem;
    hair: TRankingTraitItem;
    face: TRankingTraitItem;
    eyes: TRankingTraitItem;
    mouth: TRankingTraitItem;
    beard: TRankingTraitItem;
    body: TRankingTraitItem;
    dress: TRankingTraitItem;
    extra: TRankingTraitItem;
    background: TRankingTraitItem;
};

export interface IAvatarRanking {
    id: number;
    royalId: number;
    name: string;
    score: number;
    rank: number;
    owner: string;
    preview: string;
    frozen: boolean;
    traits: TRankingAvatarTraits;
}

export const apiResponseToAvatarRanking = ({
    id,
    royalId,
    name,
    score,
    rank,
    owner,
    preview,
    frozen,
    traits: { crown, hair, face, eyes, mouth, beard, body, dress, extra, background },
}: IAvatarRankingApiResponse): IAvatarRanking => {
    return {
        id,
        royalId,
        name,
        score,
        rank,
        owner,
        preview,
        frozen,
        traits: {
            extra,
            crown,
            hair,
            eyes,
            mouth,
            beard,
            face,
            dress,
            body,
            background,
        },
    };
};
