import { ReactElement } from "react";
import { TTrait } from "@customTypes/trait";
import styles from "./SimulatorBuyTraitOpenseaButton.module.scss";

interface ISimulatorBuyTraitOpenseaButton {
    className?: string;
    selectedTrait?: TTrait | null;
}

const SimulatorBuyTraitOpenseaButton = ({
    selectedTrait,
    className,
}: ISimulatorBuyTraitOpenseaButton): ReactElement => {
    const link =
        `https://opensea.io/collection/queenskings-traits?search[sortAscending]=true&search[sortBy]=PRICE` +
        `&search[stringTraits][1][name]=Tradeable&search[stringTraits][1][values][0]=Yes` +
        `&search[stringTraits][0][name]=Rarity&search[stringTraits][0][values][0]=${selectedTrait?.rarityString}`;

    return (
        <a href={link} className={`${styles.buyOpensea} ${className}`} rel="noopener noreferrer" target="_blank">
            SEARCH IN TRAITS COLLECTION
        </a>
    );
};

export default SimulatorBuyTraitOpenseaButton;
