import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { HACK_THE_ROYALS } from "@constants/routes";
import { OPENSEA_COLLECTION_URL } from "@constants/env";
import AvatarsLinkCarousel from "./components/AvatarLinkCarousel";
import DefaultLoader from "@components/DefaultLoader";
import useGetAvatarsFromApi from "@hooks/request/useGetAvatarFromAPI";
import discordBanner from "@images/banner-hackatao-discord.jpg";
import useMyCollection from "./useMyCollection";
import AllTraits from "./components/AllTraits";
import styles from "./MyCollection.module.scss";

const MyCollection = (): JSX.Element => {
    const { walletAddress } = useWeb3Context();
    const { isLoadingAvatars, isLoadingTraits, avatars, traits, getTraits, getAvatars, moreAvatars } =
        useGetAvatarsFromApi(walletAddress);
    const { mappedTraits, mapTraitsByType } = useMyCollection();

    useEffect(() => {
        if (walletAddress) {
            getAvatars(0);
            getTraits();
        }
    }, [walletAddress]);

    useEffect(() => {
        mapTraitsByType(traits);
    }, [traits.length]);

    const checkTraits = () => !!(traits.length > 0);

    if (!(avatars.length > 0) && !checkTraits() && !isLoadingTraits && !isLoadingAvatars) {
        return (
            <section className={styles.container}>
                <p>
                    <span>MY ROYAL FAMILY</span>
                </p>
                <p>
                    YOU DON'T HAVE ANY AVATAR YET. YOU CAN WAIT FOR THE NEXT DROP TO MINT OR GO TO OPENSEA TO GET ONE.
                </p>

                <a className={styles.goOpenSea} href={OPENSEA_COLLECTION_URL} target="_blank" rel="noopener noreferrer">
                    GO TO OPENSEA
                </a>

                <a
                    className={styles.discordLink}
                    href="https://discord.gg/Hackatao"
                    rel="noopener noreferrer"
                    target="_blank"
                    data-testid="hackatao-link"
                >
                    <img src={discordBanner} alt="hackatao discord" />
                </a>
            </section>
        );
    }

    return (
        <section className={styles.container} data-testid="my-collection-container">
            <p>
                <span>MY ROYAL FAMILY</span>
            </p>

            {checkTraits() ? (
                <p>FOR MORE DETAILS, SELECT YOUR Q+K.</p>
            ) : (
                <p>
                    BY DEFAULT, NFTS OF THE INDIVIDUAL TRAITS HAVE NOT YET BEEN MINTED. IF YOU WOULD LIKE TO{" "}
                    <span>HACK YOUR ROYALS</span> IN ORDER TO ADD OR REMOVE TRAITS, YOU MUST FIRST MINT THE NFTS OF YOUR
                    CURRENT TRAITS.
                </p>
            )}

            {avatars.length > 0 && (
                <Link
                    className={styles.hackTheRoyals}
                    to={HACK_THE_ROYALS}
                    data-testid="my-collection-hack-the-royals-link"
                >
                    HACK THE ROYALS
                </Link>
            )}

            {isLoadingAvatars && avatars.length === 0 ? (
                <DefaultLoader classname={styles.loader} spinner />
            ) : (
                <>
                    {avatars.length > 0 ? (
                        <>
                            <div className={styles.onlyDesktop}>
                                <AvatarsLinkCarousel
                                    avatarsList={avatars}
                                    gutter={10}
                                    numberOfCards={3}
                                    enablePlaceholder
                                    numberOfPlaceHolder={3}
                                    showLeft
                                    showRight
                                    getAvatars={getAvatars}
                                    moreAvatars={moreAvatars}
                                    isLoadingAvatars={isLoadingAvatars}
                                />
                            </div>
                            <div className={styles.onlyMobile}>
                                <AvatarsLinkCarousel
                                    avatarsList={avatars}
                                    gutter={0}
                                    numberOfCards={1}
                                    enablePlaceholder
                                    showLeft
                                    showRight
                                    getAvatars={getAvatars}
                                    moreAvatars={moreAvatars}
                                    isLoadingAvatars={isLoadingAvatars}
                                />
                            </div>
                        </>
                    ) : (
                        <a
                            className={styles.goOpenSea}
                            href={OPENSEA_COLLECTION_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            GO TO OPENSEA
                        </a>
                    )}
                </>
            )}

            <>
                <p className={styles.traitListTitle}>TRAITS LIST</p>

                {checkTraits() ? (
                    <div className={styles.parts}>
                        <p className={styles.haveTraitsText}>
                            LIST OF ALL OF THE TRAITS YOU ARE HOLDING <br />
                            SOME TRAITS MAY TAKE A FEW MINUTES TO SHOW UP
                        </p>

                        {isLoadingTraits ? (
                            <DefaultLoader classname={styles.loader} spinner />
                        ) : (
                            <AllTraits mappedTraits={mappedTraits} />
                        )}
                    </div>
                ) : (
                    <p className={styles.noTraitsParagraph}>
                        YOU HAVEN'T CREATED ANY TRAITS YET. CHOOSE A Q+K FROM YOUR COLLECTION AND MINT ITS TRAITS. SOME
                        TRAITS MAY TAKE A FEW MINUTES TO SHOW UP.
                    </p>
                )}
            </>
        </section>
    );
};
export default MyCollection;
