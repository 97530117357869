import { ReactNode } from "react";
import styles from "./Container.module.scss";

const Container = ({ children }: { children: ReactNode }): JSX.Element => (
    <main className={styles.container}>
        <div className={styles.content}>{children}</div>
    </main>
);

export default Container;
