import { useState } from "react";
import { TTrait } from "@customTypes/trait";

export type TMappedTratis = {
    crown: TTrait[];
    hair: TTrait[];
    eyes: TTrait[];
    mouth: TTrait[];
    face: TTrait[];
    body: TTrait[];
    dress: TTrait[];
    background: TTrait[];
    extra: TTrait[];
    beard: TTrait[];
};

interface TUseMyCollection {
    mappedTraits: TMappedTratis;
    mapTraitsByType: (traits: TTrait[]) => void;
}

const useMyCollection = (): TUseMyCollection => {
    const [mappedTraits, setMappedTratis] = useState<TMappedTratis>({
        crown: [],
        hair: [],
        eyes: [],
        mouth: [],
        face: [],
        body: [],
        dress: [],
        background: [],
        extra: [],
        beard: [],
    });

    const mapTraitsByType = (traits: TTrait[]) => {
        const newMappedTraits = mappedTraits;

        const traitsOrdered = traits.sort((a, b) => {
            if (!a.attributeRarity || !b.attributeRarity) {
                return 0;
            }

            if (a.attributeRarity < b.attributeRarity) {
                return -1;
            }

            if (a.attributeRarity > b.attributeRarity) {
                return 1;
            }

            return 0;
        });

        traitsOrdered.map((trait) => {
            newMappedTraits[trait.type].push(trait);
        });
        setMappedTratis(newMappedTraits);
    };

    return {
        mappedTraits,
        mapTraitsByType,
    };
};

export default useMyCollection;
