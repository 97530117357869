import { ReactElement } from "react";
import { TAvatar } from "@customTypes/avatar";
import { ETransactionStatus } from "@constants/enums";
import TechnicalError from "@components/TechnicalError";
import InProgress from "./components/InProgress";
import Success from "./components/Success";
import WaitingConfirmation from "./components/WaitingConfirmation";
import styles from "./RemintTransactionMessages.module.scss";

interface IRemintTransactionMessages {
    txId: string | null;
    avatar: TAvatar | null;
    status: ETransactionStatus;
}

enum Steps {
    WAITING_CONFIRMATION = ETransactionStatus.WAITING_CONFIRMATION,
    IN_PROGRESS = ETransactionStatus.IN_PROGRESS,
    SUCCESS = ETransactionStatus.SUCCESS,
    ERROR = ETransactionStatus.ERROR,
}

type StepConfig = ReactElement;

const RemintTransactionMessages = ({ status, txId, avatar }: IRemintTransactionMessages): ReactElement => {
    const messageByStatus: Record<Steps, StepConfig> = {
        [Steps.WAITING_CONFIRMATION]: <WaitingConfirmation />,
        [Steps.IN_PROGRESS]: <InProgress txId={txId} />,
        [Steps.SUCCESS]: <Success avatar={avatar} txId={txId} />,
        [Steps.ERROR]: (
            <TechnicalError>
                <>
                    <p className={styles.technicalError}>TECHNICAL ERROR</p>
                    <p>SOMETHING WENT WRONG! THE TRANSACTION DID NOT GO THROUGH.</p>
                    <p>BE BRAVE, SEVERIGN, NEVER SURRENDER! TRY RE-MINTING AGAIN.</p>
                </>
            </TechnicalError>
        ),
    };

    return messageByStatus[status];
};

export default RemintTransactionMessages;
