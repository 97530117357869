import { ReactElement } from "react";
import { FREEZE_CONTRACT_ADDR, QK_CONTRACT_ADDR } from "@constants/env";
import arrow from "@images/opensea-arrow.png";
import styles from "./RankingAvatarItem.module.scss";

interface IRankingAvatarItem {
    id: number;
    royalId: number;
    name: string;
    preview: string;
    rank: number;
    score: number;
    selectedRow: number;
    frozen: boolean;
    handleSelectedRow: (id: number) => void;
}

const RankingAvatarItem = ({
    id,
    royalId,
    name,
    preview,
    rank,
    score,
    selectedRow,
    frozen,
    handleSelectedRow,
}: IRankingAvatarItem): ReactElement => {
    const getUrl = () => (frozen ? `${FREEZE_CONTRACT_ADDR}/${royalId}` : `${QK_CONTRACT_ADDR}/${id}`);

    return (
        <tr
            className={`${styles.container} ${selectedRow === id ? styles.selected : ""}`}
            role="button"
            tabIndex={0}
            onClick={() => handleSelectedRow(id)}
        >
            <td className={styles.avid}>{name}</td>
            <td className={styles.preview}>
                <img src={`${preview}?img-width=118`} alt={name} />
            </td>
            <td className={styles.rank}>{rank}</td>
            <td className={styles.score}>{score}</td>
            <td className={styles.viewop}>
                <a
                    href={`https://opensea.io/assets/${getUrl()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="View this avatar in Opensea!"
                >
                    <img src={arrow} alt="" />
                </a>
            </td>
        </tr>
    );
};
export default RankingAvatarItem;
