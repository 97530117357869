import { ReactElement, useState } from "react";
import { useWeb3Context } from "@nftstudios/web3-provider";
import SimulatorTabContainer from "../SimulatorTabContainer";
import styles from "./SimulatorCollection.module.scss";

export enum EActiveTab {
    OPENSEA = "opensea",
    MY_COLLECTION = "my-collection",
}

const SimulatorCollection = (): ReactElement => {
    const { walletAddress } = useWeb3Context();
    const [activeTab, setActiveTab] = useState(!walletAddress ? EActiveTab.OPENSEA : EActiveTab.MY_COLLECTION);

    return (
        <div className={styles.container}>
            <div className={styles.buttonTabs}>
                <button
                    className={styles.opensea}
                    onClick={() => setActiveTab(EActiveTab.OPENSEA)}
                    data-testid="open-sea-tab-btn"
                >
                    OpenSea Collection
                </button>
                <button
                    className={styles.myCollection}
                    onClick={() => setActiveTab(EActiveTab.MY_COLLECTION)}
                    data-testid="my-collection-tab-btn"
                >
                    My Collection
                </button>
            </div>

            <SimulatorTabContainer activeTab={activeTab} />
        </div>
    );
};

export default SimulatorCollection;
