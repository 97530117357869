import { ReactElement } from "react";
import { EBurnStages } from "@enums/burn";
import { IAsset } from "@customTypes/burn/asset";
import Button from "@elements/Button";
import BottomBanner from "@components/BottomBanner";
import BurnItem from "./components/BurnItem";
import styles from "./Confirmation.module.scss";

interface IConfirmation {
    traitsToBurn: IAsset[];
    avatarsToBurn: IAsset[];
    heroinesToBurn: IAsset[];
    handlePrevStage: (stage: EBurnStages) => void;
    burnAll: () => void;
}

const Confirmation = ({
    handlePrevStage,
    burnAll,
    avatarsToBurn,
    traitsToBurn,
    heroinesToBurn,
}: IConfirmation): ReactElement => {
    const shouldRenderAssets = traitsToBurn.length || avatarsToBurn.length || heroinesToBurn.length;

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>HAVE YOU CERTAINITY ABOUT YOUR DECISION?</h3>
            <p className={styles.subtitle}>YOU ARE ABOUT TO BURN YOUR NFT AND EXPAND THE ROYAL FLAME!</p>
            <div className={styles.assetsToBurn}>
                {shouldRenderAssets && <p className={styles.burnSubitle}>YOU ARE BURNING</p>}

                {traitsToBurn.length > 0 &&
                    traitsToBurn.map(({ id, image, name }) => <BurnItem key={id} image={image} name={name} />)}

                {avatarsToBurn.length > 0 &&
                    avatarsToBurn.map(({ id, image, name }) => <BurnItem key={id} image={image} name={name} />)}

                {heroinesToBurn.length > 0 &&
                    heroinesToBurn.map(({ id, image, name }) => <BurnItem key={id} image={image} name={name} />)}
            </div>
            <div className={styles.buttons}>
                <Button className={styles.goBack} onClick={() => handlePrevStage(EBurnStages.SELECTION)}>
                    GO BACK
                </Button>
                <Button className={styles.burn} onClick={burnAll}>
                    YES
                </Button>
            </div>
            <p className={styles.disclaimer}>*THIS ACTION IS NOT REVERSIBLE.</p>

            <BottomBanner />
        </div>
    );
};

export default Confirmation;
