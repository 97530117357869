import { useState } from "react";
import { Link } from "react-router-dom";
import { HACK_THE_ROYALS } from "@constants/routes";
import { TTrait } from "@customTypes/trait";
import TraitCarousel from "./components/TraitCarousel";
import {
    T_BACKGROUND_CONTRACT_ADDR,
    T_BEARD_CONTRACT_ADDR,
    T_BODY_CONTRACT_ADDR,
    T_CROWN_CONTRACT_ADDR,
    T_DRESS_CONTRACT_ADDR,
    T_EXTRA_CONTRACT_ADDR,
    T_EYES_CONTRACT_ADDR,
    T_FACE_CONTRACT_ADDR,
    T_HAIR_CONTRACT_ADDR,
    T_MOUTH_CONTRACT_ADDR,
} from "@constants/env";
import styles from "./ClaimedTrait.module.scss";

const traitToContract = {
    background: T_BACKGROUND_CONTRACT_ADDR,
    crown: T_CROWN_CONTRACT_ADDR,
    hair: T_HAIR_CONTRACT_ADDR,
    face: T_FACE_CONTRACT_ADDR,
    eyes: T_EYES_CONTRACT_ADDR,
    mouth: T_MOUTH_CONTRACT_ADDR,
    beard: T_BEARD_CONTRACT_ADDR,
    body: T_BODY_CONTRACT_ADDR,
    dress: T_DRESS_CONTRACT_ADDR,
    extra: T_EXTRA_CONTRACT_ADDR,
};

interface IClaimedTrait {
    traitList: TTrait[];
}

const ClaimedTrait = ({ traitList }: IClaimedTrait): JSX.Element => {
    const [selectedTrait, setSelectedTrait] = useState<TTrait | undefined>();

    const handleSelectedTrait = (id: number) => {
        const selected = traitList.find((t) => t.id === id);
        setSelectedTrait(selected ?? undefined);
    };

    return (
        <section className={styles.container}>
            <div className={styles.traitContainer}>
                <div className={styles.carouselContainer}>
                    <TraitCarousel
                        gutter={0}
                        traitList={traitList}
                        showLeft
                        showRight
                        handleActiveSlide={handleSelectedTrait}
                        btnLeftClassName={styles.arrows}
                        btnRightClassName={styles.arrows}
                    />
                </div>

                <p className={styles.traitPercent}>{selectedTrait?.rarity}% Q+K HAVE THIS TRAIT</p>

                <Link to={HACK_THE_ROYALS} className={styles.hackTheRoyalsLink}>
                    HACK IT
                </Link>
                <a
                    href={`https://opensea.io/assets/${selectedTrait ? traitToContract[selectedTrait.type] : ""}/${
                        selectedTrait?.id ?? ""
                    }`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.openSeaLink}
                >
                    VIEW IN OPENSEA
                </a>
            </div>
        </section>
    );
};

export default ClaimedTrait;
