import roadmap from "@images/banner-hackatao-roadmap.jpg";
import styles from "./Roadmap.module.scss";

const Roadmap = (): JSX.Element => (
    <section className={styles.container}>
        <a
            className={styles.discordLink}
            href="https://discord.gg/Hackatao"
            rel="noopener noreferrer"
            target="_blank"
            data-testid="roadmap-discord-link"
        >
            <img src={roadmap} alt="hackatao discord" />
        </a>
    </section>
);

export default Roadmap;
