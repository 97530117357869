import styles from "./Terms.module.scss";

const Terms = (): JSX.Element => (
    <div className={styles.container}>
        <h1>TERMS OF USE</h1>
        <p>Last Revised: August 24, 2022</p>
        <section>
            <h3 className={styles.subtitle}>Welcome to the project Queens+Kings!</h3>
            <p>
                Queens+Kings is the very first non-fungible, cryptographic token (“<b>NFT</b>”) avatar project (the “
                <b>Project</b>”) launched by Hackatao (the “<b>Artists”</b>), in collaboration with NFT studios (the “
                <b>Developers</b>,” and together with Artists, the “<b>Project Team”</b>) and supported by Sotheby’s and
                AMD (the “<b>Promoters”</b>).
            </p>
            <p>
                Queens+Kings features unique digital characters (each, an “<b>Avatar”</b>) in "profile image" (aka
                “profile pic” or "PFP") format, as generated by Artists’ algorithm. Each Avatar will initially
                correspond to a single NFT (an “<b>Avatar NFT”</b>). The Project also includes a plurality of unique,
                non-binary components of the Avatars’ appearance (each, an “<b>Avatar Trait</b>”) that are inspired by
                the visual vocabulary of quintessential Hackatao past drops and the wider cryptoverse. Within certain
                parameters defined by the Artists, these components are algorithmically generated and randomly assigned
                to Avatars in order to render them unique.
            </p>
            <p>
                As set forth more fully in below, Avatar Traits may be available for claim, purchase and/or trade on a
                standalone basis, each available instance of an Avatar Trait corresponding to a single NFT (a{" "}
                <b>“Trait NFT”</b>). Since each Avatar Trait is considered interchangeable, an Avatar can be customized
                by the then-current owner of the corresponding Avatar NFT (the <b>“Collector”</b>).
            </p>
        </section>
        <section>
            <h2>1. Applicability of the Terms</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        These Terms of Use (the <b>“Terms”</b>) regulate the use of the Platform (as that term is
                        defined below in paragraph 1.b.) that has been created specifically for the Project. These Terms
                        form a binding agreement between each Collector or other individual or single entity accessing
                        or making use of the Platform (collectively <b>“Users,”</b> each a <b>“User”</b>), on the one
                        hand, and the Project Team, on the other.
                    </p>
                </li>
                <li>
                    <p>
                        Moreover, these Terms apply to each User’s use of and access to the website currently hosted at{" "}
                        <a href="https://queenskings.hackatao.com">https://queenskings.hackatao.com</a> (the “
                        <b>Site</b>”), including any pages and domains within the Site, applications for smartphones,
                        tablets and other devices, and all interactive features, functions, and application interfaces
                        display on or offered by any of the foregoing (collectively, the “<b>Platform”</b>) and all
                        associated services offered through the Platform (collectively, the “<b>Services”</b>
                        ).
                    </p>
                </li>
                <li>
                    <p>
                        The Project Team reserves the right, at its sole discretion, to change, modify, add or remove
                        portions of these Terms at any time for any reason, and may notify Users of such changes through
                        any of a variety of means, including a change to the 'Last Revised' date set forth above and
                        other reasonable means to be determined at the Project Team's sole discretion. All changes shall
                        be effective immediately. Please check these Terms periodically for changes. A User's continued
                        use of the Site and/or Services after the posting of changes to these Terms constitutes the
                        User's binding acceptance of such changes. If a User does not accept these Terms or any
                        revisions thereto, such User’s use of and access to the Site shall be considered automatically
                        terminated pursuant to paragraph 12.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>2. Scope and Description of the Services</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        The Platform includes a decentralized application linked to a digital art marketplace that
                        operates on the Ethereum blockchain (the <b>"Blockchain"</b>), through which the claim and/or
                        purchase of Avatar NFTs and/or Trait NFTs was conducted. The Avatar NFTs were initially sold
                        through the Platform over the course of multiple releases (each, a <b>“Drop”</b>); Trait NFTs
                        were subsequently released in several additional Drops. The Drops were and will continue to be
                        conducted at the Project Team’s and/or Promoters’ discretion.
                    </p>
                </li>
                <li>
                    <p>
                        Each Avatar displayed on the Platform is created and converted by Hackatao into an image file
                        corresponding to (and referenced in the URI contained within) a single NFT. NFTs are tracked and
                        stored without time limitation on the Blockchain, intending to provide the User with a permanent
                        record of authenticity and ownership.
                    </p>
                </li>
                <li>
                    <p>
                        The Project Team only manages issuance of NFTs for sale on the Platform, i.e. sales in the
                        primary market (as set forth in paragraph 5.c.-d. of these Terms). For the avoidance of doubt,
                        the Project Team does not manage, nor is it responsible for, any sales made in the secondary
                        market.
                    </p>
                </li>
                <li>
                    <p>
                        The Platform relies on third party service providers. The Site may contain links to third-party
                        websites (<b>"Third-Party Service Providers"</b>) or may make use of additional services
                        provided by Third-Party Service Providers, including without limitation digital wallet
                        providers. In each case, the use of such products, services, applications or websites will be
                        governed by and subject to the terms and conditions and privacy policies of the relevant
                        Third-Party Service Provider in addition to paragraph 11 of these Terms.{" "}
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>3. Privacy</h2>
            <p>
                The protection of personal information is extremely important to the Project Team. Each User
                acknowledges having reviewed the Privacy Policy available on{" "}
                <a href="https://queenskings.hackatao.com/privacy-policy">
                    https://queenskings.hackatao.com/privacy-policy
                </a>{" "}
                and hereby expressly agrees to the terms thereof with respect to use and processing of Users’ personal
                information.
            </p>
        </section>
        <section>
            <h2>4. User License to the Services</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Under these Terms, the Project Team grants each User a revocable, limited, free, non-exclusive,
                        non-transferable and non-sublicensable license (the <b>“Services License”</b>) to access and use
                        the Services through the computer or other internet-compatible devices for personal use.
                    </p>
                </li>
                <li>
                    <p>
                        Users may not use the Services for resale or for any commercial purpose not permitted under the
                        terms of the Services License. Resale of the Services is expressly prohibited and constitutes a
                        serious violation of these Terms.
                    </p>
                </li>
                <li>
                    <p>
                        This paragraph 4 is intended to grant only a limited license to access and use the Platform
                        and/or Services. Each User hereby expressly acknowledges and agrees that User’s use of and/or
                        access to the Platform and/or Services does not result in any ownership or intellectual property
                        rights therein being transferred to User.
                    </p>
                </li>
                <li>
                    <p>
                        Text, graphics, user interfaces, visual interface, photos, sounds, process flow diagrams,
                        computer code (including html code), programs, software, products, information and the
                        documents, as well as the design, structure, selection, expression, appearance and layout of any
                        content included in the Services or provided through the Services are the exclusive property of
                        Artists and/or Developers.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>5. General Conditions of Sale</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        <b>Prices and Transaction Fees:</b> Transactions on the Platform are not subject to commissions.
                        Users conducting a transaction are required to pay only the minting price identified for any NFT
                        at the time of a Drop, if applicable, in addition to any transactional costs (e.g., gas fees).
                    </p>
                </li>
                <li>
                    <p>
                        <b>Eligibility to Use the Site and/or Services:</b>
                    </p>
                    <ol className={styles.lowerRomanList}>
                        <li>
                            <p>
                                In order to use the Services offered by the Platform, the User represents and warrants
                                that User has reached the age of majority or older (18 in most states of the United
                                States, but different in some jurisdictions) and has full legal capacity. While
                                individuals under the age of 18 (but not younger than 13) may use the Site and/or
                                Services, they must do so only with the consent and involvement of a parent or legal
                                guardian, and otherwise subject to these Terms.
                            </p>
                        </li>
                        <li>
                            <p>
                                To participate in the minting or purchase of, or to custody a purchased NFT minted or
                                purchased through the Platform, the User must necessarily download a third-party browser
                                extension for the creation of a supported digital wallet and connect it to the Platform.
                                The User will not be able to carry out any transactions on the Platform except through
                                MetaMask or other in-wallet browsers compatible with Ethereum.
                            </p>
                        </li>
                        <li>
                            <p>
                                The Project Team reserves the right to identify participants in each Drop and award such
                                participants redemption rights with respect to one or more NFTs to be minted in a Drop
                                (such participants thereby becoming <b>“Listed Participants”</b>) by means of raffles or
                                other eligibility criteria established at the Project Team’s sole discretion, including
                                without limitation as set forth in the Artists’ Discord channels (i.e. the Discord
                                channels established for communications about the Project and/or about the Mork token).
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <b>Method of Sale:</b>
                    </p>
                    <ol className={styles.lowerRomanList}>
                        <li>
                            <p>
                                Subject to availability (including pre-Drop identification of Listed Participants as
                                contemplated by paragraph 5.b.iii., above), Users can purchase NFTs through the Site as
                                established at the time of each Drop, i.e. through connection of a digital wallet to the
                                Site to mint an NFT directly through the Site.
                            </p>
                        </li>
                        <li>
                            <p>
                                The Project Team may, alternatively or in subsequent phases of the Project, provide for
                                the purchase of NFTs using an online auction mechanism, as hosted by the Platform and/or
                                one or more Third-Party Service Providers.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <b>Objects of Primary Sale</b>
                    </p>
                    <ol className={styles.lowerRomanList}>
                        <li>
                            <p>
                                A total of 6900 Avatar NFTs were originally released by Hackatao, distributed as
                                follows:
                            </p>
                            <ul className={styles.squareList}>
                                <li>
                                    <p>300 to Sotheby's collectors via the Sotheby’s metaverse platform;</p>
                                </li>
                                <li>
                                    <p>135 Avatars to the Project Team, as follows:</p>
                                    <ul className={styles.discList}>
                                        <li>
                                            <p>70 Avatars for Artists;</p>
                                        </li>
                                        <li>
                                            <p>30 Avatars for Developers;</p>
                                        </li>
                                        <li>
                                            <p> 5 Avatars for Promoters; and</p>
                                        </li>
                                        <li>
                                            <p>30 Avatars for other promotional and exhibition purposes</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                The Artists have subsequently created additional Avatar Traits, including without
                                limitation facial features, accessories, and, clothing. Trait NFTs corresponding to
                                these additional Avatar Traits are periodically available to be minted directly through
                                the Site, and/or may be offered for sale or awarded free of charge to particular
                                categories of Collectors who have taken part in certain initiatives organized by
                                Hackatao, as indicated at the time of each Drop.
                            </p>
                        </li>
                        <li>
                            <p>
                                Avatar Traits may be categorized as unique, scarce or rare, depending on the number of
                                examples released. Consequently, algorithmically-minted Avatars may contain, and
                                Collectors may assemble Avatars using, identical examples of Traits from the same
                                edition.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <b>Secondary Sales:</b>Subsequent exchanges of Avatar NFTs and Trait NFTs may take place on
                        OpenSea and/or through other on third-party marketplaces, exchanges, sale platforms, or
                        applications (collectively, <b>“Marketplaces”</b>).
                    </p>
                </li>
                <li>
                    <p>
                        <b>Changes to the Project and/or Services:</b> The processes, Services, methods and objects of
                        sale described above may vary based on any changes to the Project at the sole discretion of the
                        Project Team. Artists expressly reserve the right to further develop the Project in their sole
                        creative discretion, including without limitation by expanding and/or modifying the NFTs offered
                        or otherwise made available.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>6. Alteration of Avatars (“Hacking”):</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Each Avatar was originally released as a composite of a maximum number of ten (10) Avatar Traits
                        which assigned by default and transferred to the initial Collector at the same time as such
                        Collector’s purchase of the Avatar NFT through a Drop. As generated by the Artists’ algorithm,
                        the composite Avatar Traits initially exist as part of a single Avatar NFT.
                    </p>
                </li>
                <li>
                    <p>
                        A Collector may change the appearance of a Collector-owned Avatar using acquired Avatar Traits
                        and/or additionally-acquired Avatars.
                    </p>
                </li>
                <li>
                    <p>
                        A Collector so alters the appearance of an Avatar by connecting their digital wallet to the Site
                        and using the Site’s ‘Hack the Royals’ feature, which allows a Collector to disassemble an
                        Avatar into its component Avatar Traits, thereby generating individual Trait NFTs corresponding
                        to those Avatar Traits (such process, <b>“Minting the Traits”</b>). There is no service fee or
                        commission charged by the Platform for Minting the Traits; the Collector undertaking this
                        process is responsible for paying only the gas fees required to complete the Blockchain
                        transaction (which fees may vary depending on network traffic and other factors outside the
                        Project Team’s control).
                    </p>
                </li>
                <li>
                    <p>
                        Minting the Traits is required prior to Hacking any original, algorithmically-generated Avatar
                        (i.e., an Avatar in the same state as it was acquired during the Drop through which it was
                        offered), but is not required to be repeated for the same Avatar, nor for any Avatar acquired in
                        a Marketplace and for which a prior owner has already Minted the Traits.
                    </p>
                </li>
                <li>
                    <p>
                        Once Minting the Traits has been completed for an Avatar (whether such transaction is completed
                        by the current owner or a prior owner thereof), subject only to paragraph 7 of these Terms, the
                        Collector who owns the Avatar may remove such Avatar Traits from the Avatar’s composition, and
                        may also replace them with other Avatar Traits in the Collector’s digital wallet.
                    </p>
                </li>
                <li>
                    <p>
                        Following the Collector’s selection of such a new configuration of Avatar Traits using the
                        Site’s ‘Hack the Royals’ feature, the Collector may ‘re-mint’ the altered Avatar (thereby
                        creating a new Avatar NFT for which the contract ‘bundles’ the Avatar NFT together with the
                        selected Trait NFTs, and points to an updated IPFS address that hosts a refreshed image of the
                        selected combination of Avatar Traits). This is the process referred to as <b>“Hacking,”</b> and
                        a re-minted Avatar may also be referred to as a <b>“Hacked Avatar.”</b>
                    </p>
                </li>
                <li>
                    <p>
                        The re-minted Avatar NFT will replace the prior version of the Avatar NFT for the Hacked Avatar,
                        and the individual Trait NFTs corresponding to the component Avatar Traits will ‘follow’ the
                        Avatar NFT upon sale or transfer of the Avatar NFT, unless the Collector first detaches such
                        Avatar Traits from the Avatar using the Site’s ‘Hack the Royals’ feature (as required in order
                        to separately transact such Trait NFTs). For the avoidance of doubt, a Hacked Avatar is
                        transacted in Marketplaces as a ‘bundled’ contract, comprising an Avatar NFT and the individual
                        Trait NFTs corresponding to the then-attached Avatar Traits.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>7. Non-Commercial License in an Avatar</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Artists grant to each Collector a non-exclusive license (the <b>“Non-Commercial Use License”</b>
                        ) in each Avatar then owned by such Collector, solely for the following purposes (the
                        <b>“Non-CommercialUses”</b>): (i) displaying or causing the Avatar to be displayed on any
                        Collector-controlled social media account (including without limitation as Collector’s PFP);
                        (ii) promoting, discussing, or commenting on the Avatar, Collector’s purchase and/or ownership
                        thereof, and/or the Project, including without limitation for news, critical, archival,
                        parodical and/or satirical purposes (e.g., visual ‘memes’); (iii) displaying the Avatar in
                        Marketplaces in association with an offer to sell, or trade, the corresponding Avatar NFT,
                        provided that in each case the Marketplace cryptographically verifies Collector as the actual
                        owner of the Avatar NFT being so offered for sale.
                    </p>
                </li>
                <li>
                    <p>
                        The Non-Commercial Use License is sub-licensable by the Collector solely if and as expressly
                        required to fulfill the Non-Commercial Uses, and automatically transfers to the new owner of the
                        Avatar NFT upon the Collector’s sale thereof.
                    </p>
                </li>
                <li>
                    <p>
                        Notwithstanding the foregoing, Artists reserve the right to revoke the Non-Commercial Use
                        License with respect to any use of an Avatar that is deemed by Artists in their sole discretion
                        to be abusive, offensive, illegal, or otherwise in violation of paragraph 12.d.iv. of these
                        Terms.
                    </p>
                </li>
                <li>
                    <p>
                        Where practicable, displays of the Artwork or any portion thereof made pursuant to the
                        Non-Commercial Use License shall ‘tag’ Artists (i.e., embed in a social media post Artists’
                        handle or username on such social media platform) or otherwise credit or reference Artists, in
                        order to promote further connections between Project Team and Collectors.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>8. Avatar “Freezing” Process</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        As discussed in paragraph 6 of these Terms, the Platform allows each Collectors to Hack an
                        Avatar they own by purchasing additional Avatars and/or Avatar Traits and reassembling a
                        different combination of Avatar Traits before re-minting on a contract that bundles the Avatar
                        NFT together with the selected Trait NFTs.
                    </p>
                </li>
                <li>
                    <p>
                        <b>"Freezing"</b> refers to the process by which, at the discretion of the Collector, the Avatar
                        Traits comprising an Avatar become immutable, such that the Trait NFTs can no longer be
                        un-bundled from the Avatar NFT or individually exchanged, and the composition of the Avatar
                        itself can no longer be altered (the resulting Avatar, the <b>"Frozen Avatar,"</b> and the
                        corresponding NFT, the <b>"Frozen Avatar NFT"</b>).
                    </p>
                </li>
                <li>
                    <p>
                        There is no service fee or commission charged by the Platform for Freezing an Avatar; the
                        Collector undertaking this process is responsible for paying only the gas fees required to
                        complete the Blockchain transaction (which fees may vary depending on network traffic and other
                        factors outside the Project Team’s control).
                    </p>
                </li>
                <li>
                    <p>
                        An Avatar composition with a minimum of six (6) Avatar Traits – face, body, background, eyes,
                        mouth, and crown – is required for Freezing. (Note that no minimum number of Traits is required
                        to be attached to an Avatar other than for Freezing; i.e., a Collector may create and engage in
                        Non-Commercial Uses of a Hacked Avatar with less than six (6) Avatar Traits.)
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>9. Commercial Use License in a Frozen Avatar</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        The Artists grant each Collector who owns a Frozen Avatar NFT the worldwide rights to reproduce,
                        display, distribute, and make commercial use of the corresponding Frozen Avatar (such license,
                        as more fully set forth below, the <b>"Commercial Use License"</b>).
                    </p>
                </li>
                <li>
                    <p>
                        The Commercial Use License permits the then-owner of a Frozen Avatar NFT to make innovative,
                        original and creative artistic interpretations of the Frozen Avatar, including in other artistic
                        styles or by incorporating the Frozen Avatar into new creative works (each, a{" "}
                        <b>“Derivative Work”</b>). Examples of Derivative Works include, but are not limited to,
                        reinterpreting a Frozen Avatar in manga, cartoon, pixel art, pointillist/Ben-Day dot, or
                        hyper-realist style, creating a three-dimensional rendering of a Frozen Avatar (including for
                        use in a virtual world), creating a physical sculptural work depicting a Frozen Avatar,
                        incorporating a Frozen Avatar into a comic book, and creating an animated short ‘starring’ the
                        Frozen Avatar. The then-owner of a Frozen Avatar may also mint NFTs of the Derivative Works (but
                        may not re-mint on any blockchain nor offer or sell through any Marketplace, any NFTs
                        corresponding to an image that is identical to the original Frozen Avatar).
                    </p>
                </li>
                <li>
                    <p>
                        In addition, the then-owner of a Frozen Avatar may manufacture, distribute, and sell commercial
                        merchandise featuring reproductions of the Frozen Avatar, including without limitation
                        toys/novelty items and apparel (each, an item of <b>“Commercial Merchandise,”</b> and such use,
                        together with the creation and use of Derivative Works, collectively, the{" "}
                        <b>“Commercial Uses”</b>).
                    </p>
                </li>
                <li>
                    <p>
                        The Commercial Use License extends only to the specific combination of Avatar Traits comprising
                        to the Frozen Avatar, and does not permit the owner of a Frozen Avatar to undertake Commercial
                        Uses (i) in which the Collector has altered the fundamental appearance of such Avatar Traits
                        (e.g., by changing the color of the Frozen Avatar’s hair or eyes, which might result in the
                        Frozen Avatar taking on the appearance of another Collector’s Avatar), nor (ii) reproducing
                        individual Avatar Traits as isolated or extracted from the Frozen Avatar (e.g. producing an item
                        of apparel using only the pattern from the Frozen Avatar’s background Avatar Trait, or creating
                        a new animated character using just the eye or mouth Avatar Trait from the Frozen Avatar). The
                        foregoing limitations on the Commercial Use License are imposed in order to balance the
                        Commercial Use License with the rights of other Collectors of Frozen Avatars using overlapping
                        Avatar Traits.
                    </p>
                </li>
                <li>
                    <p>
                        Without limiting the foregoing, the Commercial Use License does not extend to nor permit
                        Commercial Uses that are abusive, offensive, infringe on third-party rights, or are otherwise in
                        violation of paragraph 12.d.iv. of these Terms, as determined in the sole discretion of the
                        Artists.
                    </p>
                </li>
                <li>
                    <p>
                        The Commercial Use License is sublicensable by the Collector only if and as expressly required
                        to undertake the Commercial Uses, and is automatically transferred to the new owner of the
                        Frozen Avatar NFT upon the sale of the Frozen Avatar NFT by the Collector.
                    </p>
                </li>
                <li>
                    <p>
                        In the event that two or more Collectors seek to Freeze an Avatar with an identical combination
                        of Avatar Traits, the process will still be permitted, but only the first Collector who
                        undertakes the Freezing Process in relation to such Avatar will receive rights in the resulting
                        Frozen Avatar pursuant to the Commercial Use License.
                    </p>
                </li>
                <li>
                    <p>
                        Accordingly, if at the time a Collector attempts the Freezing Process for an Avatar and
                        identical combination of Avatar Traits has already been Frozen, the Collector will receive a
                        message on the Site indicating that continuing with the Freezing process will not result in a
                        Commercial Use License being granted. The Collector may decline to proceed at such time, and at
                        their sole discretion, may alter the combination of Avatar Traits comprising (i.e., Hack or
                        re-Hack) the Avatar to be Frozen in order to create a unique Frozen Avatar to which the
                        Commercial Use License will apply.
                    </p>
                </li>
                <li>
                    <p>
                        If the Collector instead elects to proceed with Freezing the duplicate Avatar, the
                        previously-Frozen Avatar will be marked with a symbol resembling a hollow snowflake with a solid
                        crown inside it (the “Original Frozen Symbol”), and each subsequent duplicate will be marked
                        with a symbol resembling a hollow snowflake without a solid crown inside (the “Duplicate Frozen
                        Symbol”). For the avoidance of doubt, Frozen Avatars bearing the Duplicate Frozen Symbol are not
                        subject to the Commercial Use License. By using the Site to undertake the Freezing process, each
                        Collector hereby acknowledges and agrees that the Project Team may subsequently alter their
                        Frozen Avatar by applying the the Original Frozen Symbol or Duplicate Frozen Symbol, as
                        applicable.
                    </p>
                </li>
                <li>
                    <p>
                        Without limiting its other intellectual property rights, the Project Team expressly reserves the
                        right to display, exhibit, and perform any Frozen Avatar in any media now known or hereafter
                        invented (and to reproduce, transmit, and distribute the Frozen Avatar incident to same) for
                        general Project promotional purposes (including without limitation display on the Site, on the
                        Artists’ social media accounts, and inclusion in digital and physical exhibitions and trade
                        shows), provided that in each such case, the Frozen Avatar will be credited as “owned by” the
                        Collector who is, at the time such use is initiated, the owner thereof.
                    </p>
                </li>
                <li>
                    <p>
                        Under no circumstances will the Project Team be liable for any Commercial Use undertaken by a
                        Collector. By accepting the Commercial Use License, a Collector assumes full responsibility for
                        all such Commercial Uses, and to the fullest extent permissible under applicable law, agrees to
                        defend and indemnify the Project Team against any and all actions, claims, costs, demands,
                        damages, and liabilities arising in relation to or in connection with such Commercial Uses.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>10. Automated Transactions through Blockchain Smart Contracts</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Each User acknowledges that the respective rights of the Artists and/or Developers (including
                        resale royalty rights, as applicable) and the rights and obligations of the Collector transfer
                        with the NFT and the time of any transfer of ownership of such NFT. Such rights may be processed
                        or initiated directly through one or more smart contracts on the Ethereum blockchain network.
                    </p>
                </li>
                <li>
                    <p>
                        Collectors offering Project-originating NFTs for secondary sale must do so through an on-chain
                        Marketplace, and are obligated to condition any secondary purchaser’s acquisition of such NFT(s)
                        on acceptance of and adherence to these Terms.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>11. Representations; Additional Disclaimers; User Release</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Artists represent and warrant that each algorithmically-generated Avatar originally offered as
                        part of a Drop was offered as an original, authentic and unique digital artwork by Artists,
                        i.e., that no two such Avatars comprised an identical combination of Avatar Traits.
                        Notwithstanding the foregoing, it is possible that Avatars with combinations of Avatar Traits
                        resulting in an overall similar appearance were offered, and further, as a result of the Hacking
                        process described in paragraph 6 of these Terms, Collectors may subsequently modify Avatars
                        using acquired Avatars Traits and, in doing so, create, one or more Avatars identical to Avatars
                        issued in a Drop and/or circulating in the secondary market.
                    </p>
                </li>
                <li>
                    <p>The Project Team expressly disclaims any representation or warranty as to:</p>
                    <ol className={styles.lowerRomanList}>
                        <li>
                            <p>
                                the connection or interaction between any NFT and the related Avatar or Trait(s)
                                (together, the <b>"Assets"</b>), including without limitation the stability or duration
                                of any link in the NFT referencing such corresponding assets or the continued display of
                                the assets at such location;
                            </p>
                        </li>
                        <li>
                            <p>
                                the relationship to, interaction between, compatibility with or functionality of any NFT
                                or related Asset, on the one hand, and any Marketplace, computer system or other
                                environment, game, contest, series, or similar, on the other;
                            </p>
                        </li>
                        <li>
                            <p>
                                the status or state of any NFT, underlying smart contract, or any related Asset or any
                                transfer mechanism for any NFT as free of viruses or other harmful components, or the
                                functionality of the foregoing as a buyer expects or without fault;
                            </p>
                        </li>
                        <li>
                            <p>
                                whether any NFT or Smart Contract is reliable, compatible with any particular computer
                                system(s) or platforms, error-free, compatible with a User's digital wallet or otherwise
                                meets the User's requirements, or that any defects in any NFT or its smart contract can
                                or will be corrected; or
                            </p>
                        </li>
                        <li>
                            <p>
                                the accuracy or reliability of any depiction of the intended performance of any NFT, any
                                smart contract, or any related Asset.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        Without limiting the foregoing, each User expressly accepts all risks associated with
                        purchasing, using, transferring, and owning NFTs generally, including without limitation,
                        temporary inaccessibility due to network, server, or blockchain protocol change, failures,
                        malfunctions, or disruptions; risk of losing access due to lost or forgotten private key(s) or
                        password(s) or corrupted wallet files; mis-typed addresses or incorrectly constructed
                        transactions; viruses, phishing, hacking, security breaches or attacks; and regulatory
                        implications (including without limitation as pertain to securities, cryptocurrencies, and/or
                        capital gains or other taxation issues).
                    </p>
                </li>
                <li>
                    <p>
                        In addition, User acknowledges that the Platform is not affiliated with, and the Project Team
                        does not own or control, MetaMask, Coinbase, Google Chrome, the Ethereum network or any other
                        third party site, product or service that the User may access, visit or use for the purpose of
                        enabling such User to use the Platform and/or Services. The Project Team is not liable for the
                        acts or omissions of such third-parties, and exempt from liability for any damage suffered by
                        the User for any interaction with such third-parties.
                    </p>
                </li>
                <li>
                    <p>
                        In using the Site and/or the Services, each User expressly agrees to release, indemnify, defend,
                        and hold harmless the Project Team and each of their officers, directors, employees, agents,
                        affiliates, successors, and assigns from and against any and all claims, losses, costs, damages
                        and expenses of whatever kind, including reasonable attorneys’ fees, arising from or relating to
                        such User’s use or misuse of the Site and/or Services, including without limitation such User’s
                        violation of these Terms or of any rights of any third-party, such User’s violation of
                        applicable laws, including sanctions regulations, and/or any claims by or against such User of
                        infringement of intellectual property or other proprietary rights. Each user who is a California
                        resident expressly waives California Civil Code Section 1542, which says: “A general release
                        does not extend to claims that the creditor or releasing party does not know or suspect to exist
                        in his or her favor at the time of executing the release and that, if known by him or her, would
                        have materially affected his or her settlement with the debtor or released party.” Each User who
                        is a resident of another jurisdiction with a comparable statute or doctrine expressly waives the
                        same.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>12. Termination, Suspension and Other Measures</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        <b>Termination by the User:</b> The User can discontinue use of the Platform and/or Services at
                        any time, without forfeiting or being precluded from accessing the NFTs purchased by such User
                        up to that time, provided, however, that any such NFTs remain subject to the intellectual
                        property rights set forth in these Terms (including without limitation the Non-Commercial Use
                        License, if and as applicable).
                    </p>
                </li>
                <li>
                    <p>
                        <b>Termination or Suspension of Services by the Project Team:</b> The Project Team reserves the
                        right, at its sole discretion, for any or no reason, at any time, and without notice, to
                        terminate a User's right to access or use the Platform as set forth in paragraph 1.c. of these
                        Terms, including without limitation in the event of the User’s violation of this paragraph 12.
                        The Project Team has the right to suspend the Services if it has sufficient reasons to believe
                        that a User is not acting in accordance with these Terms. For the avoidance of doubt, this means
                        that the Project Team reserves the right to suspend a User’s access to the site, including
                        without limitation by restricting connection of the User’s digital wallet with the Site.
                    </p>
                </li>
                <li>
                    <p>
                        <b>No Liability:</b> User acknowledges and accepts that the Project Team will have no liability
                        or obligation towards the User in the case of termination or suspension pursuant to this
                        paragraph 12 of these Terms, and will not be entitled to a refund of any amount that User has
                        already paid in connection with the Site or Services, including for any NFT.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Additional Consequences and Remedies:</b> The Project Team reserves the right to take
                        additional measures not enumerated in these Terms to protect the Site and other Users from
                        illegal, fraudulent or otherwise inappropriate behavior, including without limitation the
                        following:
                    </p>
                    <ol className={styles.lowerRomanList}>
                        <li>
                            <p>
                                reproduction, distribution, copying, sale or resale, trade, or exploitation any portion
                                of the Site or content obtained through the Site, for any purpose that is inconsistent
                                with these Terms;
                            </p>
                        </li>
                        <li>
                            <p>
                                creation of derivative works of and/or modification, adaptation, or translation of the
                                Site or any part or contents thereof except and only to the extent that such activity is
                                expressly permitted by applicable law notwithstanding this limitation or under these
                                Terms, or with the prior written authorization of the Project Team and any applicable
                                licensors;
                            </p>
                        </li>
                        <li>
                            <p>
                                defamation, harassment, abuse, threats, stalking or defrauding Users of the Site, or
                                collecting, or attempting to collect, personal information about Users or third-parties
                                without their consent (or using the Site to engage in such conduct);
                            </p>
                        </li>
                        <li>
                            <p>
                                posting, transmitting, reproducing, or disseminating any content through the Site (or
                                elsewhere, including any other use that would otherwise be permitted pursuant to
                                paragraphs 6.a. and/or 8.a. of these Terms) that the Project Team determines at its sole
                                discretion: (i) is unlawful, harmful, harassing, fraudulent, threatening, abusive,
                                libelous, defamatory, vulgar, obscene, hateful, or racially, ethnically or otherwise
                                objectionable, including without limitation as a result of association with or use for
                                any political figure, candidate or candidacy, campaign, or movement or any religious
                                institution or similar, or infringes any third-party’s intellectual property, moral, or
                                other rights, (ii) is derogatory or harmful to the Artists’ reputation (or otherwise in
                                violation of the Artists’ moral rights), the reputation of the Site's licensors, or any
                                of the Project Team's or their respective officers, members, employees, representatives,
                                licensors and/or suppliers, in any way; (iii) may incite violence or other unlawful
                                activity; or (iv) is harmful to children in any manner;
                            </p>
                        </li>
                        <li>
                            <p>
                                intentionally interfering with or damaging, impairing or disabling, interrupting,
                                impairing, or overburdening the operation of the Site or any User’s enjoyment of it by
                                any means, including but not limited to uploading or otherwise disseminating viruses,
                                worms, spyware, adware, or other malicious code, or placing a disproportionate load on
                                the Site with the intended result of denying service to other Users, sending mass
                                unsolicited messages or ‘flooding’ servers;
                            </p>
                        </li>
                        <li>
                            <p>
                                removing, circumventing, disabling, damaging or otherwise interfering with any
                                security-related features of the Site, features that prevent or restrict the use or
                                copying of any part of the Site, or features that enforce limitations on the use of the
                                Site;
                            </p>
                        </li>
                        <li>
                            <p>
                                attempting to gain unauthorized access to the Site or any part of it, including gaining
                                access or attempting to gain access to another User’s account, computer systems or
                                networks connected to the Site or any part of it, through request, hacking, password
                                mining, use of any robot, spider, scraper, or any other means;
                            </p>
                        </li>
                        <li>
                            <p>
                                reverse engineering, decompiling, disassembling or otherwise attempting to discover the
                                source code of the Site or any part thereof, except and only to the extent that such
                                activity is expressly permitted by applicable law notwithstanding this limitation;
                            </p>
                        </li>
                        <li>
                            <p>
                                making unsolicited offers, advertisements, proposals, or sending junk mail or spam to
                                other Users of the Site or inserting User’s own or a third-party’s advertising, branding
                                or other promotional content on the Site; or
                            </p>
                        </li>
                        <li>
                            <p>
                                impersonating any other person or entity, or falsely stating or otherwise
                                misrepresenting your affiliation with a person or entity.
                            </p>
                        </li>
                    </ol>
                </li>
            </ol>
        </section>
        <section>
            <h2>13. Applicable Law and Dispute Resolution</h2>
            <p>
                These Terms, access to and use of the Site, and Users’ participation in any purchase through the Site of
                any NFT offered thereon, will be governed, construed and enforced in accordance with the laws of the
                State of New York. Any dispute arising in connection with any of the foregoing or otherwise between the
                parties that is not subject to arbitration pursuant to paragraph 14 or that cannot be brought before a
                small claims court will be resolved in the state or federal courts of the State of California and the
                United States.
            </p>
        </section>
        <section>
            <h2>14. Arbitration</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Any dispute, claim, action, or case relating to, these Terms or otherwise in connection with the
                        Platform (or any NFT offered thereon) and/or the Services, will be resolved by administered
                        arbitration of the American Arbitration Association in accordance with its International
                        Arbitration Rules.
                    </p>
                </li>
                <li>
                    <p>The case will be adjudicated by a single arbitrator.</p>
                </li>
                <li>
                    <p>Each party will bear its own fees and costs associated with the arbitration proceedings.</p>
                </li>
                <li>
                    <p>The location of the arbitration proceedings will be New York, NY.</p>
                </li>
                <li>
                    <p>The languages of the arbitration will be English and Italian.</p>
                </li>
                <li>
                    <p>
                        The arbitration award will be final and binding. Any judgment on the award made by the
                        arbitrator may be presented in any court of competent jurisdiction.
                    </p>
                </li>
                <li>
                    <p>
                        IN CONNECTION WITH ANY DISPUTE ARISING OUT OF OR RELATING TO THESE TERMS, INCLUDING WITHOUT
                        LIMITATION DISPUTES RELATING TO THE SITE OR ANY PRODUCT SOLD OR DISTRIBUTED THROUGH THE SITE, OR
                        ANY SMART CONTRACT GOVERNING ANY NFT, USER EXPRESSLY WAIVES USER’S RIGHT TO A JURY TRIAL AND TO
                        PARTICIPATE IN COLLECTIVE ACTION OR CLASS ARBITRATION.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>15. Entire Agreement; No Waiver; Severability</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        These Terms contain the entire understanding as to the relationship between the parties with
                        reference to the subject matter hereof, it being understood that it replaces any previous
                        understanding(s) and/or agreement(s) between the parties. The Project Team reserves the right to
                        transfer and/or assign the rights and obligations deriving from these Terms to third parties.{" "}
                    </p>
                </li>
                <li>
                    <p>
                        Any tolerance by the Project Team of a User's behavior in violation of the provisions contained
                        in these Terms does not constitute a waiver of the rights deriving from the violated provisions
                        nor the right to demand the exact fulfillment of all these Terms.
                    </p>
                </li>
                <li>
                    <p>
                        In the event that any provision of the Terms ais or becomes unenforceable under any applicable
                        law, or is determined to be null or inapplicable by any court or arbitrator of competent
                        jurisdiction, and/or if one or more provisions cannot, for reasons other than the
                        non-fulfillment of the obliged subject, be fulfilled in full compliance with the provisions
                        agreed here, the remaining provisions will remain in full force and effect.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <h2>16. Contact and Assistance</h2>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Users may seek support in relation to the Platform and/or Services through customer service by
                        sending an e-mail to the address:{" "}
                        <a href="mailto:queenskings@hackatao.com">queenskings@hackatao.com</a>.
                    </p>
                </li>
                <li>
                    <p>
                        Without prejudice to the above, any question or comment regarding the Site and/or these Terms
                        and, in general, any communication to the Project Team must be sent to the e-mail address:{" "}
                        <a href="mailto:queenskings@hackatao.com">queenskings@hackatao.com</a>.
                    </p>
                </li>
                <li>
                    <p>
                        The Project Team expressly reserves the right to make any further communication or send
                        notifications and messages to Users through the Site.
                    </p>
                </li>
            </ol>
        </section>
        <section>
            <p>
                ALL RIGHTS RESERVED. EXCEPT AS OTHERWISE EXPRESSLY PERMITTED BY THESE TERMS, ANY ARTWORK DISPLAYED ON
                THE SITE MAY NOT BE REPRODUCED OR USED IN ANY MANNER WHATSOEVER WITHOUT THE ADVANCE WRITTEN PERMISSION
                OF HACKATAO OR THEIR RESPECTIVE OWNERS.
            </p>
        </section>
    </div>
);

export default Terms;
