import { ChangeEvent, ReactElement } from "react";
import styles from "./SimulatorFilters.module.scss";

export enum ERarityValues {
    RARITY_PLUS = 1,
    RARITY_MINUS = -1,
}

export enum ETypeValues {
    EXTRA = "extra",
    CROWN = "crown",
    HAIR = "hair",
    EYES = "eyes",
    MOUTH = "mouth",
    BEARD = "beard",
    FACE = "face",
    DRESS = "dress",
    BODY = "body",
    BACKGROUND = "background",
}

interface ISimulatorFilters {
    typeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    rarityChange: (e: ChangeEvent<HTMLInputElement>) => void;
    isFetchingData: boolean;
}

const SimulatorFilters = ({ typeChange, rarityChange, isFetchingData }: ISimulatorFilters): ReactElement => (
    <div className={styles.container}>
        <div className={styles.traitBox} role="radiogroup" onChange={typeChange}>
            <div className={styles.item}>
                <input
                    tabIndex={0}
                    type="radio"
                    name="type"
                    value={ETypeValues.EXTRA}
                    disabled={isFetchingData}
                    defaultChecked
                />{" "}
                POWERS
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.CROWN} disabled={isFetchingData} />{" "}
                CROWN
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.HAIR} disabled={isFetchingData} /> HAIR
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.EYES} disabled={isFetchingData} /> EYES
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.MOUTH} disabled={isFetchingData} />{" "}
                MOUTH
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.BEARD} disabled={isFetchingData} />{" "}
                BEARD
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.FACE} disabled={isFetchingData} /> FACE
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.DRESS} disabled={isFetchingData} />{" "}
                DRESS
            </div>
            <div className={styles.item}>
                <input tabIndex={-1} type="radio" name="type" value={ETypeValues.BODY} disabled={isFetchingData} /> BODY
            </div>
            <div className={styles.item} data-testid="background-check-filter">
                <input
                    tabIndex={-1}
                    type="radio"
                    name="type"
                    value={ETypeValues.BACKGROUND}
                    disabled={isFetchingData}
                />
                BACKGROUND
            </div>
        </div>

        <div className={styles.rarityBox} role="radiogroup" onChange={rarityChange}>
            <div className={styles.item}>
                <input
                    type="radio"
                    name="rarity"
                    value={ERarityValues.RARITY_PLUS}
                    disabled={isFetchingData}
                    defaultChecked
                />{" "}
                + RARE
            </div>
            <div className={styles.item}>
                <input type="radio" name="rarity" value={ERarityValues.RARITY_MINUS} disabled={isFetchingData} /> - RARE
            </div>
        </div>
    </div>
);

export default SimulatorFilters;
