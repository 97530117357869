import { DISCORD, MEDIUM } from "@constants/links";
import { TERMS_OF_USE } from "@constants/routes";
import { Link } from "react-router-dom";
import styles from "./LearnMore.module.scss";

const LearnMore = (): JSX.Element => {
    return (
        <div className={styles.container}>
            <h1>
                <span>HACKING SEASON 7:</span> IN YOUR HANDS
            </h1>
            <p className={styles.capitalizedParagraph}>January 24th - December 31st 2024</p>
            <p className={styles.boldParagraph}>
                WHAT GIVES BIRTH TO CREATIVITY? IT SPRINGS FROM HARD WORK, DARING EXPLORATION AND A TOUCH OF LUCK.
                CREATIVITY IS WHAT FUELS HUMANITY’S EVOLUTIONARY LEAPS.
            </p>
            <p className={styles.boldParagraph}>
                WITHIN EVERY INDIVIDUAL LIES A DORMANT PROWESS, A LATENT ABILITY WAITING TO BE REFINED, AN UNTAPPED
                POTENTIAL CONNECTING TO THE VAST DEPTHS OF THE SUBCONSCIOUS. SEASON 7 DELVES INTO PRECISELY THAT —
                UNFURLING THE PATHWAYS OF YOUR MIND TO WITNESS THE DESTINATIONS THEY UNVEIL.
            </p>
            <p className={styles.boldParagraph}>
                WE BELIEVE THAT THE REALM OF Q+K HAS THE POWER TO INSPIRE CREATIVITY: THAT THE ROYALS SERVE AS CONDUITS
                FOR IDEAS THAT CAN EXTRACT THE ZENITH OF YOUR CREATIVE ABILITIES.
            </p>
            <p className={styles.boldParagraph}>
                CREATIVITY IS NOT CONFINED TO ARTISTS ALONE; IT IS FOR THOSE ASPIRING TO ELEVATE THE BEAUTY ALREADY
                PERVASIVE IN THE WORLD. WE SUMMON ALL TO UNLEASH THE CREATIVITY WITHIN, LEVERAGING YOUR QUEENS+KINGS AS
                COMPANIONS ON THIS TRANSFORMATIVE JOURNEY.
            </p>

            <p className={styles.dottedLine}>- - - - - - - - - - - - - - -</p>

            <h2>CONTEST</h2>

            <p>
                THE FIRST CONTEST OF THE SEASON WAS CENTRED AROUND STREET ART - COLLECTORS WERE INVITED TO CREATE: BY
                WAY OF ANY MEDIUM, USING THEIR ROYALS AS A MUSE: AN ODE TO STREET ART, WITH THE WINNER RECEIVING A PRINT
                OF THEIR Q+K.
            </p>

            <p>
                TO MAINTAIN THE CREATIVE SPARK IN THE COMMUNITY, THE NEXT CONTEST HAS BEEN DUBBED HACK THE PIXEL: WHERE
                COLLECTORS HAVE UNTIL THE END OF SUMMER 2024 TO HACK QUEENS+KINGS AND SUBMIT THEM. HACKATAO WILL SELECT
                A SMALL NUMBER OF ROYALS THAT THEY DEEM TO BE THE BEST AND MOST CREATIVE - THOSE ROYALS WILL GO TO A
                VOTE - THE EYES AND HANDS OF THE SOVEREIGN COMMUNITY WILL DECIDE WHICH Q+K IS VICTORIOUS, WITH THE
                WINNER’S PRIZE BEING A BEAUTIFUL PIXEL PODMORK OF THEIR Q+K.
            </p>

            <h2>HACKING & CHANGES TO TRAIT CLAIMS:</h2>

            <ul className={styles.bulletList}>
                <li className={styles.noBullet}>
                    <p>HACKING SEASON 7: JANUARY 24TH, 2024 - DECEMBER 31ST, 2024 </p>
                </li>
                <li className={styles.noBullet}>
                    <p>OCTOBER: TRAITS CLAIM FOR ALL THE HACKERS OF SEASON 7</p>
                </li>
            </ul>

            <p>
                COLLECTORS ARE ABLE TO PURCHASE TRAITS FOR A FIXED PRICE; THIS IS TO COVER TECH COSTS AND ALLOW THE
                PROJECT TO GROW AND EVOLVE! EVERYONE WILL HAVE THE OPPORTUNITY TO ACQUIRE NEW TRAITS FOR THEIR ROYALS AT
                THE END OF THIS SEASON.
            </p>

            <p>
                COLLECTORS WHO HAVE HACKED THEIR ROYALS (AS THAT TERM IS DEFINED IN SECTION 6 OF THE TERMS OF USE) ARE
                ELIGIBLE TO PURCHASE NEW TRAITS FROM THE SITE. THE TOP HACKERS WILL BENEFIT FROM EARLY ACCESS TO THE NEW
                TRAITS AS WELL AS RARER TRAITS RELEASED FOR SALE.
            </p>

            <h2>BURNING</h2>
            <p>
                THE BURNING MECHANISM HAS BEEN INTRODUCED TO INCREASE RARITY WITHIN THE PROJECT BY PRIORITISING SCARCITY
                AS IS NECESSARY TO KEEP THE PROJECT GROWING AND EVOLVING. THIS IS AN AMAZING TOOL FOR THE COMMUNITY, AS
                WE AIM TO KEEP THIS COMMUNITY MORE ENGAGED FOR LONGER!
            </p>
            <p>
                THIS NEW FUNCTIONALITY OPERATES IN ‘BURNING SEASONS’ THAT LAST FOR 6 MONTHS (THIS IS TO ENSURE THAT THE
                START AND END OF THE HACKING AND BURNING SEASONS DO NOT COINCIDE).
            </p>
            <ul className={styles.bulletList}>
                <li className={styles.noBullet}>
                    <p>BURNING SEASON 1: SEPT. 2023 - APRIL 2024</p>
                    <li className={styles.noBullet}>
                        <p>JULY: LIMITED EDITION REWARD DROP</p>
                    </li>
                    <p>BURNING SEASON 2: MAY 2024 - DECEMBER 2024</p>
                </li>
            </ul>
            <p>
                ALL TRAITS, BASES AND SOME OTHER HACKATAO ARTWORKS PREVIOUSLY MINTED IN LARGE EDITIONS CAN BE BURNED
                EVERY SEASON, BUT THERE WILL BE LIMITATIONS IN ORDER TO PROTECT THE RARITY OF CERTAIN TRAITS.
            </p>
            <p>
                AS A SPECIAL REWARD FOR BURNING AT CERTAIN LEVELS TO BE SPECIFIED EACH BURNING SEASON, HACKATAO WILL
                RELEASE A NEW ARTWORK IN A LIMITED EDITION (E.G. AN EDITION SIZE OF 23).
            </p>

            <p>TO ALLOCATE THOSE EDITIONS, THERE WILL BE A DOUBLE CRITERIA:</p>
            <ul className={styles.bulletList}>
                <li>
                    <p>
                        THE MAJORITY WILL BE AUTOMATICALLY GIVEN TO THE TOP BURNERS WHO MANAGE TO COLLECT AT LEAST A
                        MINIMUM CERTAIN AMOUNT OF POINTS, RECEIVING THE EDITION AT THE END OF THE BURNING SEASON AND
                        SEEING THEIR BURNING POINTS RESET TO 0.
                    </p>
                </li>
                <li>
                    <p>
                        THE REMAINING FEW WILL BE GIVEN OUT IN A RAFFLE, WHERE COLLECTORS USE THEIR BUILT-UP BURNING
                        POINTS TO ENTER THE RAFFLE. THE MORE POINTS ACCUMULATED, THE MORE YOUR CHANCES OF WINNING THE
                        RAFFLE.
                    </p>
                </li>
            </ul>

            <p>
                ALL COLLECTORS WHO WON A REWARD (AUTOMATICALLY OR THROUGH THE RAFFLE) WILL SEE THEIR POINTS RESET TO 0,
                AND ALL OTHERS WILL KEEP THEIR POINTS FOR THE NEXT SEASON.
            </p>

            <h2>FREEZING:</h2>

            <p>
                DROPPING TEMPERATURES FREEZE AVATARS THAT ARE WILLING TO EXPLORE MORE INVENTIVE TERRITORY. FROZEN ROYALS
                WILL CONTINUE TO SAIL BEYOND KNOWN TERRITORY AND THEY MAY ALSO EXPERIMENT CREATIVELY ALONGSIDE HACKATAO.
                THE OWNERS OF FROZEN ROYALS WILL OBTAIN COMMERCIAL RIGHTS OVER THEIR SOVEREIGN CREATIONS, ALLOWING THEM
                TO USE THEIR AVATARS IN OTHER REALMS AND TO EXPLORE THE POSSIBILITY OF GENERATING REVENUE STREAMS FROM
                THOSE VENTURES.
            </p>

            <p>
                LEARN MORE ABOUT THE FREEZING TOOL <Link to={TERMS_OF_USE}>HERE</Link> AND ON{" "}
                <a href={MEDIUM} rel="noopener noreferrer" target="_blank">
                    MEDIUM
                </a>
                .
            </p>

            <a href={DISCORD} rel="noopener noreferrer" target="_blank">
                DISCOVER MORE
            </a>
        </div>
    );
};

export default LearnMore;
