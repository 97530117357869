import { ReactElement } from "react";
import { ETHERSCAN_URL } from "@constants/env";
import DefaultLoader from "@components/DefaultLoader";
import styles from "./ClaimTraitInProgress.module.scss";

interface IClaimTraitInProgress {
    txId: string;
    loadingText: string;
}

const ClaimTraitInProgress = ({ txId, loadingText }: IClaimTraitInProgress): ReactElement => (
    <section className={styles.container}>
        <p className={styles.loadingText}>{loadingText}</p>
        <p>
            CHECK YOUR{" "}
            <a href={`${ETHERSCAN_URL}/tx/${txId}`} rel="noopener noreferrer" target="_blank">
                TRANSACTION
            </a>
        </p>

        <DefaultLoader classname={styles.loader} spinner />
    </section>
);

export default ClaimTraitInProgress;
