import { ChangeEvent, ReactElement } from "react";
import { TTrait } from "@customTypes/trait";
import { EActiveTab } from "../SimulatorCollection/SimulatorCollection";
import SimulatorFilters from "../SimulatorFilters";
import SimulatorTraitGrid from "../SimulatorTraitGrid";
import SimulatorTraitOwnerAvatarPreview from "../SimulatorTraitOwnerAvatarPreview";
import styles from "./SimulatorTab.module.scss";

interface ISimulatorTab {
    activeTab: EActiveTab;
    isFetchingData: boolean;
    isLoadingTraits: boolean;
    isLoadingMintedTraits: boolean;
    hasMoreMintedTraits: boolean;
    selectedOpenseaTrait: TTrait | null;
    sortedMintedTraits: TTrait[];
    sortedTraits: TTrait[];
    handleNext: () => void;
    handleTypeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleRarityChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SimulatorTab = ({
    activeTab,
    isFetchingData,
    isLoadingTraits,
    isLoadingMintedTraits,
    hasMoreMintedTraits,
    sortedMintedTraits,
    sortedTraits,
    handleNext,
    handleTypeChange,
    handleRarityChange,
}: ISimulatorTab): ReactElement => (
    <div
        className={`${styles.container} ${
            activeTab === EActiveTab.OPENSEA ? styles.containerBrown : styles.containerGray
        }`}
    >
        <div className={styles.filterBox}>
            <SimulatorFilters
                typeChange={handleTypeChange}
                rarityChange={handleRarityChange}
                isFetchingData={isFetchingData}
            />

            {activeTab === EActiveTab.MY_COLLECTION && <SimulatorTraitOwnerAvatarPreview />}
        </div>

        <div id="scrollableDiv" className={styles.infiniteContainer}>
            <SimulatorTraitGrid
                traits={activeTab === EActiveTab.OPENSEA ? sortedMintedTraits : sortedTraits}
                activeTab={activeTab}
                isLoadingTraits={isLoadingTraits}
                isLoadingMintedTraits={isLoadingMintedTraits}
                handleNextFetch={handleNext}
                hasMoreTraits={hasMoreMintedTraits}
            />
        </div>
    </div>
);

export default SimulatorTab;
