import ItemsCarousel from "react-items-carousel";
import { useState, useEffect } from "react";
import { TAvatar } from "@customTypes/avatar";
import { generatePath, Link } from "react-router-dom";
import { AVATAR_PROFILE } from "@constants/routes";
import loader from "@images/loader.png";
import styles from "./AvatarLinkCarousel.module.scss";

interface IAvatarsCarousel {
    avatarsList: TAvatar[];
    handleActiveSlide?: (id: number) => void;
    showLeft?: boolean;
    showRight?: boolean;
    numberOfCards?: number;
    gutter: number;
    enablePlaceholder?: boolean;
    numberOfPlaceHolder?: number;
    getAvatars: (offset: number) => void;
    moreAvatars: boolean;
    isLoadingAvatars: boolean;
}

const AvatarsLinkCarousel = ({
    avatarsList,
    showLeft,
    showRight,
    handleActiveSlide,
    getAvatars,
    isLoadingAvatars,
    moreAvatars,
    numberOfCards = 1,
    gutter = 5,
}: IAvatarsCarousel): JSX.Element => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(1);
    const [offset, setOffset] = useState(0);

    const chevronWidth = 40;

    useEffect(() => {
        handleActiveSlide && handleActiveSlide(activeIndex);
    }, [activeIndex]);

    const handleActiveItemIndex = (id: number) => {
        setActiveItemIndex(id);
    };

    const handleImageClicked = (index: number) => {
        setActiveIndex(index);
    };

    return (
        <div className={styles.container} style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={handleActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCards}
                rightChevron={showRight ? <button className={styles.nextBtn} aria-label="Go next" /> : null}
                chevronWidth={chevronWidth}
                leftChevron={showLeft ? <button className={styles.prevBtn} aria-label="Go prev" /> : null}
                gutter={gutter}
                outsideChevron
                onActiveStateChange={({ isLastScroll }: { isLastScroll: boolean; isFirstScroll: boolean }) => {
                    if (isLastScroll && moreAvatars && !isLoadingAvatars) {
                        getAvatars(offset + numberOfCards + 1);
                        setOffset(offset + numberOfCards + 1);
                    }
                }}
            >
                {avatarsList.map(({ id, image, name, originalTokenId }, index: number) => (
                    <div
                        key={id}
                        role="button"
                        tabIndex={0}
                        className={activeIndex === index ? styles.imageActiveContainer : styles.imageContainer}
                        onClick={() => handleImageClicked(index)}
                        onKeyDown={() => handleImageClicked(index)}
                    >
                        <Link
                            to={generatePath(AVATAR_PROFILE, { id: originalTokenId })}
                            data-testid="avatar-image-link"
                        >
                            <img draggable="false" src={image} alt={name} />
                        </Link>
                    </div>
                ))}

                {isLoadingAvatars && (
                    <div className={styles.avatarLoaderContainer}>
                        <img src={loader} className={styles.avatarLoader} alt="Loading spinner" />
                    </div>
                )}
            </ItemsCarousel>
        </div>
    );
};

export default AvatarsLinkCarousel;
