import FooterNavbar from "./components/FooterNavbar";
import { HACKATAO, NFTSTUDIOS, DISCORD, INSTAGRAM, TWITTER, EMAIL } from "@constants/links";
import logo from "@images/footer-logo.png";
import nft_studios from "@images/nft-studios-logo.png";
import hackatao from "@images/hackatao-logo.png";
import xlogo from "@images/x_logo.png";
import mail from "@images/mail-icon.png";
import discord from "@images/discord-icon.png";
import instagram from "@images/instragram-icon.png";
import styles from "./Footer.module.scss";

const Footer = (): JSX.Element => {
    return (
        <footer className={styles.container}>
            <div>
                <div>
                    <div>
                        <a href={HACKATAO} rel="noopener noreferrer" target="_blank" data-testid="hackatao-link">
                            <img src={hackatao} alt="hackatao logo" />
                        </a>
                        <a href={NFTSTUDIOS} rel="noopener noreferrer" target="_blank" data-testid="nft-studios-link">
                            <img src={nft_studios} alt="nft studios logo" />
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <img src={logo} alt="footer logo" />
                <div>
                    <FooterNavbar />
                    <div>
                        <a href={DISCORD} target="_blank" rel="noopener noreferrer" data-testid="discord-link">
                            <img src={discord} alt="Hackatao discord community" />
                        </a>
                        <a href={INSTAGRAM} target="_blank" rel="noopener noreferrer" data-testid="instagram-link">
                            <img src={instagram} alt="Hackatao instagram profile" />
                        </a>
                        <a
                            className={styles.xlogo}
                            href={TWITTER}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-testid="twitter-link"
                        >
                            <img src={xlogo} alt="Hackatao x profile" />
                        </a>
                        <a href={EMAIL} data-testid="mailto-link">
                            <img src={mail} alt="Link to mail" />
                        </a>
                        <a href={EMAIL}>
                            <span className={styles.email}>QUEENSKINGS@HACKATAO.COM</span>
                        </a>
                    </div>
                </div>
                <p>
                    <span>ALL RIGHTS RESERVED. </span> ANY ARTWORK DISPLAYED ON THIS WEBSITE MAY NOT BE REPRODUCED OR
                    USED IN ANY MANNER WHATSOVER WITHOUT THE EXPRESS WRITTEN PERMISSION OF HACKATAO OR THEIR RESPECTIVE
                    OWNERS.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
