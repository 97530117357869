import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useSimulatorContext } from "@context/simulatorContext";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { TTrait } from "@customTypes/trait";
import { ERarityValues, ETypeValues } from "../SimulatorFilters";
import { EActiveTab } from "../SimulatorCollection";
import SimulatorTab from "../SimulatorTab";
import useSimulator from "@pages/Simulator/useSimulator";

interface ISimulatorTabContainer {
    activeTab: EActiveTab;
}

const DEFAULT_LIMIT = 30;

const SimulatorTabContainer = ({ activeTab }: ISimulatorTabContainer): ReactElement => {
    const [rarityOrder, setRarityOrder] = useState(ERarityValues.RARITY_PLUS);
    const [typeFilter, setTypeFilter] = useState(ETypeValues.EXTRA);
    const [isFetchingData, setIsFetchigData] = useState(false);
    const [sortedTraits, setSortedTraits] = useState<TTrait[]>([]);
    const [sortedMintedTraits, setSortedMintedTraits] = useState<TTrait[]>([]);
    const [hasMoreMintedTraits, setHasMoreMintedTraits] = useState(true);

    const { walletAddress } = useWeb3Context();
    const { selectedOpenseaTrait, updateSelectedOpenseaTrait } = useSimulatorContext();
    const { isLoadingTraits, isLoadingMintedTraits, traits, getTraits, getMintedTraits, mintedNextCursor } =
        useSimulator();

    useEffect(() => {
        getTraits();
    }, []);

    useEffect(() => {
        setSortedTraits([]);
        setSortedMintedTraits([]);

        if (activeTab === EActiveTab.OPENSEA) {
            handleGetMintedTraits();
            return;
        }

        return () => {
            setSortedMintedTraits([]);
            setSortedTraits([]);
        };
    }, [activeTab, walletAddress, rarityOrder, typeFilter]);

    useEffect(() => {
        if (activeTab === EActiveTab.OPENSEA) {
            !isLoadingMintedTraits && setIsFetchigData(false);
            return;
        }

        setIsFetchigData(false);
    }, [isLoadingMintedTraits, sortedTraits]);

    useEffect(() => {
        if (rarityOrder === 1) {
            setSortedTraits(traits.filter(({ type }) => type === typeFilter).sort((a, b) => a.rarity - b.rarity));
            return;
        }

        setSortedTraits(
            traits
                .filter(({ type }) => type === typeFilter)
                .sort((a, b) => a.rarity - b.rarity)
                .reverse()
        );
    }, [activeTab, typeFilter, rarityOrder, traits]);

    const handleGetMintedTraits = async () => {
        setIsFetchigData(true);
        const t = await getMintedTraits(typeFilter, rarityOrder, 0);
        setSortedMintedTraits(t);
        updateSelectedOpenseaTrait(t[0]);
        setHasMoreMintedTraits(t.length !== 0 && t.length === DEFAULT_LIMIT);
    };

    const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTypeFilter(e.target.value as ETypeValues);
        setIsFetchigData(true);
    };

    const handleRarityChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRarityOrder(parseInt(e.target.value));
        setIsFetchigData(true);
    };

    const handleNext = async () => {
        if (activeTab !== EActiveTab.OPENSEA) {
            return;
        }

        setIsFetchigData(true);
        const t = await getMintedTraits(typeFilter, rarityOrder, mintedNextCursor);
        const filtered = sortedMintedTraits.filter((f) => f.type === typeFilter);
        setSortedMintedTraits([...filtered, ...t]);
        setHasMoreMintedTraits(t.length !== 0 && t.length === DEFAULT_LIMIT);
    };

    return (
        <SimulatorTab
            activeTab={activeTab}
            isFetchingData={isFetchingData}
            isLoadingTraits={isLoadingTraits}
            isLoadingMintedTraits={isLoadingMintedTraits}
            hasMoreMintedTraits={hasMoreMintedTraits}
            selectedOpenseaTrait={selectedOpenseaTrait}
            sortedTraits={sortedTraits}
            sortedMintedTraits={sortedMintedTraits}
            handleNext={handleNext}
            handleTypeChange={handleTypeChange}
            handleRarityChange={handleRarityChange}
        />
    );
};

export default SimulatorTabContainer;
