import { format } from "date-fns";
import { IBurnedAssetApiResponse, IHistoryBurnedAsset } from "@customTypes/royalList/historyBurnedAsset";

const assetApiResponseToAsset = ({
    createdAt,
    image,
    ownerAddress,
    name,
    type,
    tokenId,
}: IBurnedAssetApiResponse): IHistoryBurnedAsset => {
    return {
        date: format(new Date(createdAt), "yyyy-MM-dd"),
        image,
        owner: ownerAddress,
        name: name,
        tokenId,
        type,
    };
};

export default assetApiResponseToAsset;
