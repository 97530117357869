import { TTrait } from "@customTypes/trait";
import ClaimedTrait from "../ClaimedTrait";
import BottomBanner from "@components/BottomBanner";
import DefaultLoader from "@components/DefaultLoader";
import ConfirmationCheck from "@images/confirmation-check.png";
import styles from "./ClaimTraitSuccess.module.scss";

interface IClaimTraitSuccess {
    isLoadingClaimedTraits: boolean;
    claimedTraits: TTrait[];
}

const ClaimTraitSucess = ({ claimedTraits, isLoadingClaimedTraits }: IClaimTraitSuccess): JSX.Element => (
    <section className={styles.container}>
        <p className={styles.mintedTraitText}>
            <span>CONGRATZ!</span> NOW YOUR ROYALS CAN SCARE THE DEAD!
        </p>

        <img className={styles.check} src={ConfirmationCheck} alt="confirmation check" />
        <p>SOME TRAITS MAY TAKE A FEW MINUTES TO SHOW UP</p>

        {isLoadingClaimedTraits ? (
            <DefaultLoader classname={styles.loader} spinner />
        ) : (
            <div className={styles.claimedTraitContainer}>
                {claimedTraits.length > 0 ? (
                    <ClaimedTrait traitList={claimedTraits} />
                ) : (
                    <p>There are no minted traits</p>
                )}
            </div>
        )}

        <BottomBanner className={styles.onlyDesktop} />
    </section>
);

export default ClaimTraitSucess;
