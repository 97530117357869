import { ReactElement } from "react";
import { EActiveTab } from "./components/RankingTabs/RankingTabs";
import { EPreviewType } from "./components/RankingPreview/RankingPreview";
import withMaxWidth from "@hocs/withMaxWidth";
import useRanking from "./useRanking";
import RankingTabs from "./components/RankingTabs";
import RankingAvatarsList from "./components/RankingAvatarsList";
import RankingTable from "./components/RankingTable";
import RankingTraitsList from "./components/RankingTraitsList";
import RankingPreview from "./components/RankingPreview";
import BottomBanner from "@components/BottomBanner";
import DefaultLoader from "@components/DefaultLoader";
import styles from "./Ranking.module.scss";

const TOTAL_AVATARS = 6900;

const Ranking = (): ReactElement => {
    const {
        activeTab,
        traitSearch,
        avatarSearch,
        traits,
        avatars,
        selectedTrait,
        selectedAvatar,
        isPageLoading,
        params,
        traitPage,
        avatarPage,
        totalTraits,
        isFetchingTraits,
        isFetchingAvatars,
        handleTabChange,
        handleAvatarSearchChange,
        handleAvatarSubmit,
        handleResetAvatarSearch,
        handleSelectedAvatarRow,
        handleTraitSearchChange,
        handleTraitTypeChange,
        handleResetTraitSearch,
        handleTraitSubmit,
        handleSelectedTraitRow,
        handleItemsLimit,
        handleNextPage,
        handlePrevPage,
    } = useRanking();

    const getTotalAvatar = () => (avatarSearch ? avatars.length : TOTAL_AVATARS);

    if (isPageLoading) {
        return (
            <div className={styles.container}>
                <DefaultLoader spinner />
            </div>
        );
    }

    const { traitType, limit } = params;

    return (
        <div className={styles.container}>
            <p className={styles.title}>
                <span>SUBVERSIVE HIERARCHY</span>. WE DON'T LIKE RANKINGS AND HIERARCHIES.
            </p>
            <p className={styles.title}>YET, WE CAN'T EVADE THIS PRIMAL HUMAN NEED TO RANK. </p>
            <p className={styles.title}>FORTUNATELY, THANKS TO HACKING, HIERARCHY CAN CHANGE.</p>
            <p className={styles.titleBrown}>
                <span>HERE'S THEIR EVOLUTION</span>.
            </p>
            <p className={styles.title}>
                VOTE FOR YOUR Q+K IN AESTHETIC RANKING:
                <a href="https://queenskings.likey.wtf/" rel="noopener noreferrer" target="_blank">
                    {" "}
                    HTTPS://QUEENSKINGS.LIKEY.WTF/
                </a>
            </p>
            <p className={styles.changes}>
                *RANKINGS MAY CHANGE AS NEW TRAITS ARE BEING RELEASED AND CLAIMED BY COLLECTORS.
            </p>

            <div className={styles.content}>
                <RankingTabs
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    avatarSearch={avatarSearch}
                    traitSearch={traitSearch}
                    handleAvatarSearchChange={handleAvatarSearchChange}
                    handleTraitSearchChange={handleTraitSearchChange}
                    handleSubmitAvatarSearch={handleAvatarSubmit}
                    handleSubmitTraitSearch={handleTraitSubmit}
                    handleResetAvatarSearch={handleResetAvatarSearch}
                    handleResetTraitSearch={handleResetTraitSearch}
                />
                <RankingTable
                    activeTab={activeTab}
                    traitType={traitType}
                    actualLimit={limit}
                    actualPage={activeTab === EActiveTab.AVATARS ? avatarPage : traitPage}
                    totalItems={activeTab === EActiveTab.AVATARS ? getTotalAvatar() : totalTraits}
                    handleTypeChange={handleTraitTypeChange}
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    handleItemsLimitChange={handleItemsLimit}
                >
                    {activeTab === EActiveTab.AVATARS ? (
                        <RankingAvatarsList
                            items={avatars}
                            selectedRow={selectedAvatar?.id ?? 0}
                            isLoading={isFetchingAvatars}
                            handleSelectedRow={handleSelectedAvatarRow}
                        />
                    ) : (
                        <RankingTraitsList
                            items={traits}
                            selectedRow={selectedTrait?.rarityString ?? ""}
                            isLoading={isFetchingTraits}
                            handleSelectedRow={handleSelectedTraitRow}
                        />
                    )}
                </RankingTable>
                <RankingPreview
                    type={activeTab === EActiveTab.TRAITS ? EPreviewType.TRAIT : EPreviewType.AVATAR}
                    object={activeTab === EActiveTab.TRAITS ? selectedTrait : selectedAvatar}
                />
            </div>
            <BottomBanner />
        </div>
    );
};

export default withMaxWidth(Ranking);
