import { useState, useEffect } from "react";
import ItemsCarousel from "react-items-carousel";
import useWindowSize from "@hooks/useWindowSize";
import { TTrait } from "@customTypes/trait";
import styles from "./TraitCarousel.module.scss";

interface ITraitsCarousel {
    btnLeftClassName?: string;
    btnRightClassName?: string;
    traitList: TTrait[];
    showLeft?: boolean;
    showRight?: boolean;
    numberOfCards?: number;
    gutter: number;
    handleActiveSlide?: (id: number) => void;
    handleActiveTraitIndex?: (id: number) => void;
}

const TraitCarousel = ({
    btnLeftClassName,
    btnRightClassName,
    traitList,
    showLeft,
    showRight,
    numberOfCards = 1,
    gutter = 5,
    handleActiveSlide,
    handleActiveTraitIndex,
}: ITraitsCarousel): JSX.Element => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const { width } = useWindowSize();

    const chevronWidth = width && width > 375 ? 40 : 20;

    useEffect(() => {
        handleActiveSlide && handleActiveSlide(traitList[activeIndex].id);
        handleActiveTraitIndex && handleActiveTraitIndex(activeIndex);
    }, [activeIndex, activeItemIndex]);

    const handleActiveItemIndex = (itemIndex: number) => {
        if (itemIndex > activeItemIndex) {
            setActiveIndex(activeIndex === traitList.length - 1 ? 0 : activeIndex + 1);
        } else {
            setActiveIndex(activeIndex === 0 ? traitList.length - 1 : activeIndex - 1);
        }
        setActiveItemIndex(itemIndex);
    };

    return (
        <div className={styles.container} style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={handleActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCards}
                rightChevron={
                    showRight ? (
                        <button
                            className={`${styles.nextBtn} ${btnRightClassName}`}
                            aria-label="Next trait"
                            data-testid="minted-trait-next-button"
                        />
                    ) : null
                }
                chevronWidth={chevronWidth}
                leftChevron={
                    showLeft ? (
                        <button
                            className={`${styles.prevBtn} ${btnLeftClassName}`}
                            aria-label="Prev trait"
                            data-testid="minted-trait-prev-button"
                        />
                    ) : null
                }
                gutter={gutter}
                outsideChevron
            >
                {traitList.map(({ id, image, name }) => (
                    <div key={id} className={styles.imageContainer}>
                        <p>
                            <span className={styles.name}>{name}</span> #{id}
                        </p>
                        <img src={image} alt={name} />
                    </div>
                ))}
            </ItemsCarousel>
        </div>
    );
};

export default TraitCarousel;
