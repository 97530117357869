import { ReactElement } from "react";
import { ETHERSCAN_URL } from "@constants/env";
import DefaultLoader from "@components/DefaultLoader";
import parentStyles from "../../FreezeTransactionMessages.module.scss";

interface IInProgress {
    isCopy: boolean;
    txId: string | null;
}

const InProgress = ({ isCopy, txId }: IInProgress): ReactElement => (
    <div className={parentStyles.freezeContainer}>
        <p className={parentStyles.gold}>FREEZING</p>
        <p className={parentStyles.paragraph}>
            FALL IN TEMPERATURE! WE ARE FREEZING YOUR Q+K {!isCopy ? "AND GRANTING YOU COMMERCIAL RIGHTS" : undefined}.
        </p>
        <p>BE PATIENT, SOVEREIGN, NEW TERRITORY TO EXPLORE AWAITS YOU. </p>

        <p className={parentStyles.lastParagraph}>
            CHECK YOUR{" "}
            <a href={`${ETHERSCAN_URL}/tx/${txId}`} rel="noopener noreferrer" target="_blank">
                TRANSACTION
            </a>
        </p>
        <DefaultLoader spinner />
    </div>
);

export default InProgress;
