import ReactTooltip from "react-tooltip";
import { generatePath, Link } from "react-router-dom";
import { TRAIT_PROFILE } from "@constants/routes";
import styles from "./Trait.module.scss";

interface ITrait {
    id: number;
    rarity: number;
    name?: string;
    type: string;
    image: string;
    available?: boolean;
    showStats: boolean;
    showTooltip?: boolean;
}

const Trait = ({ id, rarity, name, showStats, image, type, available, showTooltip }: ITrait): JSX.Element => (
    <div
        className={styles.container}
        data-tip={
            showTooltip
                ? `${name} #${id}
            <br /><br /> 
            ${rarity}% have this trait 
            ${available ? "<br /><br /> This trait is currently in a Q+K" : ""}`
                : null
        }
        data-testid="trait-tooltip"
    >
        <Link to={generatePath(TRAIT_PROFILE, { type: type, id: id })}>
            {available && <div className={styles.traitTaken}></div>}
            <img src={image} alt={`trait ${id}`} />
        </Link>
        {showStats && (
            <div className={styles.stats}>
                <p>#{id}</p>
                <p>{rarity}%</p>
            </div>
        )}
        <ReactTooltip data-multiline={true} html={true} />
    </div>
);

export default Trait;
