import { KeyboardEvent, MouseEvent, ReactElement } from "react";
import { EBurnStages, ETabType } from "@enums/burn";
import { IAsset } from "@customTypes/burn/asset";
import useSelectedAssets from "@hooks/burn/useSelectedAssets";
import useGetAssets from "@hooks/burn/useGetAssets";
import BottomBanner from "@components/BottomBanner";
import Preview from "./components/Preview";
import Title from "./components/Title";
import Table from "./components/Table";
import styles from "./Selection.module.scss";

interface ISelection {
    tab: ETabType;
    traitsToBurn: IAsset[];
    avatarsToBurn: IAsset[];
    heroinesToBurn: IAsset[];
    addToBurn: (asset: IAsset) => void;
    selectTab: (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>, tab: ETabType) => void;
    addTraitToBurn: (trait: IAsset) => void;
    addAvatarToBurn: (avatar: IAsset) => void;
    addHeroineToBurn: (heroine: IAsset) => void;
    handleStage: (stages: EBurnStages) => void;
}

const Selection = ({
    handleStage,
    tab,
    traitsToBurn,
    avatarsToBurn,
    heroinesToBurn,
    addToBurn,
    selectTab,
    addTraitToBurn,
    addAvatarToBurn,
    addHeroineToBurn,
}: ISelection): ReactElement => {
    const {
        avatarSearch,
        traitSearch,
        heroineSearch,
        traits,
        traitPage,
        avatars,
        avatarPage,
        heroines,
        heroinesPage,
        params,
        total,
        isFetchingTraits,
        isFetchingAvatars,
        isFetchingHeroines,
        handleAvatarSearchChange,
        handleTraitSearchChange,
        handleHeroineSearchChange,
        handleResetAvatarSearch,
        handleResetTraitSearch,
        handleResetHeroineSearch,
        handleAvatarSubmit,
        handleTraitSubmit,
        handleHeroineSubmit,
        handleTraitTypeChange,
        handleItemsLimit,
        handleNextPage,
        handlePrevPage,
    } = useGetAssets(tab);

    const { currentAssets, updateSelectedTrait, updateSelectedAvatar, updateSelectedHeroine } = useSelectedAssets({
        tab,
        traits,
        traitsToBurn,
        avatars,
        avatarsToBurn,
        heroines,
        heroinesToBurn,
        total,
        traitPage,
        avatarPage,
        heroinesPage,
        isFetchingTraits,
        isFetchingAvatars,
        isFetchingHeroines,
    });

    const goToConfirmation = () => {
        if (traitsToBurn.length || avatarsToBurn.length || heroinesToBurn.length) {
            handleStage(EBurnStages.CONFIRMATION);
        }
    };

    const selectedHandler = (id: string) => {
        if (tab === ETabType.AVATARS) {
            updateSelectedAvatar(filterAvatarById(id)!);
        } else if (tab === ETabType.TRAITS) {
            updateSelectedTrait(filterTraitById(id)!);
        } else {
            updateSelectedHeroine(filterHeroineById(id)!);
        }
    };

    const checkedHandler = (id: string) => {
        if (tab === ETabType.AVATARS) {
            addAvatarToBurn(filterAvatarById(id)!);
        } else if (tab === ETabType.TRAITS) {
            addTraitToBurn(filterTraitById(id)!);
        } else {
            addHeroineToBurn(filterHeroineById(id)!);
        }
    };

    const addSelectedAssetToBurn = () => {
        if (selectedItem) {
            addToBurn(selectedItem);
        }
    };

    const filterAvatarById = (id: string) => {
        return avatars.find((item) => item.id === id);
    };

    const filterTraitById = (id: string) => {
        return traits.find((item) => item.id === id);
    };

    const filterHeroineById = (id: string) => {
        return heroines.find((item) => item.id === id);
    };

    const { traitType, limit } = params;

    const { items, selectedItem, isLoading, notFoundMessage, checkedItems, page, totalItems } = currentAssets;

    return (
        <div className={styles.container}>
            <Title />
            <div className={styles.content}>
                <Table
                    tabs={
                        <Table.Tabs
                            tab={tab}
                            handleTabChange={selectTab}
                            traitSearch={traitSearch}
                            avatarSearch={avatarSearch}
                            heroineSearch={heroineSearch}
                            handleTraitSearchChange={handleTraitSearchChange}
                            handleSubmitTraitSearch={handleTraitSubmit}
                            handleResetTraitSearch={handleResetTraitSearch}
                            handleAvatarSearchChange={handleAvatarSearchChange}
                            handleSubmitAvatarSearch={handleAvatarSubmit}
                            handleResetAvatarSearch={handleResetAvatarSearch}
                            handleHeroineSearchChange={handleHeroineSearchChange}
                            handleResetHeroineSearch={handleResetHeroineSearch}
                            handleSubmitHeroineSearch={handleHeroineSubmit}
                        />
                    }
                    header={<Table.Head tab={tab} traitType={traitType} handleTypeChange={handleTraitTypeChange} />}
                    body={
                        <Table.Body
                            items={items}
                            handleCheckedRow={checkedHandler}
                            handleSelectedRow={selectedHandler}
                            isLoading={isLoading}
                            notFoundMessage={notFoundMessage}
                            selectedRow={selectedItem?.id ?? ""}
                            checkedItems={checkedItems}
                        />
                    }
                    foot={
                        <Table.Foot
                            actualLimit={limit}
                            actualPage={page}
                            totalItems={totalItems}
                            handleNextPage={handleNextPage}
                            handlePrevPage={handlePrevPage}
                            handleItemsLimitChange={handleItemsLimit}
                        />
                    }
                />
                <Preview
                    image={<Preview.Image src={selectedItem?.image} alt={selectedItem?.name} />}
                    caption={
                        <Preview.Caption
                            text={selectedItem?.name ?? ""}
                            handleClick={addSelectedAssetToBurn}
                            isLoading={isLoading}
                        />
                    }
                    actions={<Preview.Actions goToConfirmation={goToConfirmation} />}
                />
            </div>

            <BottomBanner className={styles.banner} />
        </div>
    );
};

export default Selection;
