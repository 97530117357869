import { Link } from "react-router-dom";
import { BURNING, CLAIM_TRAIT, LEARN_MORE } from "@constants/routes";
import burnNow from "@images/BURN_NOW.png";
import styles from "./TitleMain.module.scss";

const TitleMain = (): JSX.Element => (
    <div className={styles.countdown}>
        <div className={styles.title}>
            <p className={styles.firstLine}>HACK THE ROYALS</p>
            <p className={styles.secondLine}>AND BE YOUR OWN</p>
            <p className={styles.thirdLine}>CREATIVE SOVEREIGN</p>
        </div>
        <p className={styles.symbols}>🜃 🜁 🜄 🜂</p>
        <p className={styles.traistDropName}>
            HACKING SEASON 7: <br />
            IN YOUR HANDS
        </p>
        <Link to={LEARN_MORE} className={styles.subscribe}>
            LEARN MORE
        </Link>
        <Link to={CLAIM_TRAIT} className={`${styles.subscribe} ${styles.traitsButton}`}>
            NEW TRAITS
        </Link>
        <Link to={BURNING} className={styles.burnNow}>
            <img src={burnNow} alt="Burn now" width="100px" height="100px" />
        </Link>
    </div>
);

export default TitleMain;
