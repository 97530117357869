import { ROYAL_LIST } from "@constants/routes";
import { Link } from "react-router-dom";
import Button from "@elements/Button";
import styles from "./Actions.module.scss";
import { ReactElement } from "react";

interface IActions {
    goToConfirmation: () => void;
}

const Actions = ({ goToConfirmation }: IActions): ReactElement => (
    <div className={styles.buttons}>
        <Button type="secondary" onClick={goToConfirmation}>
            BURN
        </Button>
        <Link to={ROYAL_LIST}>ROYAL LIST</Link>
    </div>
);

export default Actions;
