import axios from "axios";
import { useEffect, useState } from "react";
import { IMAGES_API_BASE_URL } from "@constants/env";
import { BURNABLE_AVATARS } from "@constants/apiEndpoints";
import { IAsset } from "@customTypes/burn/asset";
import { avatarApiResponseToAsset } from "@utils/mappers/burn/avatarApiResponseToAsset";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { ETabType } from "@enums/burn";

interface IUseGetAvatars {
    data: IAsset[];
    currentPage: number;
    total: number;
    isLoading: boolean;
}

const useGetAvatars = (
    limit: number,
    page: number,
    id: string | undefined = undefined,
    tab: ETabType
): IUseGetAvatars => {
    const { walletAddress } = useWeb3Context();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<IAsset[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (tab !== ETabType.AVATARS || !walletAddress) {
            return;
        }

        const controller = new AbortController();
        const signal = controller.signal;

        setIsLoading(true);
        axios
            .get(`${BURNABLE_AVATARS}`.replace(":address", walletAddress), {
                baseURL: IMAGES_API_BASE_URL,
                params: {
                    page,
                    limit,
                    tokenId: id,
                },
                signal,
            })
            .then((result) => {
                if (result) {
                    setCurrentPage(result.data.page);
                    setTotal(result.data.total);
                    const parsedAvatars = result.data.avatars.map((avatar: any) => avatarApiResponseToAsset(avatar));
                    setData(parsedAvatars);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));

        return () => {
            controller.abort();
        };
    }, [walletAddress, limit, page, id, tab]);

    return {
        data,
        isLoading,
        total,
        currentPage,
    };
};

export default useGetAvatars;
