import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { TTrait } from "@customTypes/trait";
import { TAvatar } from "@customTypes/avatar";

interface ISimulatorContext {
    allTraitsAreMine: boolean;
    isPreviewLoading: boolean;
    selectedAvatar: TAvatar | null;
    avatarsList: TAvatar[];
    selectedTraitsList: TTrait[];
    traitsList: TTrait[];
    selectedTrait: TTrait | null;
    traitAvatarPreview: TTrait | null;
    selectedOpenseaTrait: TTrait | null;
    updateSelectedAvatar: (a: TAvatar) => void;
    updateAvatarsList: (aList: TAvatar[]) => void;
    updateSelectedTraitsList: (tList: TTrait[]) => void;
    updateTraitsList: (tList: TTrait[]) => void;
    updateSelectedTrait: (t: TTrait) => void;
    updateTraitAvatarPreview: (t: TTrait) => void;
    updateSelectedOpenseaTrait: (t: TTrait) => void;
    updateSelectedAvatarImage: (url: string) => void;
    updatePreviewLoading: () => void;
    updateAllTraitsAreMine: ({ type, value }: { type: string; value: boolean }) => void;
    updateAvatarsListWithNewAvatar: (avatar: TAvatar) => void;
    resetAllTraitsAreMine: () => void;
}

interface IChildrenProps {
    children: ReactElement;
}

export const SimulatorContext = createContext<ISimulatorContext | undefined>(undefined);

const validationInitialState = {
    extra: true,
    crown: true,
    hair: true,
    eyes: true,
    mouth: true,
    beard: true,
    face: true,
    dress: true,
    body: true,
    background: true,
};

const SimulatorContextProvider = ({ children }: IChildrenProps): ReactElement => {
    const [selectedAvatar, setSelectedAvatar] = useState<TAvatar | null>(null);
    const [avatarsList, setAvatarsList] = useState<TAvatar[]>([]);
    const [selectedTraitsList, setSelectedTraitsList] = useState<TTrait[]>([]);
    const [traitsList, setTraitsList] = useState<TTrait[]>([]);
    const [selectedOpenseaTrait, setSelectedOpenseaTrait] = useState<TTrait | null>(null);
    const [selectedTrait, setSelectedTrait] = useState<TTrait | null>(null);
    const [traitAvatarPreview, setTraitAvatarPreview] = useState<TTrait | null>(null);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [allTraitsAreMine, setAllTraitsAreMine] = useState(true);
    const [validation, setValidation] = useState(validationInitialState);

    useEffect(() => {
        let allMine = true;
        Object.keys(validation).map((key) => {
            if (!validation[key]) {
                allMine = false;
                return;
            }
        });
        setAllTraitsAreMine(allMine);
    }, [validation]);

    useEffect(() => {
        setValidation(validationInitialState);
        setSelectedTrait(null);
    }, [selectedAvatar?.id]);

    const updatePreviewLoading = () => {
        setIsPreviewLoading(false);
    };

    const updateSelectedAvatar = (a: TAvatar) => {
        setSelectedAvatar(a);
    };

    const updateAvatarsList = (aList: TAvatar[]) => {
        setAvatarsList(aList);
    };

    const updateAvatarsListWithNewAvatar = (avatar: TAvatar) => {
        const filteredAvatarsList = avatarsList.filter((a) => a.id !== avatar.id);
        const newAvatarsList = [avatar, ...filteredAvatarsList];
        setAvatarsList(newAvatarsList);
    };

    const updateSelectedTraitsList = (tList: TTrait[]) => {
        setSelectedTraitsList(tList);
    };

    const updateTraitsList = (tList: TTrait[]) => {
        setTraitsList(tList);
    };

    const updateSelectedOpenseaTrait = (t: TTrait) => {
        setSelectedOpenseaTrait(t);
    };

    const updateAllTraitsAreMine = ({ type, value }: { type: string; value: boolean }) => {
        setValidation({
            ...validation,
            [type]: value,
        });
    };

    const resetAllTraitsAreMine = () => {
        setValidation(validationInitialState);
    };

    const updateSelectedTrait = ({
        id,
        image,
        avatarId,
        name,
        type,
        rarity,
        style,
        rarityString,
        available,
        owner,
        minted,
    }: TTrait) => {
        const trait: TTrait = {
            id,
            image,
            name,
            rarity,
            style,
            type,
            avatarId,
            available,
            owner,
            rarityString,
            minted,
        };

        setSelectedTrait(trait);
    };

    const updateTraitAvatarPreview = ({
        id,
        image,
        avatarId,
        name,
        type,
        rarity,
        style,
        rarityString,
        available,
        owner,
        minted,
    }: TTrait) => {
        const trait: TTrait = {
            id,
            image,
            name,
            rarity,
            style,
            type,
            avatarId,
            available,
            owner,
            rarityString,
            minted,
        };

        setTraitAvatarPreview(trait);
    };

    const updateSelectedAvatarImage = (url: string) => {
        if (!selectedAvatar) {
            return;
        }

        setSelectedAvatar(
            (prevAvatar) =>
                ({
                    ...prevAvatar,
                    image: url,
                } as TAvatar)
        );

        const avatarInList = avatarsList.find((a) => a.id === selectedAvatar.id);

        if (avatarInList) {
            avatarInList.image !== url && setIsPreviewLoading(true);
            avatarInList.image = url;
        }
    };

    return (
        <SimulatorContext.Provider
            value={{
                allTraitsAreMine,
                isPreviewLoading,
                selectedAvatar,
                avatarsList,
                selectedTraitsList,
                traitsList,
                selectedTrait,
                traitAvatarPreview,
                selectedOpenseaTrait,
                updateSelectedAvatar,
                updateAvatarsList,
                updateSelectedTraitsList,
                updateTraitAvatarPreview,
                updateTraitsList,
                updateSelectedTrait,
                updateSelectedOpenseaTrait,
                updateSelectedAvatarImage,
                updatePreviewLoading,
                updateAllTraitsAreMine,
                updateAvatarsListWithNewAvatar,
                resetAllTraitsAreMine,
            }}
        >
            {children}
        </SimulatorContext.Provider>
    );
};

const useSimulatorContext = (): ISimulatorContext => {
    const context = useContext(SimulatorContext);

    if (context === undefined) {
        throw new Error("useSimulatorContext should be used within a SimulatorContextProvider");
    }

    return context;
};

export { SimulatorContextProvider, useSimulatorContext };
