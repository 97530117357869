import axios from "axios";
import { useEffect, useState } from "react";
import { IAsset } from "@customTypes/burn/asset";
import { IHeroineApiResponse } from "@customTypes/heroine";
import { BURNABLE_HEROINES } from "@constants/apiEndpoints";
import { IMAGES_API_BASE_URL } from "@constants/env";
import { heroinesApiResponseToAsset } from "@utils/mappers/burn/heroineApiResponseToAsset";
import { ETabType } from "@enums/burn";
import { useWeb3Context } from "@nftstudios/web3-provider";

interface IUseGetHeroines {
    data: IAsset[];
    currentPage: number;
    total: number;
    isLoading: boolean;
}

const useGetHeroines = (
    limit: number,
    page: number,
    id: string | undefined = undefined,
    tab: ETabType
): IUseGetHeroines => {
    const { walletAddress } = useWeb3Context();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<IAsset[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (tab !== ETabType.HEROINES || !walletAddress) {
            return;
        }

        const controller = new AbortController();
        const signal = controller.signal;

        setIsLoading(true);
        axios
            .get(`${BURNABLE_HEROINES}`.replace(":address", walletAddress), {
                baseURL: IMAGES_API_BASE_URL,
                params: {
                    page,
                    limit,
                    id,
                },
                signal,
            })
            .then((result) => {
                if (result) {
                    setCurrentPage(result.data.page);
                    setTotal(result.data.total);
                    const parsedHeroines = result.data.heroines.map((apiHeroine: IHeroineApiResponse) =>
                        heroinesApiResponseToAsset(apiHeroine)
                    );
                    setData(parsedHeroines);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));

        return () => {
            controller.abort();
        };
    }, [walletAddress, tab, limit, page, id]);

    return {
        data,
        currentPage,
        total,
        isLoading,
    };
};

export default useGetHeroines;
