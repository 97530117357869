import { ReactElement } from "react";
import { IAvatarRanking } from "@utils/scripts/AvatarUtils";
import DefaultLoader from "@components/DefaultLoader";
import RankingAvatarItem from "../RankingAvatarItem";
import styles from "./RankingAvatarsList.module.scss";

interface IRankingTraitsList {
    items: IAvatarRanking[];
    selectedRow: number;
    isLoading: boolean;
    handleSelectedRow: (id: number) => void;
}

const RankingAvatarsList = ({ items, selectedRow, isLoading, handleSelectedRow }: IRankingTraitsList): ReactElement => (
    <>
        {isLoading ? (
            <tr className={styles.fullRow}>
                <td>
                    <DefaultLoader classname={styles.loader} spinner />
                </td>
            </tr>
        ) : (
            <>
                {items.length > 0 ? (
                    <>
                        {items.map(({ id, name, preview, rank, score, frozen, royalId }: IAvatarRanking) => (
                            <RankingAvatarItem
                                key={`${id}_${preview}_${score}_${rank}_avatar`}
                                id={id}
                                royalId={royalId}
                                name={name}
                                preview={preview}
                                rank={rank}
                                score={score}
                                frozen={frozen}
                                selectedRow={selectedRow}
                                handleSelectedRow={() => handleSelectedRow(id)}
                            />
                        ))}
                    </>
                ) : (
                    <tr className={styles.fullRow}>
                        <td>
                            <p className={styles.notFound}>No avatar found</p>
                        </td>
                    </tr>
                )}
            </>
        )}
    </>
);

export default RankingAvatarsList;
