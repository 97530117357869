import { ReactElement } from "react";
import { IAvatarRanking } from "@utils/scripts/AvatarUtils";
import { ITraitRanking } from "@customTypes/trait";
import { FREEZE_CONTRACT_ADDR, QK_CONTRACT_ADDR } from "@constants/env";
import styles from "./RankingPreview.module.scss";

export enum EPreviewType {
    AVATAR = "AVATAR",
    TRAIT = "TRAIT",
}

interface IRankingPreview {
    type: EPreviewType;
    object: IAvatarRanking | ITraitRanking | undefined;
}

const RankingPreview = ({ type, object }: IRankingPreview): ReactElement => {
    const getLink = (type: string, name: string, rarityString: string, minted: boolean): string => {
        let link =
            "https://opensea.io/collection/queenskings?search[sortAscending]=true&search[sortBy]=PRICE" +
            `&search[stringTraits][0][name]=${type === "extra" ? "power" : type}` +
            `&search[stringTraits][0][values][0]=${name} - ${rarityString}`;

        if (minted) {
            link =
                "https://opensea.io/collection/queenskings-traits?search[sortAscending]=true&search[sortBy]=PRICE" +
                `&search[stringTraits][0][name]=Rarity&search[stringTraits][0][values][0]=${rarityString}`;
        }

        return link;
    };

    if (!object) {
        return <div className={styles.container} />;
    }

    if (type === EPreviewType.TRAIT) {
        const { name, preview, rank, rarity, type, minted, rarityString } = object as ITraitRanking;

        return (
            <div className={styles.container}>
                <div className={styles.imageBox}>
                    <img src={preview} alt="Trait Preview" />
                    <div className={styles.imgData}>
                        <span className={styles.nameId}>{name}</span>
                        <a
                            href={getLink(type, name, rarityString, minted)}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.viewOp}
                        >
                            VIEW IN OPENSEA
                        </a>
                    </div>
                    <p className={styles.rank}>RARITY RANK {rank}</p>
                    <p className={styles.percent}>{rarity}% HAVE THIS TRAIT</p>
                </div>
            </div>
        );
    }

    const { id, royalId, frozen, preview, rank, traits, name, owner } = object as IAvatarRanking;

    const getUrl = () => (frozen ? `${FREEZE_CONTRACT_ADDR}/${royalId}` : `${QK_CONTRACT_ADDR}/${id}`);

    return (
        <div className={styles.container}>
            <div className={styles.imageBox}>
                <img src={`${preview}?img-width=640`} alt="Avatar Preview" />
                <div className={styles.imgData}>
                    <p className={styles.nameId}>{name}</p>
                    <a
                        href={`https://opensea.io/assets/${getUrl()}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={styles.viewOp}
                    >
                        VIEW IN OPENSEA
                    </a>
                </div>
                <p className={styles.owner}>
                    <span>OWNER</span>
                    <a
                        href={`https://opensea.io/${owner}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Opensea owner profile"
                    >
                        {owner}
                    </a>
                </p>
                <p className={styles.rank}>
                    <span>RARITY RANK</span> {rank}
                </p>

                {traits &&
                    Object.keys(traits).map((key) => (
                        <div className={styles.traits} key={`${key}-${traits[key] ? traits[key].tokenid : ""}`}>
                            <p>
                                <span className={styles.type}>{key === "extra" ? "powers" : key}</span>
                                <span className={styles.name}>
                                    {traits[key] && traits[key].name ? `-${traits[key].name}` : "-"}
                                </span>
                                <span className={styles.traitPercent}>
                                    {traits[key] && traits[key].rarity > 0 ? `${traits[key].rarity}%` : "-"}
                                </span>
                            </p>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default RankingPreview;
