import { ReactElement, useEffect, useState } from "react";
import { sortTraits } from "@utils/scripts/AvatarUtils";
import { useSimulatorContext } from "@context/simulatorContext";
import { TTrait } from "@customTypes/trait";
import { TAvatarTraitItem } from "@customTypes/avatar";
import { EActiveTab } from "../SimulatorCollection";
import ReactTooltip from "react-tooltip";
import Loader from "@components/Loader";
import useSimulator from "@pages/Simulator/useSimulator";
import emptyTrait from "@images/builder/x-trait.png";
import styles from "./SimulatorSelectedTraits.module.scss";

interface ISimulatorSelectedTraits {
    isLoading: boolean;
}

const SimulatorSelectedTraits = ({ isLoading }: ISimulatorSelectedTraits): ReactElement => {
    const [selectedAvatarTraits, setSelectedAvatarTraits] = useState<TTrait[]>([]);
    const [doubleTappedTrait, setDoubleTappedTrait] = useState<TTrait | null>(null);
    const [tap, setTap] = useState(0);

    const { addTraitToPreview } = useSimulator();
    const { selectedAvatar, selectedTrait, updateSelectedTrait } = useSimulatorContext();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    useEffect(() => {
        if (selectedAvatar) {
            const traitsToParse = selectedAvatar.traits;
            const parsedTraits = Object.keys(sortTraits(traitsToParse)).map((key) =>
                mapAvatarTraitToTrait(traitsToParse[key])
            );
            setSelectedAvatarTraits(parsedTraits);
        }
    }, [selectedAvatar?.traits]);

    useEffect(() => {
        if (!selectedTrait) {
            for (let i = 0; i < selectedAvatarTraits.length; i++) {
                if (selectedAvatarTraits[i].id !== 0) {
                    updateSelectedTrait(selectedAvatarTraits[i]);
                    break;
                }
            }
        }
    }, [selectedAvatar?.id, selectedAvatarTraits]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTap(0);
        }, 300);

        if (tap === 2 && doubleTappedTrait) {
            addTraitToPreview(doubleTappedTrait, EActiveTab.MY_COLLECTION);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [tap]);

    const handleTap = (trait: TAvatarTraitItem) => {
        setDoubleTappedTrait(mapAvatarTraitToTrait(trait));
        updateSelectedTrait(mapAvatarTraitToTrait(trait));
        setTap(tap + 1);
    };

    const mapAvatarTraitToTrait = ({
        tokenId,
        image,
        name,
        rarity,
        style,
        type,
        rarityString,
    }: TAvatarTraitItem): TTrait => {
        const trait: TTrait = {
            id: tokenId,
            image,
            name,
            rarity,
            style: style ?? "",
            type,
            avatarId: 0,
            available: false,
            owner: "",
            rarityString: rarityString ?? "",
        };
        return trait;
    };

    const getToolTipText = (trait: TTrait) => {
        return `${trait.name} 
                <br /><br /> 
                ${trait.avatarId ? "Q+K #" + trait.avatarId + "<br /><br />" : ""}  
                Trait # ${trait.id} 
                <br /><br /> 
                ${trait.rarity}% have this trait`;
    };

    return (
        <div className={styles.container}>
            {isLoading ? (
                <>
                    {[...new Array(10)].map((_, index) => (
                        <div key={index} className={styles.placeHolder}>
                            <Loader className={styles.loader} circleClassName={styles.circle} />
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {selectedAvatarTraits &&
                        Object.keys(selectedAvatarTraits).map((key) => {
                            const dataTip = getToolTipText(selectedAvatarTraits[key]);

                            return (
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className={`${styles.traitContainer} ${
                                        selectedAvatarTraits[key].id === 0 ? styles.cursorNone : styles.cursorPointer
                                    }`}
                                    key={`selected-${selectedAvatarTraits[key].type}-trait`}
                                    onDoubleClick={() =>
                                        selectedAvatarTraits[key].id !== 0
                                            ? addTraitToPreview(selectedAvatarTraits[key], EActiveTab.MY_COLLECTION)
                                            : null
                                    }
                                    onClick={() => updateSelectedTrait(selectedAvatarTraits[key])}
                                    onKeyDown={() => updateSelectedTrait(selectedAvatarTraits[key])}
                                    onTouchStart={() => handleTap(selectedAvatarTraits[key])}
                                    data-tip={dataTip}
                                >
                                    <p>
                                        {selectedAvatarTraits[key].type === "extra"
                                            ? "power"
                                            : selectedAvatarTraits[key].type}
                                    </p>
                                    <div
                                        className={
                                            selectedAvatarTraits[key].id === selectedTrait?.id
                                                ? styles.selectedTraitOpensea
                                                : undefined
                                        }
                                    >
                                        <img
                                            src={
                                                selectedAvatarTraits[key].id !== 0
                                                    ? selectedAvatarTraits[key].image.replace(
                                                          "/traits-images/",
                                                          "/traits-images-thumbnails/"
                                                      )
                                                    : emptyTrait
                                            }
                                            alt={`${selectedAvatarTraits[key].type} trait`}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </>
            )}
        </div>
    );
};

export default SimulatorSelectedTraits;
