import { TTrait } from "@customTypes/trait";
import Trait from "@components/Trait";
import styles from "./TraitList.module.scss";

interface ITraitList {
    traits: TTrait[];
    showStats: boolean;
}

const TraitList = ({ traits, showStats }: ITraitList): JSX.Element => (
    <div className={styles.container}>
        {traits.map(({ id, rarity, image, type, available, name }) => (
            <Trait
                key={id}
                name={name}
                id={id}
                type={type}
                rarity={rarity}
                image={image}
                available={available}
                showStats={showStats}
                showTooltip={true}
            />
        ))}
    </div>
);

export default TraitList;
