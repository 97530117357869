import useWindowSize from "@hooks/useWindowSize";
import { ComponentType } from "react";

const WIDTH_LIMIT = 1024;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withMaxWidth = <T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) => {
    const WrappedComponent = (props: T) => {
        const { width } = useWindowSize();
        if (width && width < WIDTH_LIMIT) {
            return (
                <div className="notAvailableInMobile">
                    <p>THIS PAGE IS NOT AVAILABLE FOR MOBILE DEVICES.</p>
                </div>
            );
        }
        return <Component {...props} />;
    };

    return WrappedComponent;
};

export default withMaxWidth;
