import { useEffect, useState } from "react";
import { ETabType, EBurnStages } from "@enums/burn";
import { IAsset } from "@customTypes/burn/asset";
import { ETransactionStatus } from "@constants/enums";
import { useWeb3Context } from "@nftstudios/web3-provider";
import useBurnContract from "@hooks/contracts/useBurnContracts";
import mapAssetsToBurn from "@utils/scripts/burn/mapAssetsToBurn";

interface IUseBurn {
    txId: string | null;
    avatarsToBurn: IAsset[];
    traitsToBurn: IAsset[];
    heroinesToBurn: IAsset[];
    addToBurn: (asset: IAsset) => void;
    addTraitToBurn: (trait: IAsset) => void;
    addAvatarToBurn: (avatar: IAsset) => void;
    addHeroineToBurn: (heroine: IAsset) => void;
    burnAssets: () => void;
}

const useBurn = (tab: ETabType, updateStage: (stage: EBurnStages) => void): IUseBurn => {
    const { walletAddress, web3 } = useWeb3Context();
    const { burn, status, txId } = useBurnContract(web3, walletAddress);
    const [avatarsToBurn, setAvatarsToBurn] = useState<IAsset[]>([]);
    const [traitsToBurn, setTraitsToBurn] = useState<IAsset[]>([]);
    const [heroinesToBurn, setHeroinesToBurn] = useState<IAsset[]>([]);

    useEffect(() => {
        if (status === ETransactionStatus.NO_ACTION) {
            updateStage(EBurnStages.SELECTION);
        } else if (status === ETransactionStatus.WAITING_CONFIRMATION) {
            updateStage(EBurnStages.WAITING_CONFIRMATION);
        } else if (status === ETransactionStatus.IN_PROGRESS) {
            updateStage(EBurnStages.LOADING);
        } else if (status === ETransactionStatus.SUCCESS) {
            updateStage(EBurnStages.SUCCESS);
        } else if (status === ETransactionStatus.ERROR) {
            updateStage(EBurnStages.ERROR);
        }
    }, [status]);

    const addToBurn = (asset: IAsset) => {
        if (tab === ETabType.AVATARS) {
            addAvatarToBurn(asset);
        } else if (tab === ETabType.TRAITS) {
            addTraitToBurn(asset);
        } else {
            addHeroineToBurn(asset);
        }
    };

    const addTraitToBurn = (trait: IAsset) => {
        const foundTrait = traitsToBurn.find((t) => t.id === trait.id);

        if (foundTrait) {
            setTraitsToBurn((prevTraits) => prevTraits.filter((t) => t.id !== foundTrait.id));
            return;
        }

        setTraitsToBurn((prevTraits) => [...prevTraits, trait]);
    };

    const addAvatarToBurn = (avatar: IAsset) => {
        const foundAvatar = avatarsToBurn.find((a) => a.id === avatar.id);
        if (foundAvatar) {
            setAvatarsToBurn((prevAvatars) => prevAvatars.filter((a) => a.id !== foundAvatar.id));
            return;
        }

        setAvatarsToBurn((prevAvatars) => [...prevAvatars, avatar]);
    };

    const addHeroineToBurn = (heroine: IAsset) => {
        const foundHeroine = heroinesToBurn.find((h) => h.id === heroine.id);
        if (foundHeroine) {
            setHeroinesToBurn((prevHeroines) => prevHeroines.filter((h) => h.id !== foundHeroine.id));
            return;
        }

        setHeroinesToBurn((prevHeroines) => [...prevHeroines, heroine]);
    };

    const burnAssets = () => {
        updateStage(EBurnStages.LOADING);
        const assetsToBurn = mapAssetsToBurn(avatarsToBurn, traitsToBurn, heroinesToBurn);
        burn(assetsToBurn);
    };

    return {
        txId,
        avatarsToBurn,
        traitsToBurn,
        heroinesToBurn,
        addToBurn,
        addTraitToBurn,
        addAvatarToBurn,
        addHeroineToBurn,
        burnAssets,
    };
};

export default useBurn;
