// import SecondDropContractAbi from "@contracts/SecondDropABI";
// import PrideMonthDropAbi from "@contracts/PrideMonthDropABI";
import QKContractAbi from "@contracts/QKContractABI";
import PayableTraitsDropAbi from "@contracts/PayableTraitsDropABI";
import FreezeContractAbi from "@contracts/FreezeABI";
import {
    // PRIDE_MONTH_DROP_ADDR,
    QK_CONTRACT_ADDR,
    // SECOND_DROP_CONTRACT_ADDR,
    CLAIM_TRAIT_CONTRACT_ADDR,
    FREEZE_CONTRACT_ADDR,
} from "@constants/env";

export default [
    {
        contract: CLAIM_TRAIT_CONTRACT_ADDR as string,
        abi: PayableTraitsDropAbi,
    },
    {
        contract: QK_CONTRACT_ADDR as string,
        abi: QKContractAbi,
    },
    // {
    //     contract: PRIDE_MONTH_DROP_ADDR as string,
    //     abi: PrideMonthDropAbi,
    // },
    // {
    //     contract: SECOND_DROP_CONTRACT_ADDR as string,
    //     abi: SecondDropContractAbi,
    // },
    {
        contract: FREEZE_CONTRACT_ADDR as string,
        abi: FreezeContractAbi,
    },
];
