import { Redirect, Route, RouteProps } from "react-router-dom";
import { HOME } from "@constants/routes";
import { useWeb3Context } from "@nftstudios/web3-provider";
import DefaultLoader from "@components/DefaultLoader";

type PrivateRouteProps = RouteProps;

const PrivateRoute = ({ ...routeProps }: PrivateRouteProps): JSX.Element => {
    const { isInitialized, walletAddress } = useWeb3Context();

    if (!isInitialized) {
        return <DefaultLoader spinner />;
    }

    if (isInitialized && !walletAddress) {
        return <Redirect to={HOME} />;
    }

    return <Route {...routeProps} />;
};

export default PrivateRoute;
