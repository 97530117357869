import { ITraitApiResponse, ITraitRanking, ITraitRankingApiResponse, TTrait } from "@customTypes/trait";

export const apiResponseToTrait = ({
    name,
    type,
    tokenId,
    image,
    avatarId,
    owner,
    style,
    rarity,
    attributes,
    rarityString,
    minted,
}: ITraitApiResponse): TTrait => {
    return {
        id: tokenId,
        name,
        image,
        type,
        rarity,
        available: avatarId !== 0,
        avatarId,
        owner,
        style,
        attributeRarity: attributes ? attributes[0]?.value : "",
        rarityString,
        minted,
    };
};

export const apiResponseToRankingTrait = (
    type: string,
    { name, rank, score, preview, rarity, rarityString, minted }: ITraitRankingApiResponse
): ITraitRanking => {
    return {
        name,
        rank,
        score,
        preview,
        rarity,
        rarityString,
        minted,
        type,
    };
};
