import styles from "./BottomBanner.module.scss";

interface IBottomBanner {
    className?: string;
}

const BottomBanner = ({ className }: IBottomBanner): JSX.Element => (
    <div className={`${className} ${styles.container}`} />
);

export default BottomBanner;
