import { IAsset } from "@customTypes/burn/asset";
import { TParams } from "@customTypes/burn/params";
import { ETabType } from "@enums/burn";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import useGetAvatars from "./useGetAvatars";
import useGetHeroines from "./useGetHeorines";
import useGetTraits from "./useGetTraits";

interface IUseGetAssets {
    traitSearch: string;
    avatarSearch: string;
    heroineSearch: string;
    traits: IAsset[];
    avatars: IAsset[];
    heroines: IAsset[];
    params: TParams;
    traitPage: number;
    heroinesPage: number;
    avatarPage: number;
    total: number;
    isFetchingTraits: boolean;
    isFetchingAvatars: boolean;
    isFetchingHeroines: boolean;
    handleAvatarSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleAvatarSubmit: (e: FormEvent) => void;
    handleResetAvatarSearch: () => void;
    handleTraitSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleResetTraitSearch: () => void;
    handleTraitSubmit: (e: FormEvent) => void;
    handleHeroineSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleHeroineSubmit: (e: FormEvent) => void;
    handleResetHeroineSearch: () => void;
    handleItemsLimit: (e: ChangeEvent<HTMLSelectElement>) => void;
    handleTraitTypeChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    handleNextPage: () => void;
    handlePrevPage: () => void;
}

const useGetAssets = (tab: ETabType): IUseGetAssets => {
    const [params, setParams] = useState<TParams>({
        limit: 20,
        traitType: "crown",
        traitName: "",
        avatarId: "",
        heroineId: "",
        page: 0,
    });

    const { limit, traitName, traitType, avatarId, heroineId, page } = params;

    const {
        isLoading: isFetchingAvatars,
        currentPage: avatarsCurrentPage,
        data: avatars,
        total: avatarsTotal,
    } = useGetAvatars(limit, page, avatarId, tab);

    const {
        isLoading: isFetchingTraits,
        currentPage: traitsCurrentPage,
        data: traits,
        total: traitsTotal,
    } = useGetTraits(limit, page, traitName, traitType, tab);

    const {
        isLoading: isFetchingHeroines,
        currentPage: heroinesCurrentPage,
        data: heroines,
        total: heroinesTotal,
    } = useGetHeroines(limit, page, heroineId, tab);

    const [traitSearch, setTraitSearch] = useState("");
    const [avatarSearch, setAvatarSearch] = useState("");
    const [heroineSearch, setHeroineSearch] = useState("");

    const total = {
        [ETabType.TRAITS]: traitsTotal,
        [ETabType.AVATARS]: avatarsTotal,
        [ETabType.HEROINES]: heroinesTotal,
    };

    useEffect(() => {
        setParams((prevParams) => ({
            ...prevParams,
            limit: 20,
            page: 0,
        }));
    }, [tab]);

    const handleAvatarSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setAvatarSearch(e.target.value);
    };

    const handleResetAvatarSearch = () => {
        setParams({ ...params, avatarId: "", page: 0 });
        setAvatarSearch("");
    };

    const handleAvatarSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (avatarSearch) {
            setParams({ ...params, avatarId: avatarSearch, page: 0 });
            return;
        }

        setParams({ ...params, avatarId: "", page: 0 });
    };

    const handleTraitSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setTraitSearch(e.target.value);
    };

    const handleResetTraitSearch = () => {
        setParams({ ...params, traitName: "", page: 0 });
        setTraitSearch("");
    };

    const handleTraitSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (traitSearch) {
            setParams({ ...params, traitName: traitSearch, page: 0 });
            return;
        }
        setParams({ ...params, traitName: "", page: 0 });
    };

    const handleHeroineSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setHeroineSearch(e.target.value);
    };

    const handleResetHeroineSearch = () => {
        setParams({ ...params, heroineId: "", page: 0 });
        setHeroineSearch("");
    };

    const handleHeroineSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (heroineSearch) {
            setParams({ ...params, heroineId: heroineSearch, page: 0 });
            return;
        }

        setParams({ ...params, heroineId: "", page: 0 });
    };

    const handleItemsLimit = (e: ChangeEvent<HTMLSelectElement>) => {
        setParams({ ...params, limit: Number(e.target.value) });
    };

    const handleTraitTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setParams({ ...params, traitType: e.target.value, page: 0 });
    };

    const handleNextPage = () => {
        if (tab === ETabType.AVATARS) {
            setParams({ ...params, page: params.page + 1 });
            return;
        }

        setParams({ ...params, page: params.page + 1 });
    };

    const handlePrevPage = () => {
        if (tab === ETabType.AVATARS) {
            setParams({ ...params, page: params.page - 1 });
            return;
        }

        setParams({ ...params, page: params.page - 1 });
    };

    return {
        params,
        avatars,
        traits,
        total: total[tab] || 1,
        heroines,
        traitPage: traitsCurrentPage,
        heroinesPage: heroinesCurrentPage,
        avatarPage: avatarsCurrentPage,
        avatarSearch,
        traitSearch,
        heroineSearch,
        isFetchingTraits,
        isFetchingAvatars,
        isFetchingHeroines,
        handleAvatarSearchChange,
        handleAvatarSubmit,
        handleResetAvatarSearch,
        handleTraitSearchChange,
        handleResetTraitSearch,
        handleHeroineSearchChange,
        handleHeroineSubmit,
        handleResetHeroineSearch,
        handleTraitSubmit,
        handleItemsLimit,
        handleTraitTypeChange,
        handleNextPage,
        handlePrevPage,
    };
};

export default useGetAssets;
