import Modal from "react-modal";
import { useState } from "react";
import { KEY_INFURA_ID, KEY_WALLET_CONNECT_CHAIN, KEY_WALLET_CONNECT_ID, MAINTENANCE } from "@constants/env";
import { Web3ContextProvider } from "@nftstudios/web3-provider";
import DefaultLoader from "@components/DefaultLoader";
import Layout from "@components/Layout";
import "./index.scss";

function App(): JSX.Element {
    Modal.setAppElement("#root");

    const [isReady, setIsReady] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (document as any).fonts.load("16px Montserrat");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (document as any).fonts.load("16px Bebas-Neue");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (document as any).fonts.ready.then(() => setIsReady(true));

    if (MAINTENANCE && parseInt(MAINTENANCE) === 1) {
        return (
            <div className="maintenance" style={{ display: "flex", height: "100vh", justifyContent: "center" }}>
                website under maintenance
            </div>
        );
    }

    return isReady ? (
        <div className="App">
            <Web3ContextProvider
                infuraId={KEY_INFURA_ID}
                projectId={KEY_WALLET_CONNECT_ID}
                chainIds={[KEY_WALLET_CONNECT_CHAIN]}
            >
                <Layout />
            </Web3ContextProvider>
        </div>
    ) : (
        <DefaultLoader spinner />
    );
}

export default App;
