import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { TAvatar, TAvatarTraitItem, TAvatarTraits } from "@customTypes/avatar";
import { TTrait } from "@customTypes/trait";
import { useSimulatorContext } from "@context/simulatorContext";
import { ETransactionStatus, EProjectTransactionTypes } from "@constants/enums";
import { REGENERATOR_API } from "@constants/env";
import { PREVIEW_AVATAR } from "@constants/apiEndpoints";
import { EActiveTab } from "./components/SimulatorCollection";
import { checkDressTraitRule, copyAvatar, getParamsFromSelectedAvatar, randomAvatarId } from "./simulatorUtils";
import { useWeb3Context } from "@nftstudios/web3-provider";
import useAvatarContract from "@hooks/contracts/useAvatarContract";
import useGetAvatarsFromApi from "@hooks/request/useGetAvatarFromAPI";

interface IUseSimulator {
    txId: string | null;
    status: ETransactionStatus;
    moreAvatars: boolean;
    transactionType: EProjectTransactionTypes;
    mintedNextCursor: number;
    walletAddress: string;
    isOpenFreezeModal: boolean;
    originalIsEqualToSelected: boolean;
    selectedAvatarHasMintedTraits: boolean;
    toggleCarouselReset: boolean;
    avatarSearch: string;
    traits: TTrait[];
    originalAvatar: TAvatar | null;
    isLoadingAvatars: boolean;
    isLoadingTraits: boolean;
    isLoadingMintedTraits: boolean;
    isLoadingAvatarByTokenId: boolean;
    getTraits: () => void;
    resetAvatar: () => void;
    requestOpenFreezeModal: () => void;
    requestCloseFreezeModal: () => void;
    assembleSelectedAvatar: () => void;
    handleAvatarSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleAvatarSubmit: (e: FormEvent) => void;
    handleResetAvatarSearch: () => void;
    getMoreAvatars: (o: number) => void;
    addTraitToPreview: (t: TTrait, a: EActiveTab) => void;
    createSelectedAvatarTraits: () => void;
    handleActiveAvatar: (id: number) => void;
    getMintedTraits: (type: string, rarity: number, nextCursor?: number, limit?: number) => Promise<TTrait[]>;
    getAvatarByOriginalTokenId: (t: number) => Promise<TAvatar | null>;
    freezeSelectedAvatar: () => void;
    randomTraits: () => void;
}

const ORIGIN_MAX_AVATAR_IDS = 6900;

const useSimulator = (): IUseSimulator => {
    const [originalAvatar, setOriginalAvatar] = useState<TAvatar | null>(null);
    const [selectedAvatarHasMintedTraits, setSelectedAvatarHasMintedTraits] = useState(false);
    const [originalIsEqualToSelected, setOriginalIsEqualToSelected] = useState(true);
    const [avatarSearch, setAvatarSearch] = useState("");
    const [toggleCarouselReset, setToggleCarouselReset] = useState(false);
    const [isOpenFreezeModal, setIsOpenFreezeModal] = useState(false);
    const [transactionType, setTransactionType] = useState<EProjectTransactionTypes>(EProjectTransactionTypes.RE_MINT);

    const { web3, walletAddress, isInitialized } = useWeb3Context();
    const { status, txId, createTraits, assembleAvatar, hasMintedTraits, freezeAvatar } = useAvatarContract(
        web3,
        walletAddress
    );

    const {
        selectedAvatar,
        updateSelectedAvatar,
        updateAvatarsList,
        updateSelectedAvatarImage,
        avatarsList,
        updatePreviewLoading,
        updateAllTraitsAreMine,
        updateAvatarsListWithNewAvatar,
        resetAllTraitsAreMine,
    } = useSimulatorContext();

    const {
        avatars,
        traits,
        isLoadingAvatarByTokenId,
        isLoadingAvatarByOriginalTokenId,
        isLoadingTraits,
        isLoadingMintedTraits,
        isLoadingAvatars,
        mintedNextCursor,
        getAvatars,
        getTraits,
        getMintedTraits,
        moreAvatars,
        getAvatarByOriginalTokenId,
        getRandomTraits,
    } = useGetAvatarsFromApi(walletAddress);

    useEffect(() => {
        if (isInitialized && !walletAddress && avatarsList.length == 0) {
            getAvatarIfNotConnected();
            return;
        }

        walletAddress && getAvatars(0);
    }, [walletAddress, isInitialized]);

    useEffect(() => {
        if (avatars.length > 0) {
            const intersection = avatars.filter((a) => !avatarsList.some((al) => a.id === al.id));
            const union = [...avatarsList, ...intersection];
            updateAvatarsList(union);
        }
    }, [avatars]);

    useEffect(() => {
        if (!selectedAvatar) {
            updateSelectedAvatar(avatarsList[0]);
            setOriginalAvatar(avatarsList[0]);
        }
    }, [avatarsList]);

    useEffect(() => {
        checkOriginalEqualToSelected();

        if (originalAvatar && selectedAvatar) {
            regeneratePreviewImage(selectedAvatar);
        }
    }, [selectedAvatar?.traits, originalIsEqualToSelected]);

    useEffect(() => {
        if (!selectedAvatar) {
            return;
        }

        avatarHasMintedTraits();
    }, [selectedAvatar?.id]);

    const getAvatarIfNotConnected = async () => {
        const avatar = await getAvatarByOriginalTokenId(randomAvatarId());

        if (avatar) {
            updateAvatarsList([avatar]);
        } else if (!isLoadingAvatarByOriginalTokenId) {
            await getAvatarIfNotConnected();
        }
    };

    const checkOriginalEqualToSelected = () => {
        const types = ["extra", "crown", "hair", "eyes", "mouth", "beard", "face", "dress", "body", "background"];

        setOriginalIsEqualToSelected(true);

        for (const type of types) {
            if (selectedAvatar?.traits[type].tokenId !== originalAvatar?.traits[type].tokenId) {
                setOriginalIsEqualToSelected(false);
                break;
            }
        }
    };

    const handleActiveAvatar = (id: number) => {
        const avt = avatarsList.find((a: TAvatar) => a.id === id);

        if (avt) {
            updateSelectedAvatar(avt);
            setOriginalAvatar(avt);
        }

        updatePreviewLoading();
    };

    const resetAvatar = () => {
        if (!originalAvatar) {
            return;
        }

        updateSelectedAvatar(originalAvatar);
        resetAllTraitsAreMine();
    };

    const createSelectedAvatarTraits = () => {
        if (!selectedAvatar) {
            return;
        }
        resetAvatar();
        createTraits(selectedAvatar.id);
    };

    const assembleSelectedAvatar = () => {
        if (!selectedAvatar) {
            return;
        }
        /**
         * TRAITS NEEDS TO BE IN THIS ORDER
         * background: 0,
         * crown: 1,
         * hair: 2,
         * face: 3,
         * eyes: 4,
         * mouth: 5,
         * beard: 6,
         * body: 7,
         * dress: 8,
         * extra == powers: 9
         */
        const {
            id,
            traits: { background, crown, hair, face, eyes, mouth, beard, body, dress, extra },
        } = selectedAvatar;

        const traitToAssembleInOrder = [
            background.tokenId,
            crown.tokenId,
            hair.tokenId,
            face.tokenId,
            eyes.tokenId,
            mouth.tokenId,
            beard.tokenId,
            body.tokenId,
            dress.tokenId,
            extra.tokenId,
        ];

        assembleAvatar(id, traitToAssembleInOrder);
    };

    const requestOpenFreezeModal = async () => {
        if (!selectedAvatar) {
            return;
        }

        setIsOpenFreezeModal(true);
        setTransactionType(EProjectTransactionTypes.FREEZE);
    };

    const requestCloseFreezeModal = () => {
        setIsOpenFreezeModal(false);
        setTransactionType(EProjectTransactionTypes.RE_MINT);
    };

    const freezeSelectedAvatar = async () => {
        if (!selectedAvatar) {
            return;
        }

        freezeAvatar(selectedAvatar.id);
    };

    const handleAvatarSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setAvatarSearch(e.target.value);
    };

    const handleResetAvatarSearch = () => {
        setAvatarSearch("");
    };

    const handleAvatarSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (avatarSearch && selectedAvatar?.id !== Number(avatarSearch)) {
            const avatar = await getAvatarByOriginalTokenId(Number(avatarSearch));
            if (avatar) {
                updateAvatarsListWithNewAvatar(avatar);
                updateSelectedAvatar(avatar);
                setOriginalAvatar(avatar);
                setToggleCarouselReset(!toggleCarouselReset);
                return;
            }
        }
    };

    const addTraitToPreview = (traitToAdd: TTrait, activeTab: EActiveTab) => {
        if (!selectedAvatar) {
            return;
        }

        const shouldBeRemoved = Object.keys(selectedAvatar.traits).some(
            (key) =>
                selectedAvatar.traits[key].tokenId === traitToAdd.id &&
                selectedAvatar.traits[key].type === traitToAdd.type
        );

        validateTraitOwnership(activeTab, traitToAdd, shouldBeRemoved);

        const newTrait: TAvatarTraitItem = {
            tokenId: shouldBeRemoved ? 0 : traitToAdd.id,
            name: traitToAdd.name,
            image: traitToAdd.image,
            rarity: shouldBeRemoved ? 0 : traitToAdd.rarity,
            type: traitToAdd.type,
            style: traitToAdd.style ? traitToAdd.style : undefined,
            rarityString: traitToAdd.rarityString ?? "",
        };

        const avatarCopy = copyAvatar(selectedAvatar);

        avatarCopy.traits[traitToAdd.type] = newTrait;

        if (checkDressTraitRule(avatarCopy)) {
            avatarCopy.traits["dress"].tokenId = 0;
            avatarCopy.traits["dress"].rarity = 0;
        }

        updateSelectedAvatar(avatarCopy);
    };

    const avatarHasMintedTraits = async () => {
        if (!selectedAvatar || !walletAddress) {
            return false;
        }

        setSelectedAvatarHasMintedTraits(
            selectedAvatar.id > ORIGIN_MAX_AVATAR_IDS ? true : await hasMintedTraits(selectedAvatar.id)
        );
    };

    const validateTraitOwnership = (activeTab: EActiveTab, trait: TTrait, shouldBeRemoved: boolean) => {
        if (activeTab === EActiveTab.OPENSEA) {
            updateAllTraitsAreMine({ type: trait.type, value: shouldBeRemoved });
            return;
        }

        updateAllTraitsAreMine({ type: trait.type, value: true });
    };

    const regeneratePreviewImage = (avatar: TAvatar) => {
        if (!avatar) {
            return;
        }

        const params = getParamsFromSelectedAvatar(avatar);
        const queryParams = new URLSearchParams(params).toString();
        const previewUrl = `${REGENERATOR_API}${PREVIEW_AVATAR}?${queryParams}`;

        updateSelectedAvatarImage(previewUrl);
    };

    const getMoreAvatars = (offset: number) => {
        getAvatars(offset);
    };

    const randomTraits = async () => {
        const randomTraits = await getRandomTraits();

        if (!randomTraits.length) {
            return;
        }
        const traits = {};

        randomTraits.forEach((trait) => {
            traits[trait.type] = {
                image: trait.image,
                name: trait.name,
                rarity: trait.rarity,
                tokenId: trait.id,
                type: trait.type,
                rarityString: trait.rarityString,
                owner: trait.owner,
                style: trait.style,
            };

            updateAllTraitsAreMine({ type: trait.type, value: trait.owner === walletAddress });
        });

        if (selectedAvatar) {
            const avatarCopy = copyAvatar(selectedAvatar);
            avatarCopy.traits = traits as TAvatarTraits;

            updateSelectedAvatar(avatarCopy);
        }
    };

    return {
        txId,
        status,
        transactionType,
        moreAvatars,
        walletAddress,
        isLoadingAvatarByTokenId,
        isOpenFreezeModal,
        isLoadingAvatars,
        isLoadingTraits,
        isLoadingMintedTraits,
        originalIsEqualToSelected,
        selectedAvatarHasMintedTraits,
        toggleCarouselReset,
        traits,
        avatarSearch,
        originalAvatar,
        mintedNextCursor,
        handleActiveAvatar,
        resetAvatar,
        addTraitToPreview,
        getTraits,
        getMintedTraits,
        assembleSelectedAvatar,
        requestOpenFreezeModal,
        requestCloseFreezeModal,
        handleAvatarSearchChange,
        handleAvatarSubmit,
        handleResetAvatarSearch,
        getMoreAvatars,
        getAvatarByOriginalTokenId,
        freezeSelectedAvatar,
        createSelectedAvatarTraits,
        randomTraits,
    };
};

export default useSimulator;
