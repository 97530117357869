import { ReactElement } from "react";
import styles from "./ClaimTrait.module.scss";

export enum messages {
    CONNECT_WALLET = 0,
    NORMAL_MESSAGE = 1,
    SOLD_OUT = 2,
    NOT_WINNER = 3,
    ALL_CLAIMED = 4,
    CLOSED = 5,
    CLAMING = 6,
}

interface IUseMessagesHelper {
    getMessages: (status: number) => ReactElement;
}

const useMessageHelper = (): IUseMessagesHelper => {
    const getMessages = (status: number) => {
        switch (status) {
            case messages.CONNECT_WALLET:
                return (
                    <p className={styles.switchParagraph}>
                        CONNECT YOUR WALLET AND THEN CHECK FOR TRAITS OF A NOVEL NATURE.
                    </p>
                );
            case messages.NORMAL_MESSAGE:
                return (
                    <p className={styles.switchParagraph}>
                        THE ROYAL ART HAS ALLOWED FOR ANOTHER SEASON OF CREATIVE REIGN. THEY HAVE BEEN ETCHED INTO THE
                        CODE OF WEB3 HISTORY!
                    </p>
                );
            case messages.SOLD_OUT:
                return (
                    <p className={styles.switchParagraph}>
                        THIS HACKING SEASON IS OVER, THE ART IS ETERNAL AND LONG MAY OUR COMMUNITY REIGN. THE FUTURE IS
                        ON THE HORIZON.
                    </p>
                );
            case messages.NOT_WINNER:
                return (
                    <p className={styles.switchParagraph}>
                        IT APPEARS THAT YOU HAVE NOT CO-CREATED OUR CULTURE WITH YOUR INPUT THIS SEASON, WITHOUT
                        HACKING, ONE CANNOT CLAIM THE SPOILS OF VICTORY!
                    </p>
                );
            case messages.ALL_CLAIMED:
                return <p className={styles.switchParagraph}>YOUR WORK IS ALREADY ETCHED INTO HISTORY.</p>;
            case messages.CLOSED:
                return (
                    <p className={styles.switchParagraph}>
                        THIS HACKING SEASON IS OVER, A YEAR'S HAPPY REIGN CONCLUDES. <br />
                        WE ARE NOW CREATING NEW TRAITS FOR THE NEXT SEASON.
                    </p>
                );
            default:
                return <p className={styles.switchParagraph}>UNKNOWN MESSAGE</p>;
        }
    };

    return {
        getMessages,
    };
};

export default useMessageHelper;
