import { ChangeEvent, FormEvent, KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { ETabType } from "@enums/burn";
import magifier from "@images/magnifier.png";
import cross from "@images/cross.png";
import styles from "./Tabs.module.scss";

interface ITabs {
    tab: ETabType;
    avatarSearch: string;
    traitSearch: string;
    heroineSearch: string;
    handleTabChange: (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>, tab: ETabType) => void;
    handleAvatarSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleTraitSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleHeroineSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSubmitAvatarSearch: (event: FormEvent) => void;
    handleSubmitTraitSearch: (event: FormEvent) => void;
    handleSubmitHeroineSearch: (event: FormEvent) => void;
    handleResetHeroineSearch: () => void;
    handleResetAvatarSearch: () => void;
    handleResetTraitSearch: () => void;
}

const Tabs = ({
    tab,
    avatarSearch,
    traitSearch,
    heroineSearch,
    handleTabChange,
    handleAvatarSearchChange,
    handleTraitSearchChange,
    handleHeroineSearchChange,
    handleSubmitAvatarSearch,
    handleSubmitTraitSearch,
    handleSubmitHeroineSearch,
    handleResetAvatarSearch,
    handleResetTraitSearch,
    handleResetHeroineSearch,
}: ITabs): ReactElement => {
    return (
        <div className={styles.container}>
            <div className={styles.tabs} role="tablist">
                <div
                    role="tab"
                    tabIndex={0}
                    className={`${tab === ETabType.AVATARS ? styles.active : ""} ${styles.tab}`}
                    onClick={(e) => handleTabChange(e, ETabType.AVATARS)}
                    onKeyDown={(e) => handleTabChange(e, ETabType.AVATARS)}
                >
                    <p>Q+K AVATAR</p>
                    <form
                        className={`${styles.searchForm} ${tab === ETabType.AVATARS ? styles.visible : styles.hidden}`}
                        onSubmit={handleSubmitAvatarSearch}
                    >
                        <input
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className={styles.searchInput}
                            value={avatarSearch}
                            placeholder="ID"
                            onChange={handleAvatarSearchChange}
                        />
                        <button type="submit">
                            <img src={magifier} alt="Search by avatar id" />
                        </button>
                        {avatarSearch && (
                            <button className={styles.cross} type="button" onClick={handleResetAvatarSearch}>
                                <img src={cross} alt="Reset search filter" />
                            </button>
                        )}
                    </form>
                </div>
                <div
                    role="tab"
                    tabIndex={0}
                    className={`${tab === ETabType.TRAITS ? styles.active : ""} ${styles.tab}`}
                    onClick={(e) => handleTabChange(e, ETabType.TRAITS)}
                    onKeyDown={(e) => handleTabChange(e, ETabType.TRAITS)}
                >
                    <p>Q+K TRAITS</p>
                    <form
                        className={`${styles.searchForm} ${tab === ETabType.TRAITS ? styles.visible : styles.hidden}`}
                        onSubmit={handleSubmitTraitSearch}
                    >
                        <input
                            type="text"
                            className={styles.searchInput}
                            value={traitSearch}
                            placeholder="Name"
                            onChange={handleTraitSearchChange}
                        />
                        <button type="submit">
                            <img src={magifier} alt="" />
                        </button>
                        {traitSearch && (
                            <button className={styles.cross} type="submit" onClick={handleResetTraitSearch}>
                                <img src={cross} alt="Reset search filter" />
                            </button>
                        )}
                    </form>
                </div>
                <div
                    role="tab"
                    tabIndex={0}
                    className={`${tab === ETabType.HEROINES ? styles.active : ""} ${styles.tab}`}
                    onClick={(e) => handleTabChange(e, ETabType.HEROINES)}
                    onKeyDown={(e) => handleTabChange(e, ETabType.HEROINES)}
                >
                    <p>HEROINES</p>
                    <form
                        className={`${styles.searchForm} ${tab === ETabType.HEROINES ? styles.visible : styles.hidden}`}
                        onSubmit={handleSubmitHeroineSearch}
                    >
                        <input
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className={styles.searchInput}
                            value={heroineSearch}
                            placeholder="ID"
                            onChange={handleHeroineSearchChange}
                        />
                        <button type="submit">
                            <img src={magifier} alt="Search by avatar id" />
                        </button>
                        {heroineSearch && (
                            <button className={styles.cross} type="button" onClick={handleResetHeroineSearch}>
                                <img src={cross} alt="Reset search filter" />
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Tabs;
