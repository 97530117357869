import { ReactElement } from "react";
import DefaultLoader from "@components/DefaultLoader";
import styles from "./ClaimTraitWaitingConfirmation.module.scss";

const ClaimTraitWaitingConfirmation = (): ReactElement => (
    <section className={styles.container}>
        <p>WAITING CONFIRMATION</p>
        <p>PLEASE CONFIRM THE TRANSACTION FROM YOUR WALLET</p>
        <DefaultLoader classname={styles.loader} />
    </section>
);

export default ClaimTraitWaitingConfirmation;
