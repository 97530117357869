import axios from "axios";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TERMS_OF_USE } from "@constants/routes";
import { TAvatar } from "@customTypes/avatar";
import { getParamsFromSelectedAvatar } from "@pages/Simulator/simulatorUtils";
import { REGENERATOR_API } from "@constants/env";
import { CHECK_DUPLICATE } from "@constants/apiEndpoints";
import Button, { Variant } from "@elements/Button";
import DefaultLoader from "@components/DefaultLoader";
import cross from "@images/cross.png";
import styles from "./FreezeConfirmation.module.scss";

interface IFreezeConfirmation {
    isCopy: boolean;
    selectedAvatar: TAvatar | null;
    handleIsCopy: (c: boolean) => void;
    onConfirmation: () => void;
    onCancel: () => void;
}

enum ECopySteps {
    STEP_1 = "STEP_1",
    STEP_2 = "STEP_2",
}

const FreezeConfirmation = ({
    isCopy,
    selectedAvatar,
    handleIsCopy,
    onConfirmation,
    onCancel,
}: IFreezeConfirmation): ReactElement => {
    const [copySteps, setCopySteps] = useState(ECopySteps.STEP_1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkCopy = async () => {
            if (!selectedAvatar) {
                return;
            }
            const params = getParamsFromSelectedAvatar(selectedAvatar);
            const queryParams = new URLSearchParams(params).toString();
            const {
                data: { duplicate },
            } = await axios.get(`${REGENERATOR_API}${CHECK_DUPLICATE}?${queryParams}`);
            handleIsCopy(duplicate);
            setIsLoading(false);
        };

        checkCopy();
    }, [selectedAvatar?.id]);

    if (isCopy && copySteps === ECopySteps.STEP_1) {
        return (
            <div className={styles.container}>
                <div className={styles.closeBox}>
                    <button onClick={onCancel}>
                        <img src={cross} alt="Close modal" />
                    </button>
                </div>
                {isLoading ? (
                    <DefaultLoader spinner />
                ) : (
                    <>
                        <p className={`${styles.title} ${styles.bolded}`}>COPY QK WARNING</p>

                        <p className={`${styles.info} ${styles.bolded}`}>
                            BEWARE! DO YOU REALLY WANT <br />
                            TO FREEZE YOUR AVATAR?
                        </p>
                        <p className={styles.info}>
                            ANOTHER USER HAS ALREADY CREATED <br /> AN IDENTICAL FROZEN AVATAR, <br />
                            THEREFORE YOURS WILL BE MARKED <br />
                            'COPY' AND WILL NOT BE SUBJECT TO THE COMMERCIAL USE LICENSE.
                        </p>
                        <p className={`${styles.info} ${styles.bolded}`}>
                            ARE YOU SURE THAT <br />
                            YOU WANT TO PROCEED?
                        </p>

                        <Button
                            className={styles.confirmButton}
                            variant={Variant.SecondaryHeader}
                            onClick={() => setCopySteps(ECopySteps.STEP_2)}
                            aria-label="Got to disclaimer step two"
                        >
                            Freeze QK
                        </Button>
                    </>
                )}
            </div>
        );
    }

    if (isCopy && copySteps === ECopySteps.STEP_2) {
        return (
            <div className={styles.container}>
                <div className={styles.closeBox}>
                    <button onClick={onCancel}>
                        <img src={cross} alt="Close modal" />
                    </button>
                </div>
                <p className={`${styles.title} ${styles.bolded}`}>COPY QK WARNING II</p>

                <p className={styles.info}>DO YOU REALLY WANT TO FREEZE A COPY? </p>
                <p className={`${styles.info} ${styles.bolded}`}>
                    CHANGE AT LEAST 1 OR 2 TRAITS <br />
                    TO MAKE IT YOUR UNIQUE Q+K AVATAR!
                </p>
                <p className={styles.info}>
                    TO ABORT THIS FREEZE AND CHANGE YOUR <br />
                    AVATAR CLICK{" "}
                    <Button
                        className={styles.links}
                        variant={Variant.Link}
                        onClick={onCancel}
                        aria-label="Cancel freeze and change at least one trait"
                    >
                        HERE
                    </Button>
                    . TO PROCEED WITH <br /> FREEZING YOUR AVATAR, CONFIRM THAT YOU <br />
                    HAVE READ AND ACCEPT THE TERMS & <br />
                    CONDITIONS APPLICABLE TO COPIES BY <br />
                    PRESSING THE BUTTON BELOW.
                </p>

                <Button
                    className={styles.confirmButton}
                    variant={Variant.SecondaryHeader}
                    onClick={onConfirmation}
                    aria-label="Confirm freeze selected avatar"
                >
                    Freeze QK
                </Button>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.closeBox}>
                <button onClick={onCancel}>
                    <img src={cross} alt="Close modal" />
                </button>
            </div>
            {isLoading ? (
                <DefaultLoader spinner />
            ) : (
                <>
                    <p className={`${styles.title} ${styles.bolded}`}>FREEZE QK WARNING</p>

                    <p className={`${styles.info} ${styles.bolded}`}>
                        BY FREEZING THIS Q+K YOU WILL LOCK THE APPEARANCE OF YOUR AVATAR FOREVER.
                    </p>
                    <p className={`${styles.info} ${styles.noMarginBottom}`}>
                        ARE YOU SURE YOU WANT TO FREEZE THIS Q+K? <br />
                        THE ACTION IS IRREVERSIBLE, BUT IT WILL GRANT YOU COMMERCIAL RIGHTS ON YOUR AVATAR.
                    </p>
                    <p>
                        <Link to={TERMS_OF_USE} className={`${styles.links} ${styles.noMarginTop}`}>
                            T&C LINK
                        </Link>
                    </p>
                    <p className={styles.info}>THE TRAITS OF A FROZEN Q+K ARE NO LONGER INTERCHANGEABLE (BURNED).</p>

                    <Button
                        className={styles.confirmButton}
                        variant={Variant.SecondaryHeader}
                        onClick={onConfirmation}
                        aria-label="Confirm freeze selected avatar"
                    >
                        Freeze QK
                    </Button>
                </>
            )}
        </div>
    );
};

export default FreezeConfirmation;
