import { IAsset } from "@customTypes/burn/asset";
import { TBurnInput } from "@customTypes/burn/contractInput";

const mapAssetsToBurn = (avatars: IAsset[], traits: IAsset[], heroines: IAsset[]): TBurnInput => {
    const types: string[] = [];
    const tokenIds: number[] = [];

    avatars.forEach((avatar) => {
        types.push("avatar");
        tokenIds.push(Number(avatar.id));
    });

    heroines.forEach((heroine) => {
        if (heroine.type === "HD") {
            types.push("heroinesD");
            tokenIds.push(Number(heroine.id));
        } else {
            types.push("heroinesH");
            tokenIds.push(Number(heroine.id));
        }
    });

    traits.forEach((trait) => {
        types.push(trait.type);
        tokenIds.push(Number(trait.id.split("-")[0]));
    });

    return {
        types,
        tokenIds,
    };
};

export default mapAssetsToBurn;
