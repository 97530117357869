import { ReactElement } from "react";
import { ETabType } from "@enums/burn";
import styles from "./Head.module.scss";

interface IHead {
    tab: ETabType;
    traitType: string;
    handleTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const types = ["crown", "hair", "eyes", "mouth", "face", "dress", "body", "background"];

const Head = ({ tab, traitType, handleTypeChange }: IHead): ReactElement => {
    return (
        <thead>
            <tr className={styles.tableHeader}>
                {tab === ETabType.TRAITS ? (
                    <th className={styles.types}>
                        <select value={traitType} onChange={handleTypeChange}>
                            {types.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </th>
                ) : (
                    <th className={styles.name}>Q+K ID</th>
                )}
                <th className={styles.preview}>PREVIEW</th>
                <th className={styles.select}>SELECT</th>
            </tr>
        </thead>
    );
};

export default Head;
