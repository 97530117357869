import { Link } from "react-router-dom";
import { MY_COLLECTION, HOME, RANKING } from "@constants/routes";
import Navbar from "../Navbar";
import Button from "@elements/Button";
import logo from "@images/logo.png";
import logoX2 from "@images/logo_x2.png";
import styles from "./HeaderDesktop.module.scss";
import { useWeb3Context } from "@nftstudios/web3-provider";
import PROVIDER from "@constants/web3.config";

const HeaderDesktop = (): JSX.Element => {
    const { walletAddress, isInitialized, connect } = useWeb3Context();
    const isWalletConnected = isInitialized && walletAddress;

    return (
        <div className={styles.container} data-testid="header-desktop-container">
            <Link to={HOME} data-testid="header-logo-home-link">
                <img src={logo} srcSet={`${logoX2} 2x`} alt="Queens + Kings" />
            </Link>

            <div className={styles.content}>
                <Navbar />
                <div className={styles.content}>
                    {isWalletConnected ? (
                        <Link to={MY_COLLECTION} data-testid="header-desktop-my-collection-link">
                            MY COLLECTION
                        </Link>
                    ) : (
                        <Button onClick={() => connect(PROVIDER)} data-testid="header-desktop-connect-wallet-btn">
                            CONNECT WALLET
                        </Button>
                    )}
                    <Link
                        to={RANKING}
                        className={styles.subversiveHierarchy}
                        data-testid="header-subversive-hierarchy-link"
                    >
                        SUBVERSIVE HIERARCHY
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default HeaderDesktop;
