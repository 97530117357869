import { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { BURNING } from "@constants/routes";
import withMaxWidth from "@hocs/withMaxWidth";
import arrow from "@images/carousel-chevron.png";
import useGetHistoryBurnedAssets from "@hooks/royalList/useGetHistoryBurnedAssets";
import styles from "./RoyalList.module.scss";
import DefaultLoader from "@components/DefaultLoader";

const RoyalList = (): ReactElement => {
    const [actualPage, setActualPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const { data, total, currentPage, isLoading } = useGetHistoryBurnedAssets(actualPage, limit);

    const canGoNext = actualPage + 1 < Math.ceil(total / limit);
    const canGoPrev = actualPage + 1 > 1;

    const handlePrevPage = () => {
        setActualPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setActualPage(currentPage + 1);
    };

    const handleItemsLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLimit(Number(e.target.value));
    };

    const generateimageUrl = (image: string) => {
        if (image.includes("img-width")) {
            return image;
        }
        return `${image}?img-width=118`;
    };

    return (
        <div className={styles.container}>
            <p className={styles.title}>
                <span className={styles.gold}>BURN YOUR SACRIFICIES, DEAR ROYALS</span>. THE CROWN REQUIRES IT. IN THIS
                AGE,
            </p>
            <p className={styles.title}>HIERARCHY RESHAPES AND SUBVERTS. NO ESCAPE. RITUAL UNFOLDS AND POINTS</p>
            <p className={styles.title}>
                ARE AWARDED. <span className={styles.gold}>THE ROYAL FLAME SPREADS.</span>
            </p>

            <table className={styles.table}>
                <thead>
                    <tr className={styles.tableHeader}>
                        <th className={styles.name}>NAME</th>
                        <th className={styles.preview}>PREVIEW</th>
                        <th className={styles.wallet}>WALLET</th>
                        <th className={styles.date}>DATE</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <tr className={styles.fullRow}>
                            <td>
                                <DefaultLoader classname={styles.loader} spinner invert />
                            </td>
                        </tr>
                    ) : (
                        <>
                            {data.length > 0 ? (
                                <>
                                    {data.map(({ name, image, owner, date, type, tokenId }) => (
                                        <tr className={styles.row} role="button" key={`${type}-${tokenId}`}>
                                            <td className={styles.name}>{name}</td>
                                            <td className={styles.preview}>
                                                <img src={generateimageUrl(image)} alt={`Burned asset ${name}`} />
                                            </td>
                                            <td className={styles.wallet} title={owner}>
                                                {owner}
                                            </td>
                                            <td className={styles.date}>{date}</td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr className={styles.fullRow}>
                                    <td>
                                        <p className={styles.notFound}>No history burned assets found</p>
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td className={styles.pagerContainer}>
                            <button
                                className={styles.prevBtn}
                                onClick={canGoPrev ? handlePrevPage : undefined}
                                aria-label="Previous page"
                                disabled={!canGoPrev}
                            >
                                <img
                                    className={`${!canGoPrev ? styles.hidden : ""}`}
                                    src={arrow}
                                    alt="Search by avatar id"
                                />
                            </button>
                            <span>{`${actualPage + 1} / ${Math.ceil(total / limit)}`}</span>
                            <button
                                className={styles.nextBtn}
                                onClick={canGoNext ? handleNextPage : undefined}
                                aria-label="Next page"
                                disabled={!canGoNext}
                            >
                                <img
                                    className={`${!canGoNext ? styles.hidden : ""}`}
                                    src={arrow}
                                    alt="Search by avatar id"
                                />
                            </button>
                            <select value={limit} onChange={handleItemsLimitChange}>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <Link to={BURNING}>BURN</Link>
        </div>
    );
};

export default withMaxWidth(RoyalList);
