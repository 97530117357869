import Button, { Variant } from "@elements/Button";
import DefaultLoader from "@components/DefaultLoader";
import { TAvatar } from "@customTypes/avatar";
import { sortTraits } from "@utils/scripts/AvatarUtils";
import { QK_CONTRACT_ADDR, FREEZE_CONTRACT_ADDR } from "@constants/env";
import styles from "./AvatarDetail.module.scss";

interface IAvatarDetail {
    avatar: TAvatar;
    stage: EAvatarDetailsStage;
    className?: string;
    isLoading: boolean;
    handlePreviewLoaded: () => void;
    handleAssemble?: () => void;
    handleModify?: () => void;
    handleRandom?: () => void;
    handleCreate?: () => void;
}

export enum EAvatarDetailsStage {
    ModifyAndRandom = 1,
    Assemble = 2,
    View = 3,
    Create = 4,
}

const actionButton = {
    modify: (modify: () => void) => (
        <>
            <Button className={styles.btnBlack} onClick={modify}>
                HACK
                <br /> IT
            </Button>
        </>
    ),
    assemble: (assemble: () => void) => (
        <Button variant={Variant.SecondaryHeader} className={styles.btnPrimary} onClick={assemble}>
            RE-MINT
        </Button>
    ),
    view: (id: number, originalTokenId: number, frozen: boolean) => (
        <a
            href={`https://opensea.io/assets/${frozen ? FREEZE_CONTRACT_ADDR : QK_CONTRACT_ADDR}/${
                frozen ? originalTokenId : id
            }`}
            target="_blank"
            rel="noopener noreferrer"
        >
            VIEW IN OPENSEA
        </a>
    ),
    create: (createTratits: () => void) => (
        <Button variant={Variant.PrimaryHeader} className={styles.btnPrimary} onClick={createTratits}>
            MINT TRAITS
        </Button>
    ),
};

const AvatarDetail = ({
    avatar,
    stage = EAvatarDetailsStage.ModifyAndRandom,
    className,
    handleAssemble,
    handleModify,
    handleCreate,
    handlePreviewLoaded,
    isLoading,
}: IAvatarDetail): JSX.Element => {
    const { id, image, traits, originalTokenId, frozen } = avatar;

    const handleOnLoad = () => {
        handlePreviewLoaded();
    };

    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.identifier}>
                <p>
                    Q<span>+</span>K #{originalTokenId}
                </p>
            </div>
            <div className={styles.details}>
                <div className={styles.imageContainer}>
                    {isLoading && <DefaultLoader spinner />}
                    <img onLoad={handleOnLoad} src={image} alt={`${id}`} style={isLoading ? { display: "none" } : {}} />
                </div>
                <div className={styles.traitsContainer}>
                    {Object.keys(sortTraits(traits)).map((key) => (
                        <div className={styles.trait} key={`${traits[key].type}-${traits[key].tokenid}`}>
                            <p>
                                <span>{traits[key].type === "extra" ? "powers" : traits[key].type}</span>
                                <span>{traits[key].rarity > 0 ? `${traits[key].rarity}%` : "-"}</span>
                            </p>
                        </div>
                    ))}
                </div>
                <div className={styles.buttons}>
                    <div className={styles.btnContainer}>
                        {stage === EAvatarDetailsStage.Create && handleCreate && actionButton.create(handleCreate)}{" "}
                        {stage === EAvatarDetailsStage.ModifyAndRandom &&
                            handleModify &&
                            actionButton.modify(handleModify)}{" "}
                        {stage === EAvatarDetailsStage.Assemble &&
                            handleAssemble &&
                            actionButton.assemble(handleAssemble)}
                        {stage === EAvatarDetailsStage.View && actionButton.view(id, originalTokenId, frozen)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AvatarDetail;
