import { Dispatch, useReducer } from "react";
import { EActionTypes, ETransactionStatus } from "@constants/enums";

export type TError = {
    name: string;
    message: string;
    code: number;
};

export type TBurnPayload = {
    txId: string | null;
    error?: TError | null;
    status?: ETransactionStatus;
};

type TAction = {
    type: EActionTypes;
    payload: TBurnPayload;
};

const initialState: TBurnPayload = {
    txId: null,
    error: null,
    status: ETransactionStatus.NO_ACTION,
};

const burnReducer = (state: TBurnPayload, action: TAction) => {
    const {
        type,
        payload: { txId, error },
    } = action;

    switch (type) {
        case EActionTypes.CALLING_CONTRACT:
            return {
                ...state,
                status: ETransactionStatus.WAITING_CONFIRMATION,
            };
        case EActionTypes.TRANSACTION_HASH:
            return {
                ...state,
                txId,
                status: ETransactionStatus.IN_PROGRESS,
            };
        case EActionTypes.DATA:
            return {
                ...state,
                status: ETransactionStatus.SUCCESS,
            };
        case EActionTypes.ERROR:
            return {
                ...state,
                status: ETransactionStatus.ERROR,
                error,
                txId: null,
            };
        case EActionTypes.RESET:
            return initialState;
        default:
            throw new Error("Unkwnown action type");
    }
};

export const getBurnReducer = (): [state: TBurnPayload, dispatch: Dispatch<TAction>] =>
    useReducer(burnReducer, initialState);
