import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { ROYAL_LIST } from "@constants/routes";
import Button, { Variant } from "@elements/Button";
import BottomBanner from "@components/BottomBanner";
import burnImage from "@images/burn.gif";
import styles from "./Connect.module.scss";

interface IConnect {
    handleConnect: () => void;
}

const Connect = ({ handleConnect }: IConnect): ReactElement => {
    return (
        <div className={styles.container}>
            <p>
                THE HOUR IS UPON YOU TO BURN YOUR Q+K AND ASCEND TO THE HEIGHTS OF{" "}
                <span className={styles.gold}>THE BURNING RANKING</span>. EMBRACE THE FIERY REALM OF TRANSFORMATION. THE
                UNDERTAKING SHALL BESTOW UPON YOU THE MERITS YOU SEEK!
            </p>

            <div className={styles.connect}>
                <img src={burnImage} alt="" />

                <Button
                    className={styles.btnPrimary}
                    variant={Variant.PrimaryHeader}
                    onClick={handleConnect}
                    data-testid="burn-connect-wallet-button"
                >
                    CONNECT WALLET
                </Button>
                <img src={burnImage} alt="" />
            </div>

            <Link className={styles.royalList} to={ROYAL_LIST}>
                VIEW BURNING LIST
            </Link>

            <p className={styles.disclaimer}>*RANKINGS MAY CHANGE AS NEW TRAITS ARE BURNED BY COLLECTORS.</p>

            <BottomBanner />
        </div>
    );
};

export default Connect;
