import { useEffect } from "react";
import { useParams, Redirect, Link } from "react-router-dom";
import { HOME, HACK_THE_ROYALS } from "@constants/routes";
import { useWeb3Context } from "@nftstudios/web3-provider";
import {
    T_BACKGROUND_CONTRACT_ADDR,
    T_BEARD_CONTRACT_ADDR,
    T_BODY_CONTRACT_ADDR,
    T_CROWN_CONTRACT_ADDR,
    T_DRESS_CONTRACT_ADDR,
    T_EXTRA_CONTRACT_ADDR,
    T_EYES_CONTRACT_ADDR,
    T_FACE_CONTRACT_ADDR,
    T_HAIR_CONTRACT_ADDR,
    T_MOUTH_CONTRACT_ADDR,
} from "@constants/env";
const traitToContract = {
    background: T_BACKGROUND_CONTRACT_ADDR,
    crown: T_CROWN_CONTRACT_ADDR,
    hair: T_HAIR_CONTRACT_ADDR,
    face: T_FACE_CONTRACT_ADDR,
    eyes: T_EYES_CONTRACT_ADDR,
    mouth: T_MOUTH_CONTRACT_ADDR,
    beard: T_BEARD_CONTRACT_ADDR,
    body: T_BODY_CONTRACT_ADDR,
    dress: T_DRESS_CONTRACT_ADDR,
    extra: T_EXTRA_CONTRACT_ADDR,
};
import DefaultLoader from "@components/DefaultLoader";
import useGetAvatarsFromApi from "@hooks/request/useGetAvatarFromAPI";
import styles from "./TraitProfile.module.scss";

const TraitProfile = (): JSX.Element => {
    const { type, id } = useParams<{ type: string; id: string }>();
    const { walletAddress } = useWeb3Context();
    const { isLoadingTraitByTokenId, trait, getTraitByTokenId } = useGetAvatarsFromApi(walletAddress);

    useEffect(() => {
        if (!walletAddress) {
            return;
        }

        getTraitByTokenId(type, parseInt(id));
    }, [walletAddress]);

    if (isLoadingTraitByTokenId) {
        return <DefaultLoader classname={styles.loader} spinner />;
    }

    if (!trait) {
        return <Redirect to={HOME} />;
    }

    return (
        <section className={styles.container}>
            <div className={styles.upperContainer}>
                <div>
                    <p className={styles.traitName}>
                        {trait?.name} #{id}
                    </p>
                    <img className={styles.traitImage} src={trait?.image} alt={`Trait ${trait?.name}`} />
                    <p className={styles.traitPercent}>{trait?.rarity}% Q+K HAVE THIS TRAIT</p>
                </div>
                {trait && (
                    <div>
                        <a
                            href={`https://opensea.io/assets/${traitToContract[trait.type]}/${id}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            VIEW IN OPENSEA
                        </a>
                    </div>
                )}
            </div>

            {trait?.owner === walletAddress && trait.avatarId !== 0 && (
                <div className={styles.lowerContainer}>
                    <div>
                        <p>
                            CLICK ON <span>HACK IT</span> TO CHANGE THE APPEARANCE OF YOUR AVATAR. YOU COULD ALSO TRADE
                            YOUR TRAITS ON <span>OPENSEA</span>.
                        </p>
                    </div>
                    <div>
                        <Link to={HACK_THE_ROYALS}>HACK IT</Link>
                    </div>
                </div>
            )}
        </section>
    );
};
export default TraitProfile;
