import { ReactElement } from "react";
import BottomBanner from "@components/BottomBanner";
import DefaultLoader from "@components/DefaultLoader";
import styles from "./WaitingConfirmation.module.scss";

const WaitingConfirmation = (): ReactElement => {
    return (
        <div className={styles.container}>
            <h3 className={styles.title}>WAITING CONFIRMATION</h3>
            <p>PLEASE CONFIRM THE TRANSACTION FROM YOUR WALLET.</p>
            <DefaultLoader invert classname={styles.loader} />
            <BottomBanner />
        </div>
    );
};

export default WaitingConfirmation;
