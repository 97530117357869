import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "@pages/Home";
import Faq from "@pages/Faq";
import Terms from "@pages/Terms";
import MyCollection from "@pages/MyCollection";
import AvatarProfile from "@pages/AvatarProfile";
import TraitProfile from "@pages/TraitProfile";
import PrivacyPolicy from "@pages/PrivacyPolicy";
import HowItWorks from "@pages/HowItWorks";
import NotFound from "@pages/NotFound";
import LearnMore from "@pages/LearnMore";
import Roadmap from "@pages/Roadmap";
import Ranking from "@pages/Ranking";
import Simulator from "@pages/Simulator";
import ClaimTrait from "@pages/ClaimTrait";
import PrivateRoute from "./components/PrivateRoute";
import Burn from "@pages/Burn";
import { SimulatorContextProvider } from "@context/simulatorContext";
import {
    HOME,
    FAQ,
    TERMS_OF_USE,
    MY_COLLECTION,
    AVATAR_PROFILE,
    TRAIT_PROFILE,
    PRIVACY_POLICY,
    HOW_IT_WORKS,
    LEARN_MORE,
    ROADMAP,
    HACK_THE_ROYALS,
    RANKING,
    CLAIM_TRAIT,
    BURNING,
    ROYAL_LIST,
} from "@constants/routes";
import AppLayout from "./components/AppLayout";
import RoyalList from "@pages/RoyalList";

const WrappedSimilatorWithContext = () => (
    <SimulatorContextProvider>
        <Simulator />
    </SimulatorContextProvider>
);

const Layout = (): JSX.Element => (
    <Router>
        <AppLayout>
            <Switch>
                <Route exact path={HOME} component={Home} />
                <Route exact path={CLAIM_TRAIT} component={ClaimTrait} />
                <PrivateRoute exact path={MY_COLLECTION} component={MyCollection} />
                <Route exact path={HACK_THE_ROYALS} component={WrappedSimilatorWithContext} />
                <Route exact path={AVATAR_PROFILE} component={AvatarProfile} />
                <Route exact path={TRAIT_PROFILE} component={TraitProfile} />
                <Route exact path={HOW_IT_WORKS} component={HowItWorks} />
                <Route exact path={FAQ} component={Faq} />
                <Route exact path={TERMS_OF_USE} component={Terms} />
                <Route exact path={PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route exact path={LEARN_MORE} component={LearnMore} />
                <Route exact path={ROADMAP} component={Roadmap} />
                <Route exact path={RANKING} component={Ranking} />
                <Route exact path={BURNING} component={Burn} />
                <Route exact path={ROYAL_LIST} component={RoyalList} />
                <Route component={NotFound} />
            </Switch>
        </AppLayout>
    </Router>
);

export default Layout;
