export const KEY_INFURA_ID = process.env.REACT_APP_INFURA_ID;
export const KEY_WALLET_CONNECT_ID = process.env.REACT_APP_WALLET_CONNECT_ID ?? "";
export const KEY_WALLET_CONNECT_CHAIN = parseInt(process.env.REACT_APP_WALLET_CONNECT_CHAIN ?? "");
export const KEY_APPNAME = "Hackatao - Queens & Kings";
export const KEY_APPLOGO = "";
export const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_URL;
export const OPENSEA_COLLECTION_URL = process.env.REACT_APP_OPENSEA_COLLECTION_URL;
export const OPENSEA_LAB_COLLECTION_URL = process.env.REACT_APP_OPENSEA_LAB_COLLECTION_URL;
export const OPENSEA_FROZEN_COLLECTION_URL = process.env.REACT_APP_OPENSEA_FROZEN_COLLECTION_URL;
export const OPENSEA_TRAITS_COLLECTION_URL = process.env.REACT_APP_OPENSEA_TRAITS_COLLECTION_URL;
export const OPENSEA_GENESIS_COLLECTION_URL = process.env.REACT_APP_OPENSEA_GENESIS_COLLECTION_URL;
// export const SUBSCRIPTION_API = process.env.REACT_APP_SUBSCRIPTION_API;
export const MAINTENANCE = process.env.REACT_APP_IS_IN_MAINTENANCE;
export const REGENERATOR_API = process.env.REACT_APP_REGENERATOR_API;
// Other projects contracts addresses
// export const PXC_CONTRACT_ADDR = process.env.REACT_APP_PXC_CONTRACT_ADDR;
// export const CM_CONTRACT_ADDR = process.env.REACT_APP_CM_CONTRACT_ADDR;
// Project smart contracts addresses
export const QK_CONTRACT_ADDR = process.env.REACT_APP_QK_CONTRACT_ADDR;
export const T_BACKGROUND_CONTRACT_ADDR = process.env.REACT_APP_T_BACKGROUND_CONTRACT_ADDR;
export const T_CROWN_CONTRACT_ADDR = process.env.REACT_APP_T_CROWN_CONTRACT_ADDR;
export const T_HAIR_CONTRACT_ADDR = process.env.REACT_APP_T_HAIR_CONTRACT_ADDR;
export const T_FACE_CONTRACT_ADDR = process.env.REACT_APP_T_FACE_CONTRACT_ADDR;
export const T_EYES_CONTRACT_ADDR = process.env.REACT_APP_T_EYES_CONTRACT_ADDR;
export const T_MOUTH_CONTRACT_ADDR = process.env.REACT_APP_T_MOUTH_CONTRACT_ADDR;
export const T_BEARD_CONTRACT_ADDR = process.env.REACT_APP_T_BEARD_CONTRACT_ADDR;
export const T_BODY_CONTRACT_ADDR = process.env.REACT_APP_T_BODY_CONTRACT_ADDR;
export const T_DRESS_CONTRACT_ADDR = process.env.REACT_APP_T_DRESS_CONTRACT_ADDR;
export const T_EXTRA_CONTRACT_ADDR = process.env.REACT_APP_T_EXTRA_CONTRACT_ADDR;
export const HEROINES_CONTRACT_ADDR = process.env.REACT_APP_HEROINES_CONTRACT_ADDR;
// Project evets smart contracts addresses
// export const FIRST_DROP_CONTRACT_ADDR = process.env.REACT_APP_FIRST_DROP_CONTRACT_ADDR;
// export const SECOND_DROP_CONTRACT_ADDR = process.env.REACT_APP_SECOND_DROP_CONTRACT_ADDR;
// export const PRIDE_MONTH_DROP_ADDR = process.env.REACT_APP_PRIDE_MONTH_DROP_ADDR;
// export const FIRST_HACKING_SEASON_ADDR = process.env.REACT_APP_FIRST_HACKING_SEASON_ADDR;
export const FREEZE_CONTRACT_ADDR = process.env.REACT_APP_FREEZE_CONTRACT_ADDR;
export const CLAIM_TRAIT_CONTRACT_ADDR = process.env.REACT_APP_CLAIM_TRAIT_CONTRACT_ADDR;
export const BURN_CONTRACT_ADDR = process.env.REACT_APP_BURN_CONTRACT_ADDR;
// Misc constants for project events
// export const IS_BUILDER_OPEN = parseInt(process.env.REACT_APP_IS_BUILDER_OPEN ?? "0");
// export const IS_PUBLIC_SALE_OPEN = parseInt(process.env.REACT_APP_IS_PUBLIC_SALE_OPEN ?? "0");
// export const SUBSCRIPTION_START_DATE = Number(process.env.REACT_APP_SUBSCRIPTION_START_DATE);
// export const SUBSCRIPTION_END_DATE = Number(process.env.REACT_APP_SUBSCRIPTION_END_DATE);
// export const SECURED_MINT_START_DATE = Number(process.env.REACT_APP_SECURED_MINT_START_DATE);
// export const SECURED_MINT_END_DATE = Number(process.env.REACT_APP_SECURED_MINT_END_DATE);
// export const RAFFLE_RESULTS_START_DATE = Number(process.env.REACT_APP_RAFFLE_RESULTS_START_DATE);
// export const RAFFLE_RESULTS_END_DATE = Number(process.env.REACT_APP_RAFFLE_RESULTS_END_DATE);
// export const PUBLIC_MINT_START_DATE = Number(process.env.REACT_APP_PUBLIC_MINT_START_DATE);
// export const PUBLIC_MINT_END_DATE = Number(process.env.REACT_APP_PUBLIC_MINT_END_DATE);
export const IS_CLAIM_TRAITS_OPEN = Number(process.env.REACT_APP_IS_CLAIM_TRAITS_OPEN);
export const IS_CLAIM_TRAITS_FINISHED = Number(process.env.REACT_APP_IS_CLAIM_TRAITS_FINISHED);

export const CLOUDFLARE_API_BASE_URL = process.env.REACT_APP_CLOUDFLARE_API_BASE_URL;
export const IMAGES_API_BASE_URL = process.env.REACT_APP_IMAGES_API_BASE_URL;
export const RANKING_AVATAR_CLIENT_API = process.env.REACT_APP_RANKING_CLIENT_API_URL;
