import { TTrait } from "@customTypes/trait";
import { TAvatar } from "@customTypes/avatar";

const drawBodiesRarityString = ["⚲🜍", "🜞☯", "⚲☯", "🜵☠🜀"];
const beardedMouthRarityString = ["⩌🜌☈🜁", "⩌🜌☈⩄", "⩌🜌☈ෆ", "⩌🝀🜬⩄", "⩌🝀🜬ෆ"];
const tattooDressesRarityString = ["🝩⚧P🜠", "🝩✫🜚", "🝩Ϟ🜚"];
const MAX_ORIGINAL_AVATAR_ID = 6900;

const extrasMap = {
    "🜼": "neck",
    "🜷": "mask",
    "🜘": "head"
}

export const freezeModalStyles = {
    overlay: {
        backgroundColor: "#3a3a3a8c",
        backdropFilter: "blur(1.3rem)",
    },
    content: {
        maxWidth: "43.75rem",
        width: "100%",
        minHeight: "29rem",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

enum ETraitVariant {
    DISABLED,
    SELECTED,
    ENABLED,
}

export const randomAvatarId = (): number => {
    return Math.floor(Math.random() * MAX_ORIGINAL_AVATAR_ID) + 1;
};

export const copyAvatar = (avatar: TAvatar): TAvatar => JSON.parse(JSON.stringify(avatar));

export const checkTrait = (trait: TTrait, selectedAvatar: TAvatar | null): { state: ETraitVariant; error?: string } => {
    if (!selectedAvatar) {
        return { state: ETraitVariant.DISABLED, error: "No avatar selected" };
    }

    if (isTraitDress(trait) && isTraitTattooDress(trait) && !doesTraitMatchesBody(selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "This dress cannot be used with the selected body" };
    }

    if (isTraitBody(trait) && !doesTraitMatchesDress(selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "This body cannot be used with the selected dress" };
    }

    if (isTraitDress(trait) && !doesTraitMatchesBodyStyle(trait, selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "This dress cannot be used with the selected body" };
    }

    if (isTraitBody(trait) && !doesTraitMatchesDressStyle(trait, selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "This body cannot be used with the selected dress" };
    }

    if (isTraitBeard(trait) && !doesTraitMatchesMouth(selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "Beards cannot be used with the selected mouth" };
    }

    if (isTraitMouth(trait) && !doesTraitMatchesBeard(trait, selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "This mouth cannot be used with a beard" };
    }

    if (isTraitMouth(trait) && cannotCombineWithMask(trait, selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "This mouth cannot be used with the selected mask" };
    }

    if (isTraitMask(trait) && cannotCombineWithMouth(trait, selectedAvatar)) {
        return { state: ETraitVariant.DISABLED, error: "This mask cannot be used with the selected mouth" };
    }

    if (isTraitOnAvatar(trait, selectedAvatar)) {
        return { state: ETraitVariant.SELECTED };
    }

    return { state: ETraitVariant.ENABLED };
};

export const checkDressTraitRule = (avatarCopy: TAvatar): boolean =>
    (avatarCopy.traits["body"].tokenId !== 0 && avatarCopy.traits["dress"].style !== avatarCopy.traits["body"].style) ||
    (drawBodiesRarityString.includes(avatarCopy.traits["body"].rarityString ?? "") &&
        tattooDressesRarityString.includes(avatarCopy.traits["dress"].rarityString ?? ""));

export const getParamsFromSelectedAvatar = (
    av: TAvatar
): string | string[][] | Record<string, string> | URLSearchParams | undefined => {
    const traitList = av.traits;
    const params = {};

    for (const [traitType, { tokenId }] of Object.entries(traitList)) {
        if (!tokenId) {
            continue;
        }

        params[traitType] = tokenId;
    }

    return params;
};

const isTraitOnAvatar = (trait: TTrait, selectedAvatar: TAvatar): boolean =>
    trait.id === selectedAvatar?.traits[trait.type].tokenId && trait.type === selectedAvatar?.traits[trait.type].type;

const isTraitDress = (trait: TTrait): boolean => ["dress"].includes(trait.type);

const isTraitBeard = (trait: TTrait): boolean => ["beard"].includes(trait.type);

const isTraitMouth = (trait: TTrait): boolean => ["mouth"].includes(trait.type);

const isTraitBody = (trait: TTrait): boolean => ["body"].includes(trait.type);

const isTraitMask = (trait: TTrait): boolean => ["extra"].includes(trait.type) && extrasMap[trait.rarityString?.substring(0, 1)] == "mask";

const doesTraitMatchesBodyStyle = (trait: TTrait, selectedAvatar: TAvatar): boolean => {
    if (!trait.style || selectedAvatar?.traits["body"].tokenId === 0) {
        return true;
    }

    return trait.style === selectedAvatar?.traits["body"].style;
};

const doesTraitMatchesDressStyle = (trait: TTrait, selectedAvatar: TAvatar): boolean => {
    if (!trait.style || selectedAvatar?.traits["dress"].tokenId === 0) {
        return true;
    }

    return trait.style === selectedAvatar?.traits["dress"].style;
};

const isTraitTattooDress = (trait: TTrait): boolean => tattooDressesRarityString.includes(trait.rarityString);

const doesTraitMatchesBody = (selectedAvatar: TAvatar): boolean => {
    if (selectedAvatar?.traits["body"].tokenId === 0) {
        return true;
    }

    return !drawBodiesRarityString.includes(selectedAvatar?.traits["body"].rarityString ?? "");
};

const doesTraitMatchesDress = (selectedAvatar: TAvatar): boolean => {
    if (selectedAvatar?.traits["dress"].tokenId === 0) {
        return true;
    }

    return !tattooDressesRarityString.includes(selectedAvatar?.traits["dress"].rarityString ?? "");
};

const doesTraitMatchesMouth = (selectedAvatar: TAvatar): boolean => {
    if (selectedAvatar?.traits["mouth"].tokenId === 0) {
        return true;
    }

    return !beardedMouthRarityString.includes(selectedAvatar?.traits["mouth"].rarityString ?? "");
};

const doesTraitMatchesBeard = (trait: TTrait, selectedAvatar: TAvatar): boolean => {
    if (selectedAvatar?.traits["beard"].tokenId === 0) {
        return true;
    }

    return !beardedMouthRarityString.includes(trait.rarityString ?? "");
};


const cannotCombineWithMouth = (trait: TTrait, selectedAvatar: TAvatar): boolean => {
    if (selectedAvatar?.traits["mouth"].tokenId === 0) {
        return false;
    }

    return (
        (trait.rarityString == "🜷🝀🜬🜚" && selectedAvatar?.traits["mouth"].rarityString == "⩌🝀🜬⩄") || 
        (trait.rarityString == "🜷🝀🜬☈" && selectedAvatar?.traits["mouth"].rarityString == "⩌🝀🜬ෆ")
    );
};

const cannotCombineWithMask = (trait: TTrait, selectedAvatar: TAvatar): boolean => {
    if (selectedAvatar?.traits["extra"].tokenId === 0) {
        return false;
    }

    return (
        (trait.rarityString == "⩌🝀🜬⩄" && selectedAvatar?.traits["extra"].rarityString != "🜷🝀🜬☈") || 
        (trait.rarityString == "⩌🝀🜬ෆ" && selectedAvatar?.traits["extra"].rarityString != "🜷🝀🜬🜚")
    );
};

