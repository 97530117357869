import { ReactElement } from "react";
import Container from "./components/Container";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeaderDesktop from "./components/Header/components/HeaderDesktop";
import HeaderMobile from "./components/Header/components/HeaderMobile";
import ScrollToTop from "./components/SrollToTop";

interface IAppLayout {
    children: React.ReactElement;
}

const AppLayout = ({ children }: IAppLayout): ReactElement => (
    <>
        <Header>
            <HeaderDesktop />
            <HeaderMobile />
        </Header>
        <Container>
            <ScrollToTop>{children}</ScrollToTop>
        </Container>
        <Footer />
    </>
);

export default AppLayout;
