import { ReactElement } from "react";
import { TMappedTratis } from "../../useMyCollection";
import TraitList from "../TraitList";

interface IAllTraits {
    mappedTraits: TMappedTratis;
}

const AllTraits = ({ mappedTraits }: IAllTraits): ReactElement => {
    const { crown, hair, eyes, mouth, face, body, dress, background, extra, beard } = mappedTraits;

    return (
        <>
            {extra.length > 0 && (
                <div data-testid="extra">
                    <p>POWERS</p>
                    <TraitList traits={extra} showStats={false} />
                </div>
            )}

            {crown.length > 0 && (
                <div data-testid="crown">
                    <p>CROWNS</p>
                    <TraitList traits={crown} showStats={false} />
                </div>
            )}

            {hair.length > 0 && (
                <div data-testid="hair">
                    <p>HAIRS</p>
                    <TraitList traits={hair} showStats={false} />
                </div>
            )}

            {eyes.length > 0 && (
                <div data-testid="eyes">
                    <p>EYES</p>
                    <TraitList traits={eyes} showStats={false} />
                </div>
            )}

            {mouth.length > 0 && (
                <div data-testid="mouth">
                    <p>MOUTHS</p>
                    <TraitList traits={mouth} showStats={false} />
                </div>
            )}
            {beard.length > 0 && (
                <div data-testid="beard">
                    <p>BEARDS</p>
                    <TraitList traits={beard} showStats={false} />
                </div>
            )}

            {face.length > 0 && (
                <div data-testid="face">
                    <p>FACES</p>
                    <TraitList traits={face} showStats={false} />
                </div>
            )}

            {dress.length > 0 && (
                <div data-testid="dress">
                    <p>DRESSES</p>
                    <TraitList traits={dress} showStats={false} />
                </div>
            )}

            {body.length > 0 && (
                <div data-testid="body">
                    <p>BODIES</p>
                    <TraitList traits={body} showStats={false} />
                </div>
            )}

            {background.length > 0 && (
                <div data-testid="background">
                    <p>BACKGROUNDS</p>
                    <TraitList traits={background} showStats={false} />
                </div>
            )}
        </>
    );
};

export default AllTraits;
