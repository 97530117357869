import { ReactElement } from "react";
import DefaultLoader from "@components/DefaultLoader";
import Row from "../Row";
import styles from "./Body.module.scss";

interface IBody {
    items: any[];
    selectedRow: string;
    checkedItems: any[];
    isLoading: boolean;
    notFoundMessage: string;
    handleSelectedRow: (id: string) => void;
    handleCheckedRow: (id: string) => void;
}

const Body = ({
    items,
    selectedRow,
    checkedItems,
    isLoading,
    notFoundMessage,
    handleSelectedRow,
    handleCheckedRow,
}: IBody): ReactElement => {
    const isChecked = (id: string) => checkedItems.some((item) => item.id === id);

    return (
        <tbody>
            {isLoading ? (
                <tr className={styles.fullRow}>
                    <td>
                        <DefaultLoader classname={styles.loader} spinner />
                    </td>
                </tr>
            ) : (
                <>
                    {items.length > 0 ? (
                        <>
                            {items.map((item) => (
                                <Row
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    source={item.image}
                                    checked={isChecked(item.id)}
                                    selectedRowId={selectedRow}
                                    handleChecked={() => handleCheckedRow(item.id)}
                                    handleSelectedRow={() => handleSelectedRow(item.id)}
                                />
                            ))}
                        </>
                    ) : (
                        <tr className={styles.fullRow}>
                            <td>
                                <p className={styles.notFound}>{notFoundMessage}</p>
                            </td>
                        </tr>
                    )}
                </>
            )}
        </tbody>
    );
};

export default Body;
