import { ReactElement } from "react";
import styles from "./BurnItem.module.scss";

interface IBurnItem {
    image: string;
    name: string;
}

const BurnItem = ({ image, name }: IBurnItem): ReactElement => {
    return (
        <div className={styles.item}>
            <img src={image} alt="" />
            <p>{name}</p>
        </div>
    );
};

export default BurnItem;
