import BurnContractABI from "@contracts/BurnContractABI";
import QKContractABI from "@contracts/QKContractABI";
import TratisContractABI from "@contracts/TraitsContractABI";
import {
    BURN_CONTRACT_ADDR,
    HEROINES_CONTRACT_ADDR,
    QK_CONTRACT_ADDR,
    T_BACKGROUND_CONTRACT_ADDR,
    T_BODY_CONTRACT_ADDR,
    T_CROWN_CONTRACT_ADDR,
    T_DRESS_CONTRACT_ADDR,
    T_EYES_CONTRACT_ADDR,
    T_FACE_CONTRACT_ADDR,
    T_HAIR_CONTRACT_ADDR,
    T_MOUTH_CONTRACT_ADDR,
} from "@constants/env";

export default [
    {
        contract: BURN_CONTRACT_ADDR as string,
        abi: BurnContractABI,
    },
    {
        contract: QK_CONTRACT_ADDR as string,
        abi: QKContractABI,
    },
    {
        contract: T_BODY_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: T_EYES_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: T_FACE_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: T_HAIR_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: T_CROWN_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: T_DRESS_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: T_MOUTH_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: T_BACKGROUND_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
    {
        contract: HEROINES_CONTRACT_ADDR as string,
        abi: TratisContractABI,
    },
];
