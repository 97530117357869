import { ReactElement } from "react";
import qkLogoGray from "@images/qk-logo-gray.png";
import styles from "./Loader.module.scss";

interface ILoader {
    className?: string;
    circleClassName?: string;
}

const Loader = ({ className, circleClassName }: ILoader): ReactElement => (
    <div className={`${styles.container} ${className}`}>
        <div className={`${styles.circle} ${circleClassName}`}>
            <img className={styles.innerLoaderImage} src={qkLogoGray} alt="Hackatao Loader" />
        </div>
    </div>
);

export default Loader;
