import { ReactElement } from "react";
import { ITraitRanking } from "@customTypes/trait";
import DefaultLoader from "@components/DefaultLoader";
import RankingTraitItem from "../RankingTraitItem";
import styles from "./RankingTraitsList.module.scss";

interface IRankingTraitsList {
    items: ITraitRanking[];
    selectedRow: string;
    isLoading: boolean;
    handleSelectedRow: (rarityString: string) => void;
}

const RankingTraitsList = ({ items, selectedRow, isLoading, handleSelectedRow }: IRankingTraitsList): ReactElement => (
    <>
        {isLoading ? (
            <tr className={styles.fullRow}>
                <td>
                    <DefaultLoader classname={styles.loader} spinner />
                </td>
            </tr>
        ) : (
            <>
                {items.length > 0 ? (
                    <>
                        {items.map(({ name, preview, rank, score, type, minted, rarityString }: ITraitRanking) => (
                            <RankingTraitItem
                                key={`${preview}_${score}_${rank}_trait`}
                                name={name}
                                preview={preview}
                                rank={rank}
                                score={score}
                                type={type}
                                minted={minted}
                                rarityString={rarityString}
                                selectedRow={selectedRow}
                                handleSelectedRow={() => handleSelectedRow(rarityString)}
                            />
                        ))}
                    </>
                ) : (
                    <tr className={styles.fullRow}>
                        <td>
                            <p className={styles.notFound}>No traits found</p>
                        </td>
                    </tr>
                )}
            </>
        )}
    </>
);

export default RankingTraitsList;
