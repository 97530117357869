import { ReactElement } from "react";
import { ETHERSCAN_URL } from "@constants/env";
import DefaultLoader from "@components/DefaultLoader";
import parentStyles from "../../RemintTransactionMessages.module.scss";

interface IInProgress {
    txId: string | null;
}

const InProgress = ({ txId }: IInProgress): ReactElement => (
    <div className={parentStyles.remintContainer}>
        <p className={parentStyles.gold}>CROWNING</p>
        <p className={parentStyles.lastParagraph}>
            WE ARE CONFERRING INVESTITURE ON YOU; CULTIVATE PATIENCE WORTHY OF A CRYPTOSOVEREIGN. <br />
            CHECK YOUR{" "}
            <a href={`${ETHERSCAN_URL}/tx/${txId}`} rel="noopener noreferrer" target="_blank">
                TRANSACTION
            </a>
        </p>
        <DefaultLoader spinner />
    </div>
);

export default InProgress;
