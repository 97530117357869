import { ReactElement, ReactNode } from "react";
import { EActiveTab } from "../RankingTabs/RankingTabs";
import arrow from "@images/carousel-chevron.png";
import styles from "./RankingTable.module.scss";

interface IRankingTable {
    children: ReactNode;
    activeTab: EActiveTab;
    traitType: string;
    actualLimit: number;
    actualPage: number;
    totalItems: number;
    handleNextPage: () => void;
    handlePrevPage: () => void;
    handleTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleItemsLimitChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const types = ["powers", "crown", "hair", "eyes", "mouth", "beard", "face", "dress", "body", "background"];

const RankingTable = ({
    children,
    activeTab,
    traitType,
    actualLimit,
    actualPage,
    totalItems,
    handleNextPage,
    handlePrevPage,
    handleTypeChange,
    handleItemsLimitChange,
}: IRankingTable): ReactElement => {
    const canGoNext = actualPage + 1 < Math.ceil(totalItems / actualLimit);
    const canGoPrev = actualPage + 1 > 1;

    return (
        <table className={styles.container} role="tabpanel">
            <thead>
                <tr className={styles.tableHeader}>
                    {activeTab === EActiveTab.TRAITS ? (
                        <th className={styles.types}>
                            <select value={traitType} onChange={handleTypeChange}>
                                {types.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </th>
                    ) : (
                        <th className={styles.avid}>Q+K ID</th>
                    )}
                    <th className={styles.preview}>PREVIEW</th>
                    <th className={styles.rank}>RANK</th>
                    <th className={styles.score}>SCORE</th>
                    <th className={styles.viewop}>VIEW IN OPENSEA</th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
            <tfoot>
                <tr>
                    <td className={styles.pagerContainer}>
                        <button
                            className={styles.prevBtn}
                            onClick={canGoPrev ? handlePrevPage : undefined}
                            aria-label="Previous page"
                            disabled={!canGoPrev}
                        >
                            <img
                                className={`${!canGoPrev ? styles.hidden : ""}`}
                                src={arrow}
                                alt="Search by avatar id"
                            />
                        </button>
                        <span>{`${actualPage + 1} / ${Math.ceil(totalItems / actualLimit)}`}</span>
                        <button
                            className={styles.nextBtn}
                            onClick={canGoNext ? handleNextPage : undefined}
                            aria-label="Next page"
                            disabled={!canGoNext}
                        >
                            <img
                                className={`${!canGoNext ? styles.hidden : ""}`}
                                src={arrow}
                                alt="Search by avatar id"
                            />
                        </button>
                        <select value={actualLimit} onChange={handleItemsLimitChange}>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                        </select>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default RankingTable;
