import { LEARN_MORE } from "@constants/routes";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styles from "./Title.module.scss";

const Title = (): ReactElement => {
    return (
        <>
            <p className={styles.title}>
                <span>BURN YOUR SACRIFICES, DEAR ROYALS.</span> THE CROWN REQUIRES IT. IN THIS AGE,
            </p>
            <p className={styles.title}>HIERARCHY RESHAPES AND SUBVERTS. NO ESCAPE. RITUAL UNFOLDS AND POINTS</p>
            <p className={styles.titleBrown}>
                ARE AWARDED.<span> THE ROYAL FLAME SPREADS.</span>
            </p>
            <p className={styles.learnmore}>
                <Link to={LEARN_MORE}>LEARN MORE</Link>
            </p>
        </>
    );
};

export default Title;
