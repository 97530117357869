import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { ROYAL_LIST } from "@constants/routes";
import ConfirmationCheck from "@images/confirmation-white-check.png";
import styles from "./Success.module.scss";

const Success = (): ReactElement => (
    <div className={styles.container}>
        <p>
            <span>CONGRATZ</span> YOUR EFFIGY GAS BEEN SUCCESSFULLY BURNT.
        </p>
        <p className={styles.last}>CLICK HERE TO GAZE AT THE BURNING OF ROYAL ARSONISTS!</p>

        <img className={styles.check} src={ConfirmationCheck} alt="confirmation check" />

        <Link className={styles.royalList} to={ROYAL_LIST}>
            ROYAL LIST
        </Link>

        <p className={styles.disclaimer}>RANKINGS MAY CHANGE AS NEW TRAITS ARE BURNED BY COLLECTORS</p>
    </div>
);

export default Success;
