import { ReactElement } from "react";
import loader from "@images/loader.png";
import loaderBlank from "@images/loader_blank.png";
import qk_logo from "@images/qk-logo.png";
import qk_logo_white from "@images/QK_MARCHIO_White.png";
import styles from "./DefaultLoader.module.scss";

interface IDefaultLoader {
    spinner?: boolean;
    classname?: string;
    invert?: boolean;
}

const DefaultLoader = ({ spinner = false, classname, invert = false }: IDefaultLoader): ReactElement => (
    <div className={`${styles.container} ${classname}`}>
        <img className={styles.outer} src={spinner ? loader : loaderBlank} alt="spinner" />
        <img
            className={invert ? styles.inner__invert : styles.inner}
            src={invert ? qk_logo_white : qk_logo}
            alt="queen and kings logo"
        />
    </div>
);

export default DefaultLoader;
