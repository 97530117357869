import { ReactElement, ReactNode } from "react";
import Body from "./components/Body";
import Foot from "./components/Foot";
import Head from "./components/Head";
import Tabs from "./components/Tabs";
import styles from "./Table.module.scss";

interface ITable {
    tabs: ReactNode;
    header: ReactNode;
    foot: ReactNode;
    body: ReactNode;
}

const Table = ({ tabs, header, body, foot }: ITable): ReactElement => {
    return (
        <>
            {tabs}
            <table className={styles.table} role="tabpanel">
                {header}
                {body}
                {foot}
            </table>
        </>
    );
};

Table.Tabs = Tabs;
Table.Head = Head;
Table.Foot = Foot;
Table.Body = Body;

export default Table;
