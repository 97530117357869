import k_letter from "@images/not-found/K.png";
import q_letter from "@images/not-found/Q.png";
import four_number from "@images/not-found/4.png";
import styles from "./NotFound.module.scss";

const NotFound = (): JSX.Element => (
    <div className={styles.container}>
        <div className={styles.code}>
            <img src={four_number} alt="four number" />
            <img src={q_letter} alt="q from queen" />
            <img src={four_number} alt="four number" />
        </div>
        <p className={styles.message}>
            YOU HAVE BEEN DETHRONED. <span>TRY AGAIN.</span>
        </p>
        <div className={styles.decoration}>
            <img src={k_letter} alt="k from king" />
        </div>
    </div>
);

export default NotFound;
