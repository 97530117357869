import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { OPENSEA_COLLECTION_URL } from "@constants/env";
import { HACK_THE_ROYALS } from "@constants/routes";
import { getRandomAvatars } from "@utils/scripts/Avatars";
import { useWeb3Context } from "@nftstudios/web3-provider";
import PROVIDER from "@constants/web3.config";
import Button, { Variant } from "@elements/Button";
import Loader from "@components/Loader";
import styles from "./AvatarGrid.module.scss";

const AvatarGrid = (): JSX.Element => {
    const { isInitialized, walletAddress, connect } = useWeb3Context();
    const isWalletConnected = isInitialized && walletAddress;
    const [avatars, setAvatars] = useState<string[]>([]);
    const [loading, setLoading] = useState(false); //TODO: This should be removed when CMS integration is done.

    useEffect(() => {
        setAvatars(getRandomAvatars(7));
    }, [isWalletConnected]);

    const handleConnect = () => {
        if (isWalletConnected) {
            return;
        }
        connect(PROVIDER);
    };

    return (
        <section className={styles.container}>
            {loading ? (
                <div className={styles.loader}>
                    <Loader />
                </div>
            ) : (
                <div className={styles.avatars}>
                    <div>
                        {isWalletConnected ? (
                            <Link to={HACK_THE_ROYALS} data-testid="home-hackTheRoyals-link">
                                HACK THE ROYALS
                            </Link>
                        ) : (
                            <Button
                                variant={Variant.SecondaryHeader}
                                onClick={handleConnect}
                                data-testid="home-hackTheRoyals-button"
                            >
                                HACK THE ROYALS
                            </Button>
                        )}
                    </div>

                    {avatars.map((avatar) => (
                        <div key={avatar} className={styles.avatarContainer}>
                            <img src={avatar} alt="Avatar example" role="presentation" />
                        </div>
                    ))}

                    <div>
                        <a
                            className={styles.seemore}
                            href={OPENSEA_COLLECTION_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                            data-testid="see-more-link"
                        >
                            SEE MORE
                        </a>
                    </div>
                </div>
            )}
        </section>
    );
};

export default AvatarGrid;
