import { ReactElement, useEffect, useState } from "react";
import { useSimulatorContext } from "@context/simulatorContext";
import { TAvatar } from "@customTypes/avatar";
import useSimulator from "@pages/Simulator/useSimulator";
import styles from "./SimulatorTraitOwnerAvatarPreview.module.scss";

const SimulatorTraitOwnerAvatarPreview = (): ReactElement => {
    const { getAvatarByOriginalTokenId } = useSimulator();
    const { traitAvatarPreview } = useSimulatorContext();
    const [avatarPreview, setAvatarPreview] = useState<TAvatar | null>();

    useEffect(() => {
        const getPreviewAvatar = async (traitId: number) => {
            const result = await getAvatarByOriginalTokenId(traitId);
            setAvatarPreview(result);
        };

        let suscribed = false;

        if (!suscribed && traitAvatarPreview) {
            getPreviewAvatar(traitAvatarPreview.avatarId);
        }

        return () => {
            suscribed = true;
        };
    }, [traitAvatarPreview?.id]);

    return (
        <div className={styles.container}>
            {avatarPreview && (
                <div className={styles.preview}>
                    <p>Attached To:</p>
                    <img src={avatarPreview?.image} alt="" />
                </div>
            )}
        </div>
    );
};

export default SimulatorTraitOwnerAvatarPreview;
