import { useMediaQuery } from "react-responsive";
import { largeImages, mobileImages } from "@pages/Home/data";
import BackgroundSlider from "react-background-slider";
import TitleMain from "../TitleMain";
import styles from "./AvatarMain.module.scss";

const AvatarMain = (): JSX.Element => {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

    return (
        <div className={styles.mainCarousel}>
            <BackgroundSlider images={isTabletOrMobile ? mobileImages : largeImages} duration={10} transition={1} />
            <TitleMain />
        </div>
    );
};

export default AvatarMain;
