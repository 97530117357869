import Web3 from "web3";
import { useEffect, useState } from "react";
import { AbiItem } from "web3-utils";

type TContractData = {
    contract: string;
    abi: any;
};

type TInstance = Web3["givenProvider"];

const useContractsInstancer = (web3: Web3 | undefined, contractsData: TContractData[]): TInstance[] => {
    const [instances, setInstances] = useState<TInstance[]>([]);

    useEffect(() => {
        if (!web3 || contractsData.length === 0) {
            return;
        }

        contractsData.forEach((instance: TContractData) => {
            if (instance.contract && instance.abi) {
                const newInstance = new web3.eth.Contract(instance.abi as AbiItem[], instance.contract);
                setInstances((prevState: TInstance[]) => [...prevState, newInstance]);
            }
        });
    }, [web3, contractsData]);

    return instances;
};

export default useContractsInstancer;
