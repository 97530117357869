import { ReactElement } from "react";
import { TAvatar } from "@customTypes/avatar";
import { sortTraits } from "@utils/scripts/AvatarUtils";
import { FREEZE_CONTRACT_ADDR } from "@constants/env";
import ConfirmationCheck from "@images/confirmation-check.png";
import AvatarDetailCarousel from "@components/AvatarDetailCarousel";
import BottomBanner from "@components/BottomBanner";
import DefaultLoader from "@components/DefaultLoader";
import parentStyles from "../../FreezeTransactionMessages.module.scss";
import styles from "./Success.module.scss";

interface ISuccess {
    isCopy: boolean;
    avatar: TAvatar | null;
}

const Success = ({ isCopy, avatar }: ISuccess): ReactElement => (
    <div className={parentStyles.freezeContainer}>
        <p>
            <span className={parentStyles.gold}>CONGRATZ!</span> YOU ARE FROZEN.
        </p>
        {!isCopy ? (
            <>
                <p className={parentStyles.paragraph}>WELL DONE, YOU NOW HAVE COMMERCIAL RIGHTS ON YOUR AVATAR!</p>
                <p className={parentStyles.lastParagraph}>
                    WE ARE CURIOUS TO SEE WHAT YOU WILL DO WITH YOUR FROZEN Q+K.
                </p>
            </>
        ) : (
            <p className={parentStyles.lastParagraph}>REMEMBER THAT THIS AVATAR CAN NOT GRANT YOU COMMERCIAL RIGHTS.</p>
        )}
        <img className={styles.check} src={ConfirmationCheck} alt="confirmation check" />
        <p className={parentStyles.paragraph}>SOME CHANGES MAY TAKE A FEW MINUTES TO SHOW UP.</p>

        {avatar ? (
            <div className={styles.detailContainer}>
                <div className={styles.carousel}>
                    {avatar && (
                        <AvatarDetailCarousel
                            handleActiveAvatarIndex={undefined}
                            avatarsList={[avatar]}
                            gutter={0}
                            numberOfCards={1}
                        />
                    )}
                </div>
                <div className={styles.traitsContainer}>
                    {Object.keys(sortTraits(avatar.traits)).map((key) => (
                        <div className={styles.trait} key={key}>
                            <p>
                                <span>{avatar.traits[key].type === "extra" ? "powers" : avatar.traits[key].type}</span>
                                <span>{avatar.traits[key].rarity > 0 ? `${avatar.traits[key].rarity}%` : "-"}</span>
                            </p>
                        </div>
                    ))}
                </div>
                <div className={styles.btnContainer}>
                    <a
                        href={`https://opensea.io/assets/${FREEZE_CONTRACT_ADDR}/${avatar.originalTokenId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="frozen-avatar-opensea-link"
                    >
                        VIEW IN OPENSEA
                    </a>
                </div>
            </div>
        ) : (
            <DefaultLoader classname={parentStyles.lastParagraph} spinner />
        )}

        <BottomBanner className={styles.onlyDesktop} />
    </div>
);

export default Success;
