import { Link } from "react-router-dom";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { HACK_THE_ROYALS, HOME } from "@constants/routes";
import Button, { Variant } from "@elements/Button";
import PROVIDER from "@constants/web3.config";
import hackatao from "@images/how-it-works-hackatao.png";
import number from "@images/how-it-works-6900.png";
import button_style from "@elements/Button/Button.module.scss";
import styles from "./HowItWorks.module.scss";

const HowItWorks = (): JSX.Element => {
    const { isInitialized, connect, walletAddress } = useWeb3Context();

    const isWalletConnected = isInitialized && walletAddress;

    const handleConnect = () => {
        if (isWalletConnected) {
            return;
        }

        connect(PROVIDER);
    };

    return (
        <div className={styles.container}>
            <h1>HOW IT WORKS</h1>
            <section>
                <img src={hackatao} alt="hackatao logo" />
                <article>
                    <h2>Q+K</h2>
                    <p>
                        IS AN INNOVATIVE PROJECT BY HACKATAO AND DEVELOPED IN COLLABORATION WITH NFT STUDIOS. Q+K IS
                        SUPPORTED BY SOTHEBY'S AND THE HACKATAO COMMUNITY: AVATAR TRAITS ARE EXCHANGEABLE. THE PROJECT
                        INTENDS TO PUSH THE LIMITS OF PFP, TO GENERATE A TRUE ARTISTIC EXPERIENCE, AN ADVENTURE SHARED
                        WITH THE COMMUNITY IN LINE WITH THE 'SUBVERSIVE', CREATIVE VALUES OF THE CRYPTO WORLD.
                    </p>
                </article>
            </section>
            <section>
                <img src={number} alt="6900 logo" />
                <article>
                    <h2>6900</h2>
                    <p>
                        A TOTAL OF 6900 Q+KS WERE MINTED DURING TWO DIFFERENT PHASES IN TIME. A PRE-RELEASE ON DECEMBER
                        22ND, 2021: WITH THE RELEASE OF 2300 Q+KS AND A SECOND AND LAST ONE ON FEBRUARY THE 22ND, 2022:
                        WITH THE REMAINING 4.600. 135 RANDOM Q+KS ARE RESERVED FOR THE HACKATAO, NFT STUDIOS AND
                        SOTHEBY’S TEAMS. SINCE THE INTRODUCTION OF THE BURNING TOOL IN SEPTEMBER 2023, THE NUMBER OF
                        AVATARS NOW VARIES AS COLLECTORS ARE ABLE TO BURN TRAITS AND BASES, THEREFORE INCREASING THE
                        VALUE OF THE PROJECT WHILE REDUCING THE SCALE.
                    </p>
                </article>
            </section>
            <section>
                <Link to={HOME} className={button_style.primaryHeader} data-testid="howItWorks-mint-link">
                    MINT AVATAR
                </Link>
                <article>
                    <h2>MINTING</h2>
                    <p>
                        THE FIRST MINTING OF Q+KS WAS ALGORITHMICALLY GENERATED, ENTIRELY RANDOMIZED. NO RARITY
                        ADVANTAGE WAS GRANTED INITIALLY. IF COLLECTORS HAD SECURED A SPOT ON THE WHITE LIST, THEY COULD
                        MINT A NUMBER OF Q+KS. TO GET THE INDIVIDUAL NFTS OF THESE TRAITS, THE COLLECTOR MUST MINT THEM
                        IN ORDER FOR THE TOKENS TO BE CREATED, ON THE DEDICATED SECTION OF THIS WEBSITE.
                    </p>
                </article>
            </section>
            <section>
                {isWalletConnected ? (
                    <Link
                        to={HACK_THE_ROYALS}
                        className={button_style.secondaryHeader}
                        data-testid="howItWorks-hackTheRoyals-link"
                    >
                        HACK THE ROYALS
                    </Link>
                ) : (
                    <Button
                        variant={Variant.SecondaryHeader}
                        onClick={handleConnect}
                        data-testid="howItWorks-hackTheRoyals-btn"
                    >
                        HACK THE ROYALS
                    </Button>
                )}
                <article>
                    <h2>HACK THE ROYALS</h2>
                    <p>
                        BY CLICKING ON THE HACK THE ROYALS OPTION, YOU CAN DISASSEMBLE AND REASSEMBLE YOUR AVATAR WITH
                        THE TRAITS THAT YOU OWN. YOU CAN DETACH THE TRAITS FROM THE AVATAR THAT YOU OWN AND MAKE NEW USE
                        OF THEM, OR SEARCH THE OPENSEA MARKETPLACE FOR MORE TRAITS TO COLLECT.
                    </p>
                </article>
            </section>
        </div>
    );
};

export default HowItWorks;
