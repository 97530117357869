import QuantitySelector from "@components/QuantitySelector";
import TechnicalError from "@components/TechnicalError";
import { WHITELIST } from "@constants/apiEndpoints";
import { ETransactionStatus } from "@constants/enums";
import { CLOUDFLARE_API_BASE_URL, IS_CLAIM_TRAITS_OPEN } from "@constants/env";
import PROVIDER from "@constants/web3.config";
import { TTrait } from "@customTypes/trait";
import Button, { Variant } from "@elements/Button";
import useAvatarContract from "@hooks/contracts/useAvatarContract";
import useGetAvatarsFromApi from "@hooks/request/useGetAvatarFromAPI";
import claimTraitRight from "@images/AVATAR-SEASON-6-x-GIF-DX.gif";
import claimTraitLeft from "@images/AVATAR-SEASON-6-x-GIF-SX.gif";
import { useWeb3Context } from "@nftstudios/web3-provider";
import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./ClaimTrait.module.scss";
import ClaimTraitInProgress from "./components/ClaimTraitInProgress";
import ClaimTraitSuccess from "./components/ClaimTraitSuccess";
import ClaimTraitWaitingConfirmation from "./components/ClaimTraitWaitingConfirmation";
import useMessageHelper, { messages } from "./useMessagesHelper";

const isClaimedTraitsOpen = (): boolean => {
    return Math.floor(Date.now() / 1000) >= IS_CLAIM_TRAITS_OPEN;
};

const ClaimTrait = (): JSX.Element => {
    const { walletAddress, connect, web3, isInitialized } = useWeb3Context();
    const isWalletConnected = isInitialized && walletAddress;
    const { status, txId, tokenIdList, traitAddresses, claimTrait, getClaimPrice } = useAvatarContract(
        web3,
        walletAddress
    );
    const { getTraitsByTypesAndIds, isLoadingClaimedTraits } = useGetAvatarsFromApi(walletAddress);
    const [canMint, setCanMint] = useState(false);
    const [messagesStatus, setMessagesStatus] = useState(messages.NORMAL_MESSAGE);
    const [claimedTraits, setClaimedTraits] = useState<TTrait[]>([]);
    const { getMessages } = useMessageHelper();
    const [traitsQuantity, setTraitsQuantity] = useState(0);
    const [maxTraitsQuantity, setMaxTraitsQuantity] = useState(0);
    const [claimPriceTimeout, setClaimPriceTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if (!isWalletConnected) {
            setCanMint(false);
            setMessagesStatus(messages.CONNECT_WALLET);
            return;
        }

        const checkCanMint = async () => {
            setMessagesStatus(messages.NORMAL_MESSAGE);

            try {
                const { enabled, alreadyClaimed, claimable } = (
                    await axios.get(`${CLOUDFLARE_API_BASE_URL}${WHITELIST}`.replace(":address", walletAddress))
                ).data;
                if (isClaimedTraitsOpen() && alreadyClaimed) {
                    setCanMint(false);
                    setMessagesStatus(messages.CLOSED);
                } else if (isClaimedTraitsOpen() && !enabled) {
                    setCanMint(false);
                    setMessagesStatus(messages.NOT_WINNER);
                } else if (isClaimedTraitsOpen() && enabled) {
                    setCanMint(true);
                    setTraitsQuantity(claimable);
                    setMaxTraitsQuantity(claimable);
                    setMessagesStatus(messages.NORMAL_MESSAGE);
                }
            } catch (error) {
                console.log(error);
                setCanMint(false);
            }
        };

        if (isWalletConnected && walletAddress) {
            checkCanMint();
        }

        return () => setCanMint(false);
    }, [walletAddress, isWalletConnected]);

    useEffect(() => {
        if (!walletAddress) return;

        clearTimeout(claimPriceTimeout);

        const tmp = setTimeout(() => {
            Promise.all([getClaimPrice(traitsQuantity), web3?.eth.getBalance(walletAddress)]).then(
                ([price, _balance]) => {
                    if (!price) {
                        setCanMint(false);
                        return;
                    }

                    const lowBalance = web3?.utils.toBN(price).gt(web3?.utils.toBN(_balance || 0)) || false;
                    setCanMint(!lowBalance);
                }
            );
        }, 500);

        setClaimPriceTimeout(tmp);
    }, [traitsQuantity]);

    useEffect(() => {
        if (tokenIdList && tokenIdList.length > 0 && traitAddresses && traitAddresses.length > 0) {
            getTraitsByTypesAndIds(traitAddresses, tokenIdList).then((traitList) => setClaimedTraits(traitList));
        }
    }, [tokenIdList, traitAddresses]);

    const handleClaim = async () => {
        if (!isWalletConnected) {
            await connect(PROVIDER);
        }

        claimTrait(traitsQuantity);
    };

    const handleConnect = async () => {
        await connect(PROVIDER);
    };

    const addTraitsQuantity = () => {
        setTraitsQuantity(traitsQuantity + 1 >= maxTraitsQuantity ? maxTraitsQuantity : traitsQuantity + 1);
    };

    const subtractTraitsQuantity = () => {
        setTraitsQuantity(traitsQuantity - 1 == 0 ? traitsQuantity : traitsQuantity - 1);
    };

    if (status === ETransactionStatus.WAITING_CONFIRMATION) {
        return <ClaimTraitWaitingConfirmation />;
    }

    if (status === ETransactionStatus.IN_PROGRESS) {
        return (
            <ClaimTraitInProgress
                txId={txId ?? ""}
                loadingText={
                    "THE PROPHENCY CALLS FOR THE ROYAL REIGN TO GROW! IT IS THE DESTINY OF THE SOVEREIGNS TO CONTINUE TO BRING BALANCE TO THE DIGITAL REALM."
                }
            />
        );
    }

    if (status === ETransactionStatus.NO_WHITELIST) {
        return (
            <TechnicalError>
                <p>THE CONNECTED WALLET IS NOT IN THE ALLOWLIST</p>
            </TechnicalError>
        );
    }

    if (status === ETransactionStatus.ERROR) {
        return (
            <TechnicalError>
                <>
                    <p className={styles.technicalError}>TECHNICAL ERROR</p>
                    <p>SOMETHING WENT WRONG! BUT NEVER SURRENDER!</p>
                    <p>EVERYONE HAS THE SAME RIGHTS!</p>
                </>
            </TechnicalError>
        );
    }

    if (status === ETransactionStatus.SUCCESS) {
        return <ClaimTraitSuccess claimedTraits={claimedTraits} isLoadingClaimedTraits={isLoadingClaimedTraits} />;
    }

    return (
        <section className={styles.container}>
            {getMessages(messagesStatus)}

            <div className={styles.mintContainer}>
                <img src={claimTraitLeft} alt="" />
                {isWalletConnected ? (
                    <Button
                        className={`${styles.btnPrimary} ${!canMint ? styles.disabled : ""}`}
                        variant={Variant.PrimaryHeader}
                        onClick={handleClaim}
                        disabled={!canMint}
                        data-testid="claim-trait-button"
                    >
                        CLAIM TRAITS
                    </Button>
                ) : (
                    <Button
                        className={styles.btnPrimary}
                        variant={Variant.PrimaryHeader}
                        onClick={handleConnect}
                        data-testid="mint-connect-wallet-button"
                    >
                        CONNECT WALLET
                    </Button>
                )}
                <img src={claimTraitRight} alt="" />
            </div>

            {isWalletConnected && (
                <div className={styles.quantityContainer}>
                    <QuantitySelector
                        addQuantity={addTraitsQuantity}
                        quantity={traitsQuantity}
                        subtractQuantity={subtractTraitsQuantity}
                        disabled={traitsQuantity == 0}
                        key="quantity-selector"
                    />
                </div>
            )}
        </section>
    );
};

export default ClaimTrait;
