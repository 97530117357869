import { ReactElement } from "react";
import { TAvatarTraits } from "@customTypes/avatar";
import { sortTraits } from "@utils/scripts/AvatarUtils";
import styles from "./Rarities.module.scss";

interface IRarities {
    traits: TAvatarTraits | undefined;
}

const Rarities = ({ traits }: IRarities): ReactElement => (
    <div className={styles.container}>
        {traits &&
            Object.keys(sortTraits(traits)).map((key) => (
                <div className={styles.rarity} key={`${traits[key].type}-${traits[key].tokenid}`}>
                    <p>
                        <span>{traits[key].type === "extra" ? "powers" : traits[key].type}</span>
                        <span>{traits[key].rarity > 0 ? `${traits[key].rarity}%` : "-"}</span>
                    </p>
                </div>
            ))}
    </div>
);

export default Rarities;
