import { IAsset } from "@customTypes/burn/asset";
import { ITraitApiResponse } from "@customTypes/trait";

export const traitApiResponseToAsset = ({ name, type, tokenId, image }: ITraitApiResponse): IAsset => {
    return {
        id: `${tokenId}-${type}`,
        name,
        image,
        type: type,
    };
};
