import { ReactElement } from "react";
import Button from "@elements/Button";
import styles from "./Caption.module.scss";

interface ICaption {
    handleClick: () => void;
    text: string;
    isLoading: boolean;
}

const Caption = ({ handleClick, text, isLoading }: ICaption): ReactElement => (
    <div className={styles.container}>
        <span>{text}</span>
        <Button className={styles.button} onClick={isLoading ? undefined : handleClick}>
            Select
        </Button>
    </div>
);

export default Caption;
