import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HOME, MY_COLLECTION, RANKING } from "@constants/routes";
import { useWeb3Context } from "@nftstudios/web3-provider";
import PROVIDER from "@constants/web3.config";
import Button from "@elements/Button";
import Navbar from "../Navbar";
import logo from "@images/logo.png";
import bars from "@images/hamburger-menu-bars.png";
import close from "@images/hamburger-menu-close.png";
import styles from "./HeaderMobile.module.scss";

const HeaderMobile = (): JSX.Element => {
    const { pathname } = useLocation();
    const { walletAddress, isInitialized, connect } = useWeb3Context();
    const isWalletConnected = isInitialized && walletAddress;
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        setToggle(false);
    }, [pathname]);

    const handleConnect = () => {
        if (isWalletConnected) {
            return;
        }
        connect(PROVIDER);
    };

    return (
        <div className={styles.container}>
            <div className={styles.imagesButtonContainer}>
                <button onClick={() => setToggle(!toggle)} data-testid="toggle-button">
                    <img src={toggle ? close : bars} alt="Open and close mobile menu" />
                </button>

                <Link to={HOME}>
                    <img src={logo} alt="logo" />
                </Link>

                <div className={styles.linkContainer}>
                    {isWalletConnected ? (
                        <Link to={MY_COLLECTION} data-testid="my-collection-link">
                            MY COLLECTION
                        </Link>
                    ) : (
                        <Button onClick={handleConnect} data-testid="connect-wallet-button">
                            CONNECT WALLET
                        </Button>
                    )}
                    <Link to={RANKING} data-testid="header-hackTheRoyals-link">
                        SUBVERSIVE HIERARCHY
                    </Link>
                </div>
            </div>
            {toggle && <Navbar />}
        </div>
    );
};

export default HeaderMobile;
