export enum ETabType {
    AVATARS = "AVATARS",
    TRAITS = "TRAITS",
    HEROINES = "HEROINES",
}

export enum EBurnStages {
    CONNECT = "CONNECT",
    SELECTION = "SELECTION",
    CONFIRMATION = "CONFIRMATION",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS",
    WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
    ERROR = "ERROR",
}
