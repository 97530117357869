import { TAvatarApiResponse } from "@customTypes/avatar";
import { IAsset } from "@customTypes/burn/asset";

export const avatarApiResponseToAsset = ({ name, tokenId, ipfsHash }: TAvatarApiResponse): IAsset => {
    return {
        id: String(tokenId),
        name,
        image: `https://queenskings.mypinata.cloud/ipfs/${ipfsHash}`,
        type: "avatar",
    };
};
