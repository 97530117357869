import { ReactElement } from "react";
import DefaultLoader from "@components/DefaultLoader";
import parentStyles from "../../RemintTransactionMessages.module.scss";

const WaitingConfirmation = (): ReactElement => (
    <div className={parentStyles.remintContainer}>
        <p className={parentStyles.gold}>WAITING CONFIRMATION</p>
        <p className={parentStyles.lastParagraph}>PLEASE CONFIRM THE TRANSACTION FROM YOUR WALLET</p>
        <DefaultLoader />
    </div>
);

export default WaitingConfirmation;
