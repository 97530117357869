import { useEffect, useRef } from "react";

type TUsePrev = string | number | undefined;

const usePrevious = (value: TUsePrev): TUsePrev => {
    const ref = useRef<TUsePrev>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export default usePrevious;
