import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = (): JSX.Element => (
    <div className={styles.container}>
        <h1>PRIVACY POLICY</h1>

        <section>
            <p>
                The Platform (“we” or “us”), accessible from https://queenskings.hackatao.com/, is committed to
                protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding
                the Personal Data (as defined below) we collect from users of our website, located at
                https://queenskings.hackatao.com/, (the "Site") and online services ("Services"). If you see an
                undefined term in this Privacy Policy, it has the same definition as in our Terms of Service (link).
            </p>
            <p>
                We would like to clarify that the Platform does not generally retain Users' data but, where personal
                information of our Users reaches us, they will be treated in the manner illustrated below.
            </p>

            <ol className={styles.decimalList}>
                <li>
                    <p>
                        <span>Questions, Contacting Company, Reporting Violations.</span> If you have any questions or
                        concerns or complaints about our Privacy Policy or our data collection or processing practices,
                        or if you want to report any security violations to us, please contact us at the following
                        e-mail address: QUEENSKINGS@HACKATAO.COM
                    </p>
                </li>
                <li>
                    <p>
                        <span>User Consent.</span> By submitting Personal Data through our Site or Services, you agree
                        to the terms of this Privacy Policy and you expressly consent to the collection, use and
                        disclosure of your Personal Data in accordance with this Privacy Policy. For conducting
                        cryptocurrency transactions, we use third-party electronic wallet extensions such as (but not
                        limited to) MetaMask; your interactions with MetaMask and/or any third-party electronic wallet
                        extensions are governed by the applicable privacy policies. In the case of MetaMask, its privacy
                        policy is available at https://metamask.io/privacy.html.
                    </p>
                </li>
                <li>
                    <p>
                        <span>A Note About Children.</span> We do not intentionally gather Personal Data from visitors
                        who are under the age of 13. If a child under 13 submits Personal Data to Company and we learn
                        that the Personal Data is the information of a child under 13, we will attempt to delete the
                        information as soon as possible. If you believe that we might have any Personal Data from a
                        child under 13, please contact us at the address indicated in Paragraph 1.
                    </p>
                </li>
                <li>
                    <p>
                        <span>A Note to Users Outside of the United States.</span> If you are a non U.S. user of the
                        Site, by visiting the Site and providing us with data, you acknowledge and agree that your
                        Personal Data may be processed for the purposes identified in the Privacy Policy. In addition,
                        your Personal Data may be processed in the country in which it was collected and in other
                        countries, including the United States, where laws regarding processing of Personal Data may be
                        less stringent than the laws in your country. By providing your data, you consent to such
                        transfer.
                    </p>
                </li>
                <li>
                    <p>
                        <span>Types of Data We Collect.</span> "Personal Data" means data that allows someone to
                        identify or contact you, including, for example, your name, e-mail address, as well as any other
                        non-public information about you that is associated with or linked to any of the foregoing data.
                        "Anonymous Data" means data, including aggregated and de-identified data, that is not associated
                        with or linked to your Personal Data; Anonymous Data does not, by itself, permit the
                        identification of individual persons. We collect Personal Data and Anonymous Data, as described
                        below.
                    </p>
                </li>
            </ol>
        </section>

        <section>
            <p>
                <span>Information You Provide to Us.</span> If you provide us feedback or contact us via e-mail, we will
                collect your name and e-mail address, as well as any other content included in the e-mail, in order to
                send you a reply.
            </p>
            <p>
                We also collect other types of Personal Data that you provide to us voluntarily, such as your operating
                system and version, product registration number, and other requested information if you contact us via
                e-mail regarding support for the Services.
            </p>
            <p>
                We may also collect Personal Data at other points in our Site that state that Personal Data is being
                collected.
            </p>
        </section>

        <section>
            <p>
                <span>Information Collected from You About Others.</span> Information Collected from Third Party
                Companies. We may receive Personal and/or Anonymous Data about you from companies that provide our
                Services by way of a co-branded or private-labeled website or companies that offer their products and/or
                services on our Site. In particular, MetaMask provides us with your Ethereum address and certain other
                information you choose to share with MetaMask; and Civic provides us with an encrypted image of your
                government issued identification document and certain other information you authorize Civic to share
                with us for identity verification purposes. These third party companies may supply us with Personal
                Data. We may add this to the information we have already collected from you via our Site in order to
                improve the Services we provide. We do not collect Personal Data automatically, but we may tie the
                information that we collect automatically to Personal Data about you that we collect from other sources
                or that you provide to us.
            </p>
        </section>

        <section>
            <p>
                <span>Creation of Anonymous Data.</span> We may create Anonymous Data records from Personal Data by
                excluding information (such as your name) that makes the data personally identifiable to you. We use
                this Anonymous Data to analyze request and usage patterns so that we may enhance the content of our
                Services and improve Site navigation. We reserve the right to use Anonymous Data for any purpose and to
                disclose Anonymous Data to third parties without restriction.
            </p>
        </section>

        <section>
            <p>
                <span>Disclosure of Your Personal Data.</span> We disclose your Personal Data as described below and as
                described elsewhere in this Privacy Policy.
            </p>
        </section>

        <section>
            <p>
                <span>Third Party Service Providers.</span> We may share your Personal Data with third party service
                providers to: provide you with the Services that we offer you through our Site; to conduct quality
                assurance testing; to facilitate creation of accounts; to provide technical support; and/or to provide
                other services to the Company.
            </p>
        </section>

        <section>
            <p>
                <span>Affiliates.</span> We may share some or all of your Personal Data with our parent company,
                subsidiaries, joint ventures, or other companies under a common control ("Affiliates"), in which case we
                will require our Affiliates to honor this Privacy Policy.
            </p>
        </section>

        <section>
            <p>
                <span>Corporate Restructuring.</span> We may share some or all of your Personal Data in connection with
                or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding
                involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In
                the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a
                business asset. If another company acquires our company, business, or assets, that company will possess
                the Personal Data collected by us and will assume the rights and obligations regarding your Personal
                Data as described in this Privacy Policy.
            </p>
        </section>

        <section>
            <p>
                <span>As Legally Required.</span> Regardless of any choices you make regarding your Personal Data (as
                described below), Company may disclose Personal Data if it believes in good faith that such disclosure
                is necessary (a) in connection with any legal investigation; (b) to comply with relevant laws or to
                respond to subpoenas or warrants served on Company; (c) to protect or defend the rights or property of
                Company or users of the Site or Services; and/or (d) to investigate or assist in preventing any
                violation or potential violation of the law, this Privacy Policy, or our Terms of Use.
            </p>
        </section>

        <section>
            <p>
                <span>Other Disclosures.</span> We may also disclose your Personal Data, to fulfill the purpose for
                which you provide it; for any other purpose disclosed by us when you provide it; or with your consent.
            </p>
        </section>

        <section>
            <p>
                <span>How We Protect Your Information.</span> We take reasonable technical and organizational measures
                to guard against unauthorized or unlawful processing of your personal data and against accidental loss
                or destruction of, or damage to, your personal data. While no system is completely secure, we believe
                the measures implemented by the Site reduce our vulnerability to security problems to a level
                appropriate to the type of data involved. We have security measures in place to protect our user
                database and access to this database is restricted internally. However, it remains your responsibility:
            </p>
            <ol className={styles.upperLatinList}>
                <li>
                    <p>To protect against unauthorized access to your use of the Site and/or Services;</p>
                </li>
                <li>
                    <p>
                        To ensure no one else uses the Site and/or Services while your machine is logged on to the Site
                        (including by logging on to your machine through a mobile, Wi-Fi or shared access connection you
                        are using);
                    </p>
                </li>
                <li>
                    <p>To log off or exit from the Site and/or Services when not using it;</p>
                </li>
                <li>
                    <p>To maintain good internet security.</p>
                </li>
            </ol>
        </section>

        <section>
            <p>
                <span>Third Party Websites.</span> Our Site may contain links to third party websites. When you click on
                a link to any other website or location, you will leave our Site and go to another site, and another
                entity may collect Personal Data or Anonymous Data from you. We have no control over, do not review, and
                cannot be responsible for, these outside websites or their content. Please be aware that the terms of
                this Privacy Policy do not apply to these outside websites or content, or to any collection of your
                Personal Data after you click on links to such outside websites. We encourage you to read the privacy
                policies of every website you visit. The links to third party websites or locations are for your
                convenience and do not signify our endorsement of such third parties or their products, content or
                websites.
            </p>
        </section>

        <section>
            <p>
                <span>Your Choices Regarding Information.</span>You have several choices regarding the use of
                information on our Services:
            </p>
        </section>

        <section>
            <p>
                <span>Changing or Deleting Your Personal Data.</span> All users may review, update, correct or delete
                the Personal Data furnished by a userby contacting us. We will use commercially reasonable efforts to
                honor your request. We may retain an archived copy of Your records as required by law or for legitimate
                business purposes, such as information stored in blockchain technology for the purpose of verifying the
                property of the digital assets.
            </p>
            <p>
                We may use some of the information we collect for marketing purposes, including to send you promotional
                communications the Project features, products, events, or other opportunities. If you wish to stop
                receiving these communications or to opt out of use of your information for these purposes, please
                follow the opt-out instructions by clicking "Unsubscribe" (or similar opt-out language) in those
                communications.
            </p>
        </section>

        <section>
            <p>
                <span>Email Communications.</span> We could send you free newsletters and e-mails that directly promote
                the use of our Site or Services. When you receive newsletters or promotional communications from us, you
                may indicate a preference to stop receiving further communications from us and you will have the
                opportunity to "opt-out" by following the unsubscribe instructions provided in the e-mail you receive or
                by contacting us directly (please see contact information below). Despite your indicated e-mail
                preferences, we may send you service-related communications, including notices of any updates to our
                Terms and Conditions or Privacy Policy.
            </p>
            <p>
                If you decide at any time that you no longer wish to accept Cookies from our Service for any of the
                purposes described above, then you can instruct your browser, by changing its settings, to stop
                accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your
                browser's technical information. If you do not accept Cookies, however, you may not be able to use all
                portions of the Service or all functionality of the Service. If you have any questions about how to
                disable or modify Cookies, please let us know at the contact information provided below.
            </p>
        </section>

        <section>
            <p>
                <span>Changes to This Privacy Policy.</span> This Privacy Policy may be updated from time to time for
                any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy.
                The date the Privacy Policy was last revised is identified at the beginning of this Privacy Policy. You
                are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for
                periodically visiting our Site and this Privacy Policy to check for any changes.
            </p>
        </section>

        <section>
            <h2>HOW WE USE YOUR INFORMATION</h2>
            <p>We use the information we collect in various ways, including to:</p>
            <ol className={styles.discList}>
                <li>
                    <p>Provide, operate, and maintain our website</p>
                </li>
                <li>
                    <p>Improve, personalize, and expand our website</p>
                </li>
                <li>
                    <p>Understand and analyze how you use our website</p>
                </li>
                <li>
                    <p>Develop new products, services, features, and functionality</p>
                </li>
                <li>
                    <p>
                        Communicate with you, either directly or through one of our partners, including for customer
                        service, to provide you with updates and other information relating to the website, and for
                        marketing and promotional purposes
                    </p>
                </li>
                <li>
                    <p>Send you emails</p>
                </li>
                <li>
                    <p>Find and prevent fraud</p>
                </li>
            </ol>
        </section>

        <section>
            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        Request that a business that collects a consumer's personal data disclose the categories and
                        specific pieces of personal data that a business has collected about consumers.
                    </p>
                </li>
                <li>
                    <p>
                        Request that a business delete any personal data about the consumer that a business has
                        collected.
                    </p>
                </li>
                <li>
                    <p>
                        Request that a business that sells a consumer's personal data, not sell the consumer's personal
                        data.
                    </p>
                </li>
                <li>
                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us.
                    </p>
                </li>
            </ol>
        </section>

        <section>
            <h2>GDPR Data Protection Rights</h2>
            <p>
                We would like to make sure you are fully aware of all of your data protection rights. Every user is
                entitled to the following:
            </p>
            <ol className={styles.lowerLatinList}>
                <li>
                    <p>
                        <span>The right to access -</span> You have the right to request copies of your personal data.
                        We may charge you a small fee for this service.
                    </p>
                </li>
                <li>
                    <p>
                        <span>The right to rectification -</span> You have the right to request that we correct any
                        information you believe is inaccurate. You also have the right to request that we complete the
                        information you believe is incomplete.
                    </p>
                </li>
                <li>
                    <p>
                        <span>The right to erasure -</span> You have the right to request that we erase your personal
                        data, under certain conditions.
                    </p>
                </li>
                <li>
                    <p>
                        <span>The right to restrict processing -</span> You have the right to request that we restrict
                        the processing of your personal data, under certain conditions.
                    </p>
                </li>
                <li>
                    <p>
                        <span>The right to object to processing -</span> You have the right to object to our processing
                        of your personal data, under certain conditions.
                    </p>
                </li>
                <li>
                    <p>
                        <span>The right to data portability -</span> You have the right to request that we transfer the
                        data that we have collected to another organization, or directly to you, under certain
                        conditions.
                    </p>
                </li>
            </ol>
            <p>
                If you make a request, we have one month to respond to you. If you would like to exercise any of these
                rights, please contact us.
            </p>
        </section>
    </div>
);

export default PrivacyPolicy;
