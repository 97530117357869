import { KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { EBurnStages, ETabType } from "@enums/burn";
import withMaxWidth from "@hocs/withMaxWidth";
import useBurn from "@hooks/burn/useBurn";
import PROVIDER from "@constants/web3.config";
import Connect from "./components/Connect";
import Selection from "./components/Selection";
import Confirmation from "./components/Confirmation";
import Success from "./components/Success";
import Loading from "./components/Loading";
import TechnicalError from "@components/TechnicalError";
import WaitingConfirmation from "./components/WaitingConfirmation";
import DefaultLoader from "@components/DefaultLoader";
import styles from "./Burn.module.scss";

const Burn = (): ReactElement => {
    const { walletAddress, connect, web3, isInitialized } = useWeb3Context();
    const [stage, setStage] = useState(EBurnStages.CONNECT);
    const [tab, setTab] = useState(ETabType.AVATARS);
    const [isLoading, setIsLoading] = useState(true);

    const updateStage = (stage: EBurnStages) => {
        setStage(stage);
    };

    const {
        traitsToBurn,
        avatarsToBurn,
        heroinesToBurn,
        addToBurn,
        addTraitToBurn,
        addAvatarToBurn,
        addHeroineToBurn,
        burnAssets,
        txId,
    } = useBurn(tab, updateStage);

    useEffect(() => {
        if (isInitialized) {
            setIsLoading(false);
        }

        if (isInitialized && !walletAddress) {
            setStage(EBurnStages.CONNECT);
        }

        if (walletAddress && isInitialized && web3) {
            setStage(EBurnStages.SELECTION);
        }
    }, [walletAddress, web3, isInitialized]);

    const handleConnect = async () => {
        await connect(PROVIDER);
    };

    const selectTab = (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>, newTab: ETabType) => {
        if ((e.target as Element).tagName.toLowerCase() === "input") {
            return;
        }
        setTab(newTab);
    };

    if (isLoading) {
        return (
            <div className={`${styles.container} ${styles.defualtLoader}`}>
                <DefaultLoader classname={styles.loader} spinner invert />
            </div>
        );
    }

    return (
        <div className={`${styles.container} ${stage === EBurnStages.ERROR && styles.invert}`}>
            {stage === EBurnStages.CONNECT && <Connect handleConnect={handleConnect} />}
            {stage === EBurnStages.SELECTION && (
                <Selection
                    handleStage={updateStage}
                    tab={tab}
                    traitsToBurn={traitsToBurn}
                    avatarsToBurn={avatarsToBurn}
                    heroinesToBurn={heroinesToBurn}
                    addToBurn={addToBurn}
                    selectTab={selectTab}
                    addTraitToBurn={addTraitToBurn}
                    addAvatarToBurn={addAvatarToBurn}
                    addHeroineToBurn={addHeroineToBurn}
                />
            )}
            {stage === EBurnStages.CONFIRMATION && (
                <Confirmation
                    handlePrevStage={updateStage}
                    traitsToBurn={traitsToBurn}
                    avatarsToBurn={avatarsToBurn}
                    heroinesToBurn={heroinesToBurn}
                    burnAll={burnAssets}
                />
            )}
            {stage === EBurnStages.LOADING && <Loading txId={txId} />}
            {stage === EBurnStages.WAITING_CONFIRMATION && <WaitingConfirmation />}
            {stage === EBurnStages.SUCCESS && <Success />}
            {stage === EBurnStages.ERROR && (
                <TechnicalError>
                    <>
                        <p className={styles.error}>
                            TECHNICAL ERROR. SOMETHING WENT WRONG! THE TRANSACTION DID NOT GO THROUGH.
                        </p>
                        <p className={styles.error}>BE BRAVE, SEVERIGN, NEVER SURRENDER! TRY BURNING AGAIN.</p>
                    </>
                </TechnicalError>
            )}
        </div>
    );
};

export default withMaxWidth(Burn);
