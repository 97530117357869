import { ReactElement } from "react";
import arrow from "@images/carousel-chevron.png";
import styles from "./Foot.module.scss";

interface IFoot {
    actualLimit: number;
    actualPage: number;
    totalItems: number;
    handleNextPage: () => void;
    handlePrevPage: () => void;
    handleItemsLimitChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Foot = ({
    actualLimit,
    actualPage,
    totalItems,
    handleNextPage,
    handlePrevPage,
    handleItemsLimitChange,
}: IFoot): ReactElement => {
    const canGoNext = actualPage + 1 < Math.ceil(totalItems / actualLimit);
    const canGoPrev = actualPage + 1 > 1;

    return (
        <tfoot>
            <tr>
                <td className={styles.pagerContainer}>
                    <button
                        className={styles.prevBtn}
                        onClick={canGoPrev ? handlePrevPage : undefined}
                        aria-label="Previous page"
                        disabled={!canGoPrev}
                    >
                        <img className={`${!canGoPrev ? styles.hidden : ""}`} src={arrow} alt="Search by avatar id" />
                    </button>
                    <span>{`${actualPage + 1} / ${Math.ceil(totalItems / actualLimit)}`}</span>
                    <button
                        className={styles.nextBtn}
                        onClick={canGoNext ? handleNextPage : undefined}
                        aria-label="Next page"
                        disabled={!canGoNext}
                    >
                        <img className={`${!canGoNext ? styles.hidden : ""}`} src={arrow} alt="Search by avatar id" />
                    </button>
                    <select value={actualLimit} onChange={handleItemsLimitChange}>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </td>
            </tr>
        </tfoot>
    );
};

export default Foot;
