import { NavLink } from "react-router-dom";
import { HOME, HOW_IT_WORKS, ROADMAP, HACK_THE_ROYALS, CLAIM_TRAIT, BURNING } from "@constants/routes";
import styles from "./Navbar.module.scss";

const Navbar = (): JSX.Element => {
    return (
        <nav className={styles.container} data-testid="navbar">
            <ul>
                <li>
                    <NavLink exact to={HOME} activeClassName={styles.activeLink} data-testid="navbar-home-link">
                        HOME
                    </NavLink>
                </li>
                {/* <li className={styles.separator}>|</li>
                <li>
                    <NavLink exact to={MINT} activeClassName={styles.activeLink} data-testid="navbar-mint-link">
                        MINT YOUR TRAITS
                    </NavLink>
                </li> */}
                <li className={styles.separator}>|</li>
                <li>
                    <NavLink
                        exact
                        to={CLAIM_TRAIT}
                        activeClassName={styles.activeLink}
                        data-testid="navbar-claim-trait-link"
                    >
                        CLAIM TRAIT
                    </NavLink>
                </li>
                <li className={styles.separator}>|</li>
                <li>
                    <NavLink
                        exact
                        to={HOW_IT_WORKS}
                        activeClassName={styles.activeLink}
                        data-testid="navbar-how-it-works-link"
                    >
                        HOW IT WORKS
                    </NavLink>
                </li>
                <li className={styles.separator}>|</li>
                <li>
                    <NavLink exact to={ROADMAP} activeClassName={styles.activeLink} data-testid="navbar-roadmap-link">
                        ROADMAP
                    </NavLink>
                </li>
                <li className={styles.separator}>|</li>
                <li>
                    <NavLink
                        to={HACK_THE_ROYALS}
                        activeClassName={styles.activeLink}
                        data-testid="navbar-hack-the-royals-link"
                    >
                        HACK THE ROYALS
                    </NavLink>
                </li>
                <li className={styles.separator}>|</li>
                <li>
                    <NavLink exact to={BURNING} activeClassName={styles.activeLink} data-testid="navbar-mint-link">
                        BURNING
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
